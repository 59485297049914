import React from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import SignatureButton from "../../../components/SignatureButton";
import "./styles.css";

const Grievance = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <div className="form-content">
        <div>
          <form>
            <p>
              <strong>
                <h2>PATIENT COMPLAINT OR GRIEVANCE</h2>
              </strong>
            </p>
            <ul>
              <li>
                If you have a problem or complaint, please speak to the
                receptionist or your caregiver. We will address your concern(s)
                promptly.{" "}
              </li>
              <li>
                If necessary, your problem or complaint will be routed to the
                Administrator and/or Quality Assurance coordinator for
                resolution. You will receive a letter or phone call to inform
                you of the actions taken to address your complaint.
              </li>
              <li>
                If you are not satisfied with the response of the Surgery
                Center, you may contact:
              </li>
            </ul>
            <div className="lh-center">
              {" "}
              <p className="text-center">Department of Health Services</p>
              <p className="text-center">Division of Health Facilities</p>
              <p className="text-center">
                2150 Towne Centre Place, Anaheim, CA 92806
              </p>
              <p className="text-center">714.456.0630</p>
              <p className="text-center">Or</p>
              <p className="text-center">The Joint Commission</p>
              <p className="text-center">One Renaissance Blvd.</p>
              <p className="text-center">Oakbrook Terrace, IL 60181</p>
              <p className="text-center">630.792.5000</p>
              <p className="text-center lh-center">
                All Medicare beneficiaries may file a complaint or grievance
                with the Medicare Beneficiary Ombudsman. You may call
                1-800-MEDICARE and they will direct your inquiry to the Medicare
                Ombudsman. You may write to them at:
              </p>
              <p className="text-center lh-center">
                Center for Medicare and Medicaid Services
              </p>
              <p className="text-center">7500 Security Boulevard</p>
              <p className="text-center">Baltimore MD 21244</p>
              <p className="text-center lh-center">
                You may visit the Ombudsman’s webpage at:
              </p>
              <p className="text-center">www.cms.hhs.gov/center/ombudsman</p>
              <p className="text-center lh-center">
                <strong>CALIFORNIA MEDICAL BOARD</strong>
              </p>
              <p className="text-center">
                All physicians practicing in this facility are licensed by and
                are regulated by the Medical Board of California. Any
                information regarding your physician may be addressed to:
              </p>
              <p className="text-center lh-center">
                <strong>Medical Board of California</strong>
              </p>
              <p className="text-center">(800) 633-2322. www.mbc.ca.gov</p>
              <p className="text-center">
                I received information on patient rights and responsibilities,
                physician ownership disclosure, advance directive policy and the
                grievance policy at least one.
              </p>
            </div>
            <div className="grievance-container mt-50">
              <div className="gc-item">
                {" "}
                <SignatureButton />
                <hr className="signature-line"></hr>
                <p>Patient Name</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Patient Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  required
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="text"
                  id="time"
                  name="time"
                  className="bottom-border-input"
                  required
                />
                <p>Time</p>
              </div>

              <div className="gc-item">
                {" "}
                <SignatureButton />
                <hr className="signature-line"></hr>
                <p>Patient Signature</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Patient Signature
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  required
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="text"
                  id="time"
                  name="time"
                  className="bottom-border-input"
                  required
                />
                <p>Time</p>
              </div>

              <div className="gc-item">
                {" "}
                <SignatureButton />
                <hr className="signature-line"></hr>
                <p>Witness Signature</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Witness Signature
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  required
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="text"
                  id="time"
                  name="time"
                  className="bottom-border-input"
                  required
                />
                <p>Time</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Grievance;
