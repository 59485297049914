import React, { useState, useEffect } from "react";
import "./adminEditUser.css";
import BackgroundImage from "../../../components/BackgroundImage";
import placeholder from "../../../assets/images/guest.webp";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { getUserProfile, udpateUser, addFile } from "../../../utils/api";

const AdminEditUser = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [showError, setShowError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {};

    // Validate that the fields have changed before submitting
    if (firstName !== "" && firstName !== user.first_name) {
      userData.first_name = firstName;
    }
    if (lastName !== "" && lastName !== user.last_name) {
      userData.last_name = lastName;
    }
    if (email !== "" && email !== user.email) {
      userData.email = email;
    }
    if (role !== "" && role !== user.role) {
      userData.role = role;
    }

    // Check if an image was uploaded
    if (profileImage !== "") {
      // Fetch 1: Upload profile photo
      addFile(profileImage)
        .then((res) => res.json())
        .then((data) => {
          userData.image = data.fileName;

          // Fetch 2: Update the Agent
          udpateUser(userData, user.id)
            .then((res) => res.json())
            .then((data) => {
              navigate(`/admin-view-user/${user.id}`);
            })
            .catch((err) => {
              console.log(err);
              setShowError(true);
            });
        })
        .catch((err) => {
          console.log(err);
          setShowError(true);
        });
    } else {
      // Check that there is data to update
      if (Object.keys(userData).length === 0) {
        return;
      } else {
        udpateUser(userData, user.id)
          .then((res) => res.json())
          .then((data) => {
            navigate(`/admin-view-user/${user.id}`);
          })
          .catch((err) => {
            console.log(err);
            setShowError(true);
          });
      }
    }
  };

  const displayPhoto = (image) => {
    if (!image) {
      return placeholder;
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `/uploads/${image}`;
    }
  };

  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    // Don't update state if the user clicks "cancel"
    if (!e.target.files[0]) {
      return;
    }

    // Format File for fetch request
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    console.log(formData);
    console.log(...formData);
    setProfileImage(formData);
  };

  useEffect(() => {
    const user_id = window.location.href.split("/")[4];

    // Get recent Users on page load
    getUserProfile(user_id)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setUser(data);

        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(data.email);
        setRole(data.role);

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <div className="create-grid-container">
        <div className="first-column"></div>
        <div className="second-column">
          <BackgroundImage />

          {/* Breadcrumb */}
          <div className="manage-users-breadcrumb">
            <Link to="/admin-user-search">Users</Link>
            <Icon icon="ph:greater-than-bold" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="create-grid-container">
      <div className="first-column"></div>
      <div className="second-column">
        <BackgroundImage />

        {/* Breadcrumb */}
        <div className="manage-users-breadcrumb">
          <Link to="/admin-user-search">Users</Link>
          <Icon icon="ph:greater-than-bold" />
          <Link to={`/admin-view-user/${user.id}`}>
            {" "}
            {user.first_name} {user.last_name}
          </Link>
          <Icon icon="ph:greater-than-bold" />
          <p>Edit</p>
        </div>

        {/* Profile Card */}
        <form
          className="user-profile-card edit-user"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div>
            <img src={displayPhoto(user.image)} alt="placeholder profile" />
          </div>
          <div>
            <input
              type="file"
              className="edit-image-btn"
              name="photo"
              id="photo"
              onChange={handleImageChange}
              accept="image/png, image/jpeg"
            />
          </div>

          {/* Name */}
          <div className="user-card-line">
            <p className="line-heading">Name</p>
            <div className="line-input-container">
              <input
                className="line-input"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <label>First Name</label>
            </div>
          </div>
          <div className="user-card-line">
            <p className="line-heading"></p>
            <div className="line-input-container">
              <input
                className="line-input"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <label>Last Name</label>
            </div>
          </div>

          {/* Email */}
          <div className="user-card-line">
            <p className="line-heading">Email</p>
            <input
              className="line-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {/* Role */}
          <div className="user-card-line">
            <p className="line-heading">Role</p>
            <select
              className="create-input line-input"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value={""}>Select</option>
              <option value={"Doctor"}>Doctor</option>
              <option value={"Anesthesiologist"}>Anesthesiologist</option>
              <option value={"RN"}>RN (Registered Nurse)</option>
              <option value={"LVN"}>LVN (Licensed Vocational Nurse)</option>
              <option value={"Tech"}>Tech</option>
              <option value={"Admin"}>Admin</option>
              <option value={"Ancillary"}>Ancillary</option>
            </select>
          </div>

          {/* Error message diplay */}
          {showError ? (
            <p className="error-message">
              Something has gone wrong with your request, please try again
              later.
            </p>
          ) : (
            <></>
          )}

          {/* Edit and Block buttons */}
          <div>
            <button className="edit-user" type="submit">
              Update User
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminEditUser;
