import React, { useState, useEffect } from "react";
import { createCondition, updateCondition } from "../../../utils/api";
const moment = require("moment");

const Question = ({ question, ariaId }) => {
  // console.log(question);
  const [selectedOption, setSelectedOption] = useState(question.value);
  const [additionalInfo, setAdditionalInfo] = useState(
    question.condition_obj.condition_note
  );
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  // Handle changes in the radio button (Yes/No options)
  const handleRadioChange = (e) => {
    const value = e.target.value === "true";
    setSelectedOption(value);
    value !== question.value ? setCanUpdate(true) : setCanUpdate(false);
  };

  // Handle changes in the additional information input field
  const handleInputChange = (e) => {
    setAdditionalInfo(e.target.value);
    e.target.value !== question.condition_obj.condition_note
      ? setCanUpdate(true)
      : setCanUpdate(false);
  };

  // Function to add or update the entry in the medical history
  const addEntry = () => {
    if (selectedOption === false) {
      // Delete the record
      const condition_id = question.condition_obj.id;
      const payload = {
        date_removed: moment().format("MM/DD/YYYY"),
        isDeleted: true,
      };

      updateCondition(condition_id, payload)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setCanUpdate(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    } else if (
      question.condition_obj.id !== null &&
      selectedOption === true &&
      additionalInfo !== question.condition_obj.condition_note
    ) {
      // Update the record text
      const condition_id = question.condition_obj.id;
      const payload = {
        condition_note: additionalInfo,
      };

      updateCondition(condition_id, payload)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setCanUpdate(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    } else {
      // Create a new Condition record
      const payload = {
        med_history_id: JSON.parse(localStorage.getItem("med_history_id")),
        condition: question.condition_obj.condition,
        condition_note: additionalInfo || "",
        category: question.condition_obj.category,
        date_created: moment().format("MM/DD/YYYY"),
      };

      createCondition(payload)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setCanUpdate(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  return (
    <div className="question-row">
      <div className="question-container">
        <label htmlFor={ariaId}>{question.label}</label>
        <div className="radio-inline">
          <label>
            <input
              type="radio"
              value={true}
              checked={selectedOption === true}
              onChange={handleRadioChange}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              value={false}
              checked={selectedOption === false}
              onChange={handleRadioChange}
            />
            No
          </label>
        </div>
      </div>

      {selectedOption.toString() === "true" && (
        <input
          type="text"
          id="answer"
          className="full-width-input"
          placeholder="Please provide additional information"
          value={additionalInfo}
          onChange={handleInputChange}
        />
      )}

      {canUpdate && (
        <button className="med-history-add-btn" onClick={addEntry}>
          Update
        </button>
      )}

      {errMessage && <p className="err-message">Error updating history.</p>}
    </div>
  );
};

const Questionnaire = ({
  nQ,
  recentlyTraveled,
  setRecentlyTraveled,
  noKnownMedicalHistory,
  setNoKnownMedicalHistory,
  dbMedHistory,
  handleMedHistory,
}) => {
  const [conditions, setConditions] = useState(dbMedHistory.conditions || []);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 825);
  // console.log(conditions);

  const [sortedQuestions, setSortedQuestions] = useState({
    haveYouHad: [],
    haveYouHadAdd: [],
    doYou: [],
  });

  useEffect(() => {
    // Prevent running logic if dbMedHistory is undefined
    if (!dbMedHistory) return;

    const checkCondition = (condition) => {
      const conditionExists = conditions.find(
        (person) => person.condition === condition
      );

      if (conditionExists) {
        // console.log("Found:", conditionExists);
        return true;
      } else {
        // console.log("Not found");
        return false;
      }
    };

    const getCondition = (condition) => {
      const conditionExists = conditions.find(
        (person) => person.condition === condition
      );

      if (conditionExists) {
        return conditionExists;
      }
    };

    // Section - Have You Had?
    const haveYouHadQuestions = [
      {
        id: 1,
        label: "1. High Blood Pressure",
        value: checkCondition("High Blood Pressure"),
        condition_obj: getCondition("High Blood Pressure") || {
          id: null,
          condition: "High Blood Pressure",
          condition_note: "",
          category: "Cardiovascular",
        },
      },
      {
        id: 13,
        label: "9. Kidney Disease",
        value: checkCondition("Kidney Disease"),
        condition_obj: getCondition("Kidney Disease") || {
          id: null,
          condition: "Kidney Disease",
          condition_note: "",
          category: "Other",
        },
      },
      {
        id: 2,
        label: "2. Heart Trouble or Heart Attack",
        value: checkCondition("Heart Trouble"),
        condition_obj: getCondition("Heart Trouble") || {
          id: null,
          condition: "Heart Trouble",
          condition_note: "",
          category: "Cardiovascular",
        },
      },
      {
        id: 14,
        label: "10. Abdominal Bleeding Problems",
        value: checkCondition("Abdominal Bleeding"),
        condition_obj: getCondition("Abdominal Bleeding") || {
          id: null,
          condition: "Abdominal Bleeding",
          condition_note: "",
          category: "Cardiovascular",
        },
      },
      {
        id: 3,
        label: "a. Chest pain or Angina",
        value: checkCondition("Angina"),
        condition_obj: getCondition("Angina") || {
          id: null,
          condition: "Angina",
          condition_note: "",
          category: "Cardiovascular",
        },
      },
      {
        id: 15,
        label: "11. Stroke, numbness, or weakness",
        value: checkCondition("Stroke"),
        condition_obj: getCondition("Stroke") || {
          id: null,
          condition: "Stroke",
          condition_note: "",
          category: "Neuro",
        },
      },
      {
        id: 4,
        label: "b. Irregular Heart Beat",
        value: checkCondition("Irregular Heart Beat"),
        condition_obj: getCondition("Irregular Heart Beat") || {
          id: null,
          condition: "Irregular Heart Beat",
          condition_note: "",
          category: "Cardiovascular",
        },
      },
      {
        id: 16,
        label: "12. Epilepsy or convulsive seizures",
        value: checkCondition("Seizures"),
        condition_obj: getCondition("Seizures") || {
          id: null,
          condition: "Seizures",
          condition_note: "",
          category: "Neuro",
        },
      },
      {
        id: 5,
        label: "c. Congestive Heart Failure",
        value: checkCondition("Congestive Heart Failure"),
        condition_obj: getCondition("Congestive Heart Failure") || {
          id: null,
          condition: "Congestive Heart Failure",
          condition_note: "",
          category: "Cardiovascular",
        },
      },
      {
        id: 17,
        label: "13. Broken bones of back, neck, or face",
        value: checkCondition("Broken bones"),
        condition_obj: getCondition("Broken bones") || {
          id: null,
          condition: "Broken bones",
          condition_note: "",
          category: "Other",
        },
      },
      {
        id: 6,
        label: "d. Abnormal Electrocardiogram",
        value: checkCondition("Abnormal Electrocardiogram"),
        condition_obj: getCondition("Abnormal Electrocardiogram") || {
          id: null,
          condition: "Abnormal Electrocardiogram",
          condition_note: "",
          category: "Cardiovascular",
        },
      },
      {
        id: 18,
        label: "14. Back Trouble",
        value: checkCondition("Back Trouble"),
        condition_obj: getCondition("Back Trouble") || {
          id: null,
          condition: "Back Trouble",
          condition_note: "",
          category: "Neuro",
        },
      },
      {
        id: 7,
        label: "3. Gastric Esophageal Reflux, Hiatal Hernia, Ulcers",
        value: checkCondition(
          "Gastric Esophageal Reflux, Hiatal Hernia, Ulcers"
        ),
        condition_obj: getCondition(
          "Gastric Esophageal Reflux, Hiatal Hernia, Ulcers"
        ) || {
          id: null,
          condition: "Gastric Esophageal Reflux, Hiatal Hernia, Ulcers",
          condition_note: "",
          category: "Hepato",
        },
      },
      {
        id: 19,
        label: "15. Unusual muscle problems or diseases",
        value: checkCondition("Unusual muscle problems or diseases"),
        condition_obj: getCondition("Unusual muscle problems or diseases") || {
          id: null,
          condition: "Unusual muscle problems or diseases",
          condition_note: "",
          category: "Neuro",
        },
      },
      {
        id: 8,
        label: "4. Recent cold, cough, or sore throat",
        value: checkCondition("Recent cold"),
        condition_obj: getCondition("Recent cold") || {
          id: null,
          condition: "Recent cold",
          condition_note: "",
          category: "Other",
        },
      },
      {
        id: 20,
        label: "16. Unexpected fevers or heat strokes",
        value: checkCondition("Unexpected fevers or heat strokes"),
        condition_obj: getCondition("Unexpected fevers or heat strokes") || {
          id: null,
          condition: "Unexpected fevers or heat strokes",
          condition_note: "",
          category: "Other",
        },
      },
      {
        id: 9,
        label: "5. Asthma, Emphysema, Bronchitis, or Breathing problems",
        value: checkCondition(
          "Asthma, Emphysema, Bronchitis, or Breathing problems"
        ),
        condition_obj: getCondition(
          "Asthma, Emphysema, Bronchitis, or Breathing problems"
        ) || {
          id: null,
          condition: "Asthma, Emphysema, Bronchitis, or Breathing problems",
          condition_note: "",
          category: "Respiratory",
        },
      },
      {
        id: 21,
        label: "17. Bad reaction to anesthesia",
        value: checkCondition("Bad reaction to anesthesia"),
        condition_obj: getCondition("Bad reaction to anesthesia") || {
          id: null,
          condition: "Bad reaction to anesthesia",
          condition_note: "",
          category: "Other",
        },
      },
      {
        id: 10,
        label: "6. Abdominal Chest X-ray",
        value: checkCondition("Abdominal Chest X-ray"),
        condition_obj: getCondition("Abdominal Chest X-ray") || {
          id: null,
          condition: "Abdominal Chest X-ray",
          condition_note: "",
          category: "Other",
        },
      },
      {
        id: 22,
        label: "18. Relatives with bad reaction to anesthesia",
        value: checkCondition("Relatives with bad reaction to anesthesia"),
        condition_obj: getCondition(
          "Relatives with bad reaction to anesthesia"
        ) || {
          id: null,
          condition: "Relatives with bad reaction to anesthesia",
          condition_note: "",
          category: "Other",
        },
      },
      {
        id: 11,
        label: "7. Diabetes",
        value: checkCondition("Diabetes"),
        condition_obj: getCondition("Diabetes") || {
          id: null,
          condition: "Diabetes",
          condition_note: "",
          category: "Renal",
        },
      },
      {
        id: 23,
        label: "19. Problems with motion sickness",
        value: checkCondition("Problems with motion sickness"),
        condition_obj: getCondition("Problems with motion sickness") || {
          id: null,
          condition: "Problems with motion sickness",
          condition_note: "",
          category: "Other",
        },
      },
      {
        id: 12,
        label: "8. Yellow Jaundice or Hepatitis",
        value: checkCondition("Yellow Jaundice or Hepatitis"),
        condition_obj: getCondition("Yellow Jaundice or Hepatitis") || {
          id: null,
          condition: "Yellow Jaundice or Hepatitis",
          condition_note: "",
          category: "Hepato",
        },
      },
    ];

    // Section - Have You Had Additional?
    const haveYouHadQuestionsAdd = [
      {
        id: 1,
        label: "1.  Neurological Problems",
        value: checkCondition("Neurological Problems"),
        condition_obj: getCondition("Neurological Problems") || {
          id: null,
          condition: "Neurological Problems",
          condition_note: "",
          category: "Neuro",
        },
      },
      {
        id: 4,
        label: "4.  Hepatitis",
        value: checkCondition("Hepatitis"),
        condition_obj: getCondition("Hepatitis") || {
          id: null,
          condition: "Hepatitis",
          condition_note: "",
          category: "Hepato",
        },
      },
      {
        id: 2,
        label: "2.  Hypertension",
        value: checkCondition("Hypertension"),
        condition_obj: getCondition("Hypertension") || {
          id: null,
          condition: "Hypertension",
          condition_note: "",
          category: "Cardiovascular",
        },
      },
      {
        id: 5,
        label: "5.  Fainting/Diziness",
        value: checkCondition("Fainting/Diziness"),
        condition_obj: getCondition("Fainting/Diziness") || {
          id: null,
          condition: "Fainting/Diziness",
          condition_note: "",
          category: "Other",
        },
      },
      {
        id: 3,
        label: "3.  Bleeding Problems",
        value: checkCondition("Bleeding Problems"),
        condition_obj: getCondition("Bleeding Problems") || {
          id: null,
          condition: "Bleeding Problems",
          condition_note: "",
          category: "Other",
        },
      },
      {
        id: 6,
        label: "6.  Heart Disease",
        value: checkCondition("Heart Disease"),
        condition_obj: getCondition("Heart Disease") || {
          id: null,
          condition: "Heart Disease",
          condition_note: "",
          category: "Cardiovascular",
        },
      },
    ];

    // Section - Do you?
    const doYouQuestions = [
      {
        id: 1,
        label: "1.  Wear Dentures",
        value: checkCondition("Wear Dentures"),
        condition_obj: getCondition("Wear Dentures") || {
          id: null,
          condition: "Wear Dentures",
          condition_note: "",
          category: null,
        },
      },
      {
        id: 4,
        label: "4.  Smoke (how much per day/week)",
        value: checkCondition("Smoke"),
        condition_obj: getCondition("Smoke") || {
          id: null,
          condition: "Smoke",
          condition_note: "",
          category: null,
        },
      },
      {
        id: 2,
        label: "2.  Have caps on teeth",
        value: checkCondition("Have caps on teeth"),
        condition_obj: getCondition("Have caps on teeth") || {
          id: null,
          condition: "Have caps on teeth",
          condition_note: "",
          category: null,
        },
      },
      {
        id: 5,
        label: "5.  Exercise or have strenuous activity",
        value: checkCondition("Exercise or have strenuous activity"),
        condition_obj: getCondition("Exercise or have strenuous activity") || {
          id: null,
          condition: "Exercise or have strenuous activity",
          condition_note: "",
          category: null,
        },
      },
      {
        id: 3,
        label: "3.  Drink Alcohol",
        value: checkCondition("Drink Alcohol"),
        condition_obj: getCondition("Drink Alcohol") || {
          id: null,
          condition: "Drink Alcohol",
          condition_note: "",
          category: null,
        },
      },
      {
        id: 6,
        label: "6.  Have physical limitations",
        value: checkCondition("Have physical limitations"),
        condition_obj: getCondition("Have physical limitations") || {
          id: null,
          condition: "Have physical limitations",
          condition_note: "",
          category: null,
        },
      },
    ];

    if (isSmallScreen) {
      setSortedQuestions({
        haveYouHad: haveYouHadQuestions.sort((a, b) => a.id - b.id),
        haveYouHadAdd: haveYouHadQuestionsAdd.sort((a, b) => a.id - b.id),
        doYou: doYouQuestions.sort((a, b) => a.id - b.id),
      });
    } else {
      setSortedQuestions({
        haveYouHad: haveYouHadQuestions,
        haveYouHadAdd: haveYouHadQuestionsAdd,
        doYou: doYouQuestions,
      });
    }
  }, []);

  // Effect to listen to window resize and update state
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 825);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Return loading state if nqMedHistory or nQ is not yet available
  if (!dbMedHistory || !nQ) {
    return <div>Loading...</div>;
  }

  return (
    <div className="questionnaire-container">
      <div className="question-section travel">
        {" "}
        <label htmlFor="travel">
          Have you travelled anywhere in the past 30 days?
        </label>
        <select
          id="travel"
          value={recentlyTraveled}
          onChange={(e) => setRecentlyTraveled(e.target.value)}
        >
          <option value={false}>No</option>
          <option value={true}>Yes</option>
        </select>
      </div>
      <div className="question-section nkmh">
        <label htmlFor="nkmh">No Known Medical History</label>
        <input
          type="checkbox"
          id="nkmh"
          checked={noKnownMedicalHistory}
          onChange={(e) => {
            console.log(e.target.checked);
            setNoKnownMedicalHistory(e.target.checked);
            handleMedHistory(e, "medHistory");
          }}
        />
      </div>

      {!noKnownMedicalHistory && (
        <>
          <div className="question-section">
            <h3>Have You Had:</h3>
            <div className="question-grid">
              {sortedQuestions.haveYouHad?.map((question, index) => (
                <Question
                  key={question.id}
                  question={question}
                  ariaId={`haveYouHad-question-${index}`}
                />
              ))}
            </div>
          </div>

          <div className="question-section">
            <h3>Have You Had (Additional):</h3>
            <div className="question-grid">
              {sortedQuestions.haveYouHadAdd?.map((question, index) => (
                <Question
                  key={question.id}
                  question={question}
                  ariaId={`haveYouHadAdd-question-${index}`}
                />
              ))}
            </div>
          </div>
        </>
      )}

      <div className="question-section">
        <h3>Do You:</h3>
        <div className="question-grid">
          {sortedQuestions.doYou?.map((question, index) => (
            <Question
              key={question.id}
              question={question}
              ariaId={`doYou-question-${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
