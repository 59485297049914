import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getAnesthesiaQues,
  updateAnesthesiaQues,
} from "../../../utils/api";
const AnethesiaQuestionnaire = () => {
  const { form_id } = useParams();
  const navigate = useNavigate();
  const [individualSignature, setIndividualSignature] = useState("");
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    last_food: "",
    last_fluid: "",
    bp: "",
    pulse: "",
    resp: "",
    temp: "",
    o2_stat: "",
    current_medications: "",
    previous_surgeries: "",
    height: "",
    weight: "",
    traveled: "",
    med_allergies_yes: "",
    med_allergies_no: "",
    food_allergies_yes: "",
    food_allergies_no: "",
    med_allergies_text: "",
    food_allergies_text: "",
    sleep_apnea_yes: "",
    sleep_apnea_no: "",
    latex_allery_yes: "",
    latex_allergy_no: "",
    preferred_language: "",
    one_yes: "",
    one_no: "",
    one_text: "",
    two_yes: "",
    two_no: "",
    two_text: "",
    twoa_yes: "",
    twoa_no: "",
    twoa_text: "",
    two_b_yes: "",
    two_b_no: "",
    two_b_text: "",
    two_c_yes: "",
    two_c_no: "",
    two_c_text: "",
    two_d_yes: "",
    two_d_no: "",
    two_d_text: "",
    three_yes: "",
    three_no: "",
    three_text: "",
    four_yes: "",
    four_no: "",
    four_text: "",
    five_yes: "",
    five_no: "",
    five_text: "",
    six_yes: "",
    six_no: "",
    six_text: "",
    seven_yes: "",
    seven_no: "",
    seven_text: "",
    eight_yes: "",
    eight_no: "",
    eight_text: "",
    nine_yes: "",
    nine_no: "",
    nine_text: "",
    ten_yes: "",
    ten_no: "",
    ten_text: "",
    eleven_yes: "",
    eleven_no: "",
    eleven_text: "",
    twelve_yes: "",
    twelve_no: "",
    twelve_text: "",
    thirteen_yes: "",
    thirteen_no: "",
    thirteen_text: "",
    fourteen_yes: "",
    fourteen_no: "",
    fourteen_text: "",
    fifteen_yes: "",
    fifteen_no: "",
    fifteen_text: "",
    sixteen_yes: "",
    sixteen_no: "",
    sixteen_text: "",
    seventeen_yes: "",
    seventeen_no: "",
    seventeen_text: "",
    eighteen_yes: "",
    eighteen_no: "",
    eighteen_text: "",
    nineteen_yes: "",
    nineteen_no: "",
    nineteen_text: "",
    one_one_yes: "",
    one_one_no: "",
    one_one_text: "",
    one_two_yes: "",
    one_two_no: "",
    one_two_text: "",
    one_three_yes: "",
    one_three_no: "",
    one_three_text: "",
    one_four_yes: "",
    one_four_no: "",
    one_four_text: "",
    kidneyDisease: "",
    one_five_yes: "",
    one_five_no: "",
    one_five_text: "",
    one_six_yes: "",
    one_six_no: "",
    one_six_text: "",
    pregnant_yes: "",
    pregnant_no: "",
    pregnant_text: "",
    date_1: moment().format("MM-DD-YYYY"),
    signatureDate: "",
    individualSignature: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Before Submission:", formData);

    let fileName;

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      fileName = await data.fileName;
    }

    const payload = {
      patient_signature: fileName,
      last_food: formData.last_food,
      last_fluid: formData.last_fluid,
      bp: formData.bp,
      pulse: formData.pulse,
      resp: formData.resp,
      temp: formData.temp,
      o2_stat: formData.o2_stat,
      current_medications: formData.current_medications,
      previous_surgeries: formData.previous_surgeries,
      height: formData.height,
      weight: formData.weight,
      traveled: formData.traveled,
      med_allergies_yes: formData.med_allergies_yes,
      med_allergies_no: formData.med_allergies_no,
      food_allergies_yes: formData.food_allergies_yes,
      food_allergies_no: formData.food_allergies_no,
      med_allergies_text: formData.med_allergies_text,
      food_allergies_text: formData.food_allergies_text,
      sleep_apnea_yes: formData.sleep_apnea_yes,
      sleep_apnea_no: formData.sleep_apnea_no,
      latex_allery_yes: formData.latex_allery_yes,
      latex_allergy_no: formData.latex_allergy_no,
      preferred_language: formData.preferred_language,
      one_yes: formData.one_yes,
      one_no: formData.one_no,
      one_text: formData.one_text,
      two_yes: formData.two_yes,
      two_no: formData.two_no,
      two_text: formData.two_text,
      twoa_yes: formData.twoa_yes,
      twoa_no: formData.twoa_no,
      twoa_text: formData.twoa_text,
      two_b_yes: formData.two_b_yes,
      two_b_no: formData.two_b_no,
      two_b_text: formData.two_b_text,
      two_c_yes: formData.two_c_yes,
      two_c_no: formData.two_c_no,
      two_c_text: formData.two_c_text,
      two_d_yes: formData.two_d_yes,
      two_d_no: formData.two_d_no,
      two_d_text: formData.two_d_text,
      three_yes: formData.three_yes,
      three_no: formData.three_no,
      three_text: formData.three_text,
      four_yes: formData.four_yes,
      four_no: formData.four_no,
      four_text: formData.four_text,
      five_yes: formData.five_yes,
      five_no: formData.five_no,
      five_text: formData.five_text,
      six_yes: formData.six_yes,
      six_no: formData.six_no,
      six_text: formData.six_text,
      seven_yes: formData.seven_yes,
      seven_no: formData.seven_no,
      seven_text: formData.seven_text,
      eight_yes: formData.eight_yes,
      eight_no: formData.eight_no,
      eight_text: formData.eight_text,
      nine_yes: formData.nine_yes,
      nine_no: formData.nine_no,
      nine_text: formData.nine_text,
      ten_yes: formData.ten_yes,
      ten_no: formData.ten_no,
      ten_text: formData.ten_text,
      eleven_yes: formData.eleven_yes,
      eleven_no: formData.eleven_no,
      eleven_text: formData.eleven_text,
      twelve_yes: formData.twelve_yes,
      twelve_no: formData.twelve_no,
      twelve_text: formData.twelve_text,
      thirteen_yes: formData.thirteen_yes,
      thirteen_no: formData.thirteen_no,
      thirteen_text: formData.thirteen_text,
      fourteen_yes: formData.fourteen_yes,
      fourteen_no: formData.fourteen_no,
      fourteen_text: formData.fourteen_text,
      fifteen_yes: formData.fifteen_yes,
      fifteen_no: formData.fifteen_no,
      fifteen_text: formData.fifteen_text,
      sixteen_yes: formData.sixteen_yes,
      sixteen_no: formData.sixteen_no,
      sixteen_text: formData.sixteen_text,
      seventeen_yes: formData.seventeen_yes,
      seventeen_no: formData.seventeen_no,
      seventeen_text: formData.seventeen_text,
      eighteen_yes: formData.eighteen_yes,
      eighteen_no: formData.eighteen_no,
      eighteen_text: formData.eighteen_text,
      nineteen_yes: formData.nineteen_yes,
      nineteen_no: formData.nineteen_no,
      nineteen_text: formData.nineteen_text,
      one_one_yes: formData.one_one_yes,
      one_one_no: formData.one_one_no,
      one_one_text: formData.one_one_text,
      one_two_yes: formData.one_two_yes,
      one_two_no: formData.one_two_no,
      one_two_text: formData.one_two_text,
      one_three_yes: formData.one_three_yes,
      one_three_no: formData.one_three_no,
      one_three_text: formData.one_three_text,
      one_four_yes: formData.one_four_yes,
      one_four_no: formData.one_four_no,
      one_four_text: formData.one_four_text,
      one_five_yes: formData.one_five_yes,
      one_five_no: formData.one_five_no,
      one_five_text: formData.one_five_text,
      one_six_yes: formData.one_six_yes,
      one_six_no: formData.one_six_no,
      one_six_text: formData.one_six_text,
      pregnant_yes: formData.pregnant_yes,
      pregnant_no: formData.pregnant_no,
      pregnant_text: formData.pregnant_text,
      date_1: formData.date_1,
      status: "In Progress",
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
    };


    // console.log(payload);

    // Fetch 2: 
    updateAnesthesiaQues(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAnesthesiaQues(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setFormData(data);

        if (data.status !== "Not Started") {
          setFormData((prevState) => ({
            ...prevState,
            last_food: data.last_food || "",
            last_fluid: data.last_fluid || "",
            bp: data.bp || "",
            pulse: data.pulse || "",
            resp: data.resp || "",
            temp: data.temp || "",
            o2_stat: data.o2_stat || "",
            current_medications: data.current_medications || "",
            previous_surgeries: data.previous_surgeries || "",
            height: data.height || "",
            weight: data.weight || "",
            traveled: data.traveled || "",
            med_allergies_yes: data.med_allergies_yes || "",
            med_allergies_no: data.med_allergies_no || "",
            food_allergies_yes: data.food_allergies_yes || "",
            food_allergies_no: data.food_allergies_no || "",
            med_allergies_text: data.med_allergies_text || "",
            food_allergies_text: data.food_allergies_text || "",
            sleep_apnea_yes: data.sleep_apnea_yes || "",
            sleep_apnea_no: data.sleep_apnea_no || "",
            latex_allery_yes: data.latex_allery_yes || "",
            latex_allergy_no: data.latex_allergy_no || "",
            preferred_language: data.preferred_language || "",
            one_yes: data.one_yes || "",
            one_no: data.one_no || "",
            one_text: data.one_text || "",
            two_yes: data.two_yes || "",
            two_no: data.two_no || "",
            two_text: data.two_text || "",
            twoa_yes: data.twoa_yes || "",
            twoa_no: data.twoa_no || "",
            twoa_text: data.twoa_text || "",
            two_b_yes: data.two_b_yes || "",
            two_b_no: data.two_b_no || "",
            two_b_text: data.two_b_text || "",
            two_c_yes: data.two_c_yes || "",
            two_c_no: data.two_c_no || "",
            two_c_text: data.two_c_text || "",
            two_d_yes: data.two_d_yes || "",
            two_d_no: data.two_d_no || "",
            two_d_text: data.two_d_text || "",
            three_yes: data.three_yes || "",
            three_no: data.three_no || "",
            three_text: data.three_text || "",
            four_yes: data.four_yes || "",
            four_no: data.four_no || "",
            four_text: data.four_text || "",
            five_yes: data.five_yes || "",
            five_no: data.five_no || "",
            five_text: data.five_text || "",
            six_yes: data.six_yes || "",
            six_no: data.six_no || "",
            six_text: data.six_text || "",
            seven_yes: data.seven_yes || "",
            seven_no: data.seven_no || "",
            seven_text: data.seven_text || "",
            eight_yes: data.eight_yes || "",
            eight_no: data.eight_no || "",
            eight_text: data.eight_text || "",
            nine_yes: data.nine_yes || "",
            nine_no: data.nine_no || "",
            nine_text: data.nine_text || "",
            ten_yes: data.ten_yes || "",
            ten_no: data.ten_no || "",
            ten_text: data.ten_text || "",
            eleven_yes: data.eleven_yes || "",
            eleven_no: data.eleven_no || "",
            eleven_text: data.eleven_text || "",
            twelve_yes: data.twelve_yes || "",
            twelve_no: data.twelve_no || "",
            twelve_text: data.twelve_text || "",
            thirteen_yes: data.thirteen_yes || "",
            thirteen_no: data.thirteen_no || "",
            thirteen_text: data.thirteen_text || "",
            fourteen_yes: data.fourteen_yes || "",
            fourteen_no: data.fourteen_no || "",
            fourteen_text: data.fourteen_text || "",
            fifteen_yes: data.fifteen_yes || "",
            fifteen_no: data.fifteen_no || "",
            fifteen_text: data.fifteen_text || "",
            sixteen_yes: data.sixteen_yes || "",
            sixteen_no: data.sixteen_no || "",
            sixteen_text: data.sixteen_text || "",
            seventeen_yes: data.seventeen_yes || "",
            seventeen_no: data.seventeen_no || "",
            seventeen_text: data.seventeen_text || "",
            eighteen_yes: data.eighteen_yes || "",
            eighteen_no: data.eighteen_no || "",
            eighteen_text: data.eighteen_text || "",
            nineteen_yes: data.nineteen_yes || "",
            nineteen_no: data.nineteen_no || "",
            nineteen_text: data.nineteen_text || "",
            one_one_yes: data.one_one_yes || "",
            one_one_no: data.one_one_no || "",
            one_one_text: data.one_one_text || "",
            one_two_yes: data.one_two_yes || "",
            one_two_no: data.one_two_no || "",
            one_two_text: data.one_two_text || "",
            one_three_yes: data.one_three_yes || "",
            one_three_no: data.one_three_no || "",
            one_three_text: data.one_three_text || "",
            one_four_yes: data.one_four_yes || "",
            one_four_no: data.one_four_no || "",
            one_four_text: data.one_four_text || "",
            one_five_yes: data.one_five_yes || "",
            one_five_no: data.one_five_no || "",
            one_five_text: data.one_five_text || "",
            one_six_yes: data.one_six_yes || "",
            one_six_no: data.one_six_no || "",
            one_six_text: data.one_six_text || "",
            pregnant_yes: data.pregnant_yes || "",
            pregnant_no: data.pregnant_no || "",
            pregnant_text: data.pregnant_text || "",
            date_1: data.date_1 || moment().format("MM-DD-YYYY"),
            signatureDate: data.signatureDate || "",
            individualSignature: data.individualSignature || "",
          }));
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <p>Loading...</p>;
  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={(e) => handleSubmit(e)}>
          {" "}
          <h2 className="form-title">Anethesia Questionnaire</h2>
          <div className="aq-inputs-row">
            <div className="aq-input-item">
              <label>LAST FOOD</label>
              <input
                type="text"
                value={formData.last_food}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    last_food: e.target.value,
                  })
                }
              />
            </div>
            <div className="aq-input-item">
              <label>LAST FLUID</label>
              <input
                type="text"
                value={formData.last_fluid}
                onChange={(e) =>
                  setFormData({ ...formData, last_fluid: e.target.value })
                }
              />
            </div>
            <div className="aq-input-item">
              <label>BP</label>
              <input
                type="text"
                value={formData.bp}
                onChange={(e) =>
                  setFormData({ ...formData, bp: e.target.value })
                }
              />
            </div>
            <div className="aq-input-item">
              <label>PULSE</label>
              <input
                type="text"
                value={formData.pulse}
                onChange={(e) =>
                  setFormData({ ...formData, pulse: e.target.value })
                }
              />
            </div>
            <div className="aq-input-item">
              <label>RESP</label>
              <input
                type="text"
                value={formData.resp}
                onChange={(e) =>
                  setFormData({ ...formData, resp: e.target.value })
                }
              />
            </div>
            <div className="aq-input-item">
              <label>TEMP</label>
              <input
                type="text"
                value={formData.temp}
                onChange={(e) =>
                  setFormData({ ...formData, temp: e.target.value })
                }
              />
            </div>
            <div className="aq-input-item">
              <label>O2 STAT</label>
              <input
                type="text"
                value={formData.o2_stat}
                onChange={(e) =>
                  setFormData({ ...formData, o2_stat: e.target.value })
                }
              />
            </div>
          </div>
          <div className="aq-medical-form">
            <div className="aq-form-section">
              <h4>DRUGS AND MEDICATION:</h4>
              <p>
                List all medications you take and the dosages, including herbal
                supplements, vitamins, over-the-counter drugs, and recreational
                drugs use if any:
              </p>
              <input
                type="text"
                className="long-input"
                value={formData.current_medications}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    current_medications: e.target.value,
                  })
                }
              />
              <h4>SURGERIES:</h4>
              <p>
                List ALL previous operations, year and type of anesthesia.
                (general, local, spinal):
              </p>
              <input
                type="text"
                className="long-input"
                value={formData.previous_surgeries}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    previous_surgeries: e.target.value,
                  })
                }
              />
              <p>
                Height:{" "}
                <input
                  type="text"
                  className="short-input"
                  value={formData.height}
                  onChange={(e) =>
                    setFormData({ ...formData, height: e.target.value })
                  }
                />{" "}
                Weight:{" "}
                <input
                  type="text"
                  className="short-input"
                  value={formData.weight}
                  onChange={(e) =>
                    setFormData({ ...formData, weight: e.target.value })
                  }
                />
              </p>
              <p>
                Have you Traveled anywhere in the past 30 days?{" "}
                <input
                  type="text"
                  className="long-input"
                  value={formData.traveled}
                  onChange={(e) =>
                    setFormData({ ...formData, traveled: e.target.value })
                  }
                />
              </p>
            </div>
            <div className="aq-form-section">
              <p>
                Do you have any MEDICATION ALLERGIES?
                <br />
                <input
                  type="checkbox"
                  value={formData.med_allergies_yes}
                  checked={formData.med_allergies_yes}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      med_allergies_yes: true,
                      med_allergies_no: false,
                    })
                  }
                />
                YES
                <input
                  type="checkbox"
                  value={formData.med_allergies_no}
                  checked={formData.med_allergies_no}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      med_allergies_no: true,
                      med_allergies_yes: false,
                    })
                  }
                />{" "}
                NO
              </p>
              <p>
                If yes, please list:{" "}
                <input
                  type="text"
                  className="long-input"
                  value={formData.med_allergies_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      med_allergies_text: e.target.value,
                    })
                  }
                />
              </p>
              <p>
                Do you have any FOOD ALLERGIES?
                <br />
                <input
                  type="checkbox"
                  value={formData.food_allergies_yes}
                  checked={formData.food_allergies_yes}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      food_allergies_yes: true,
                      food_allergies_no: false,
                    })
                  }
                />{" "}
                YES{" "}
                <input
                  type="checkbox"
                  value={formData.food_allergies_no}
                  checked={formData.food_allergies_no}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      food_allergies_no: true,
                      food_allergies_yes: false,
                    })
                  }
                />{" "}
                NO
              </p>
              <p>
                If yes, please list:
                <input
                  type="text"
                  className="long-input"
                  value={formData.food_allergies_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      food_allergies_text: e.target.value,
                    })
                  }
                />
              </p>

              <p>
                Do you have SLEEP APNEA? (BRING C-PAP)
                <br />
                <input
                  type="checkbox"
                  value={formData.sleep_apnea_yes}
                  checked={formData.sleep_apnea_yes}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      sleep_apnea_yes: true,
                      sleep_apnea_no: false,
                    })
                  }
                />{" "}
                YES{" "}
                <input
                  type="checkbox"
                  value={formData.sleep_apnea_no}
                  checked={formData.sleep_apnea_no}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      sleep_apnea_no: true,
                      sleep_apnea_yes: false,
                    })
                  }
                />{" "}
                NO
              </p>
              <p>If yes, please call 562-270-4327 IMMEDIATELY</p>

              <p>
                Any allergies to LATEX or RUBBER products?
                <br />
                <input
                  type="checkbox"
                  value={formData.latex_allery_yes}
                  checked={formData.latex_allery_yes}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      latex_allery_yes: true,
                      latex_allergy_no: false,
                    })
                  }
                />{" "}
                YES{" "}
                <input
                  type="checkbox"
                  value={formData.latex_allergy_no}
                  checked={formData.latex_allergy_no}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      latex_allergy_no: true,
                      latex_allergy_yes: false,
                    })
                  }
                />{" "}
                NO
              </p>
            
              <p>
                Preferred Language:{" "}
                <input
                  type="text"
                  className="long-input"
                  value={formData.preferred_language}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preferred_language: e.target.value,
                    })
                  }
                />
              </p>
            </div>
          </div>
          <div>
            <h4>HAVE YOU HAD:</h4>
            <ol className="aq-form-list">
              <li className="aq-form-item">
                <p className="hyh-title">1. High Blood Pressure</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_yes}
                      checked={formData.one_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_yes: true,
                          one_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_no}
                      checked={formData.one_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_no: true,
                          one_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.one_text}
                  onChange={(e) =>
                    setFormData({ ...formData, one_text: e.target.value })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">2. Heart trouble or Heart Attack</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.two_yes}
                      checked={formData.two_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          two_yes: true,
                          two_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.two_no}
                      checked={formData.two_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          two_no: true,
                          two_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.two_text}
                  onChange={(e) =>
                    setFormData({ ...formData, two_text: e.target.value })
                  }
                />
              </li>
              <li className="aq-form-item list-letters">
                <p className="hyh-title">a. Chest pain or angina</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.twoa_yes}
                      checked={formData.twoa_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          twoa_yes: true,
                          twoa_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.twoa_no}
                      checked={formData.twoa_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          twoa_no: true,
                          twoa_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.twoa_text}
                  onChange={(e) =>
                    setFormData({ ...formData, twoa_text: e.target.value })
                  }
                />
              </li>
              <li className="aq-form-item list-letters">
                <p className="hyh-title">b. Irregular Heart beat</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.two_b_yes}
                      checked={formData.two_b_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          two_b_yes: true,
                          two_b_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.two_b_no}
                      checked={formData.two_b_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          two_b_no: true,
                          two_b_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.two_b_text}
                  onChange={(e) =>
                    setFormData({ ...formData, two_b_text: e.target.value })
                  }
                />
              </li>
              <li className="aq-form-item list-letters">
                <p className="hyh-title">c. Congestive heart failure</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.two_c_yes}
                      checked={formData.two_c_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          two_c_yes: true,
                          two_c_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.two_c_no}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          two_c_text: e.target.value,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.two_c_text}
                  onChange={(e) =>
                    setFormData({ ...formData, two_c_text: e.target.value })
                  }
                />
              </li>
              <li className="aq-form-item list-letters">
                <p className="hyh-title">d. abnormal electrocardiogram</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.two_d_yes}
                      checked={formData.two_d_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          two_d_yes: true,
                          two_d_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.two_d_no}
                      checked={formData.two_d_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          two_d_no: true,
                          two_d_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.two_d_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      two_d_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  3. Gastric Esophageal Reflux, Hiatal Hernia, Ulcers
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.three_yes}
                      checked={formData.three_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          three_yes: true,
                          three_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.three_no}
                      checked={formData.three_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          three_no: true,
                          three_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.three_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      three_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">4. Recent cold,cough or sore throat</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.four_yes}
                      checked={formData.four_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          four_yes: true,
                          four_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.four_no}
                      checked={formData.four_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          four_no: true,
                          four_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.four_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      four_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  5. Asthma, Emphysema, Bronchitis, or Breathing problems
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.five_yes}
                      checked={formData.five_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          five_yes: true,
                          five_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.five_no}
                      checked={formData.five_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          five_no: true,
                          five_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.five_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      five_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">6. Abdominal Chest X-ray</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.six_yes}
                      checked={formData.six_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          six_yes: true,
                          six_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.six_no}
                      checked={formData.six_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          six_no: true,
                          six_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.six_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      six_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">7. Diabetes</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.seven_yes}
                      checked={formData.seven_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          seven_yes: true,
                          seven_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.seven_no}
                      checked={formData.seven_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          seven_no: true,
                          seven_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.seven_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      seven_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">8. Yellow Jaundice or hepatitis</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.eight_yes}
                      checked={formData.eight_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          eight_yes: true,
                          eight_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.eight_no}
                      checked={formData.eight_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          eight_no: true,
                          eight_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.eight_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      eight_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">9. Kidney Disease</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.nine_yes}
                      checked={formData.nine_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          nine_yes: true,
                          nine_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.nine_no}
                      checked={formData.nine_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          nine_no: true,
                          nine_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.nine_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      nine_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">10. Abdominal bleeding problems </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.ten_yes}
                      checked={formData.ten_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          ten_yes: true,
                          ten_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.ten_no}
                      checked={formData.ten_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          ten_no: true,
                          ten_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.ten_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ten_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">11. Stroke, numbness or weakness</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.eleven_yes}
                      checked={formData.eleven_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          eleven_yes: true,
                          eleven_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.eleven_no}
                      checked={formData.eleven_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          eleven_no: true,
                          eleven_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.eleven_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      eleven_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  12. Epilepsy or convulsive seizures{" "}
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.twelve_yes}
                      checked={formData.twelve_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          twelve_yes: true,
                          twelve_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.twelve_no}
                      checked={formData.twelve_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          twelve_no: true,
                          twelve_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.twelve_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      twelve_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  13. Broken bones of back,neck or face{" "}
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.thirteen_yes}
                      checked={formData.thirteen_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          thirteen_yes: true,
                          thirteen_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.thirteen_no}
                      checked={formData.thirteen_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          thirteen_no: true,
                          thirteen_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.thirteen_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      thirteen_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">14. Back trouble </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.fourteen_yes}
                      checked={formData.fourteen_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          fourteen_yes: true,
                          fourteen_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.fourteen_no}
                      checked={formData.fourteen_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          fourteen_no: true,
                          fourteen_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.fourteen_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      fourteen_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  15. Unusual muscle problems or diseases{" "}
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.fifteen_yes}
                      checked={formData.fifteen_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          fifteen_yes: true,
                          fifteen_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.fifteen_no}
                      checked={formData.fifteen_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          fifteen_no: true,
                          fifteen_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.fifteen_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      fifteen_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  16. Unexpected fevers or heat strokes{" "}
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.sixteen_yes}
                      checked={formData.sixteen_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          sixteen_yes: true,
                          sixteen_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.sixteen_no}
                      checked={formData.sixteen_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          sixteen_no: true,
                          sixteen_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.sixteen_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      sixteen_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">17. Bad reaction to anesthesia </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.seventeen_yes}
                      checked={formData.seventeen_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          seventeen_yes: true,
                          seventeen_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.seventeen_no}
                      checked={formData.seventeen_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          seventeen_no: true,
                          seventeen_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.seventeen_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      seventeen_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  18. Relatives with bad reaction to anethesia{" "}
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.eighteen_yes}
                      checked={formData.eighteen_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          eighteen_yes: true,
                          eighteen_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.eighteen_no}
                      checked={formData.eighteen_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          eighteen_no: true,
                          eighteen_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.eighteen_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      eighteen_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">19. Problems with motion sickness </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.nineteen_yes}
                      checked={formData.nineteen_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          nineteen_yes: true,
                          nineteen_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.nineteen_no}
                      checked={formData.nineteen_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          nineteen_no: true,
                          nineteen_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.nineteen_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      nineteen_text: e.target.value,
                    })
                  }
                />
              </li>
            </ol>
          </div>
          <div>
            <h4>DO YOU:</h4>

            <ol className="aq-form-list">
              <li className="aq-form-item">
                <p className="hyh-title">1. Wear dentures</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_one_yes}
                      checked={formData.one_one_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_one_yes: true,
                          one_one_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_one_no}
                      checked={formData.one_one_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_one_no: true,
                          one_one_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.one_one_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      one_one_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">2. Have caps on teeth</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_two_yes}
                      checked={formData.one_two_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_two_yes: true,
                          one_two_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_two_no}
                      checked={formData.one_two_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_two_no: true,
                          one_two_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.one_two_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      one_two_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title"> 3. Drink alcohol</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_three_yes}
                      checked={formData.one_three_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_three_yes: true,
                          one_three_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_three_no}
                      checked={formData.one_three_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_three_no: true,
                          one_three_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.one_three_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      one_three_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">4. Smoke (how much per day/week)</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_four_yes}
                      checked={formData.one_four_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_four_yes: true,
                          one_four_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_four_no}
                      checked={formData.one_four_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_four_no: true,
                          one_four_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.one_four_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      one_four_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  5. Exercise or have strenuous activity
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_five_yes}
                      checked={formData.one_five_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_five_yes: true,
                          one_five_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_five_no}
                      checked={formData.one_five_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_five_no: true,
                          one_five_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.one_five_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      one_five_text: e.target.value,
                    })
                  }
                />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">6. Have any physical limitations</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_six_yes}
                      checked={formData.one_six_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_six_yes: true,
                          one_six_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.one_six_no}
                      checked={formData.one_six_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          one_six_no: true,
                          one_six_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.one_six_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      one_six_text: e.target.value,
                    })
                  }
                />
              </li>
            </ol>
          </div>
          <div>
            <ol className="aq-form-list aq-pregnant">
              <li className="aq-form-item">
                <h4>ARE YOU PREGNANT</h4>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.pregnant_yes}
                      checked={formData.pregnant_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          pregnant_yes: true,
                          pregnant_no: false,
                        })
                      }
                    />{" "}
                    YES
                  </label>
                  <label className="aq-option-label">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={formData.pregnant_no}
                      checked={formData.pregnant_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          pregnant_no: true,
                          pregnant_yes: false,
                        })
                      }
                    />{" "}
                    NO
                  </label>
                </div>
                <input
                  type="text"
                  className="long-input"
                  value={formData.pregnant_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      pregnant_text: e.target.value,
                    })
                  }
                />
              </li>
            </ol>
          </div>
          <div className="grid-2">
            <div className="signature-container mt-50">
              <SignatureButton
                individualSignature={individualSignature}
                setIndividualSignature={setIndividualSignature}
              />
              <hr className="signature-line"></hr>
              <p>Patient Signature</p>
            </div>

            <div className="mt-50">
              <label htmlFor="name" className="hidden">
                Patient Siganture
              </label>
              <input
                type="date"
                id="name"
                name="name"
                className="bottom-border-input"
                required
                value={formData.date_1}
                onChange={(e) =>
                  setFormData({ ...formData, date_1: e.target.value })
                }
              />

              <p>Date</p>
            </div>
          </div>
          {/* <div className="grid-2">
            <div className="signature-container mt-50">
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Nurse Signature</p>
            </div>

            <div className="mt-50">
              <label htmlFor="name" className="hidden">
                Nurse Signature
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
                required
              />

              <p>Date</p>
            </div> */}
          {/* </div> */}
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AnethesiaQuestionnaire;
