import { useNavigate } from "react-router-dom";
import "./signupSuccess.css";
import footerLogo from "../../../assets/images/sidebar_logo.webp";
import bgImage from "../../../assets/images/background_logo.webp";

const SignupSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="auth-background-logo">
        <img
          src={bgImage}
          alt="Cornerstone Logo"
          className="background-image"
        />
      </div>

      <div className="signup-success-page">
        <h1>Thank you for signing up!</h1>
        <p>
          Your account is pending Admin approval. Once approved, you'll be able
          to log in on the home screen.
        </p>

        <button
          onClick={() => {
            navigate("/");
          }}
        >
          Return to home
        </button>
      </div>

      <footer className="signup-footer">
        <img src={footerLogo} alt="cornerstone" />
      </footer>
    </>
  );
};

export default SignupSuccess;
