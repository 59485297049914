import React, { useState } from "react";
import DrawingPad from "../DrawingPad";
import signImage from "../../assets/sign.png";
import "./signbtn.css";

const SignatureButton = ({ individualSignature, setIndividualSignature }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {/* Sign Here Button */}
      <button
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
        className="sign-btn"
      >
        <p>Sign Here</p>
        <img src={signImage} alt="down arrow" />
      </button>

      {/* Drawing Modal */}
      {isOpen === true ? (
        <DrawingPad
          setIsOpen={setIsOpen}
          individualSignature={individualSignature}
          setIndividualSignature={setIndividualSignature}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SignatureButton;
