import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import SignatureButton from "../../../components/SignatureButton";
import "./styles.css";
import {
  addFile,
  getGrievancePolicy,
  updateGrievancePolicy,
} from "../../../utils/api";
import { useParams } from "react-router-dom";
import moment from "moment";

const Grievance = () => {
  const { form_id } = useParams();
  const navigate = useNavigate();

  const [patientName, setPatientName] = useState();
  const [dateOne, setDateOne] = useState();
  const [dateTwo, setDateTwo] = useState();
  const [timeOne, setTimeOne] = useState();
  const [timeTwo, setTimeTwo] = useState();
  const [signee, setSignee] = useState("");
  const [individualSignature, setIndividualSignature] = useState("");
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let fileName;

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);
      const data = await response.json();
      fileName = await data.fileName;
    }

    const payload = {
      time_1: moment().format("h:mm a"),
      time_2: moment().format("h:mm a"),

      date_1: moment().format("MM/DD/YYYY"),
      date_2: moment().format("MM/DD/YYYY"),

      patient_signature: fileName,
      signee: signee,
      patient_name: patientName,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log(payload);

    // Fetch 2:
    updateGrievancePolicy(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getGrievancePolicy(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setPatientName(
            data.patient_name === false ? false : data.patient_name || ""
          );
          setDateOne(data.date_1);
          setDateTwo(data.date_2);
          setTimeOne(data.time_1);
          setTimeTwo(data.time_2);
          setSignee(data.signee);
          setIndividualSignature(data.patient_signature);
        }
        setPatientName(data.patient_name);

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <div className="form-content">
        <div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <p>
              <strong>
                <h2>PATIENT COMPLAINT OR GRIEVANCE</h2>
              </strong>
            </p>
            <ul>
              <li>
                If you have a problem or complaint, please speak to the
                receptionist or your caregiver. We will address your concern(s)
                promptly.{" "}
              </li>
              <li>
                If necessary, your problem or complaint will be routed to the
                Administrator and/or Quality Assurance coordinator for
                resolution. You will receive a letter or phone call to inform
                you of the actions taken to address your complaint.
              </li>
              <li>
                If you are not satisfied with the response of the Surgery
                Center, you may contact:
              </li>
            </ul>
            <div className="lh-center">
              {" "}
              <p className="text-center">Department of Health Services</p>
              <p className="text-center">Division of Health Facilities</p>
              <p className="text-center">
                2150 Towne Centre Place, Anaheim, CA 92806
              </p>
              <p className="text-center">714.456.0630</p>
              <p className="text-center">Or</p>
              <p className="text-center">The Joint Commission</p>
              <p className="text-center">One Renaissance Blvd.</p>
              <p className="text-center">Oakbrook Terrace, IL 60181</p>
              <p className="text-center">630.792.5000</p>
              <p className="text-center lh-center">
                All Medicare beneficiaries may file a complaint or grievance
                with the Medicare Beneficiary Ombudsman. You may call
                1-800-MEDICARE and they will direct your inquiry to the Medicare
                Ombudsman. You may write to them at:
              </p>
              <p className="text-center lh-center">
                Center for Medicare and Medicaid Services
              </p>
              <p className="text-center">7500 Security Boulevard</p>
              <p className="text-center">Baltimore MD 21244</p>
              <p className="text-center lh-center">
                You may visit the Ombudsman’s webpage at:
              </p>
              <p className="text-center">www.cms.hhs.gov/center/ombudsman</p>
              <p className="text-center lh-center">
                <strong>CALIFORNIA MEDICAL BOARD</strong>
              </p>
              <p className="text-center">
                All physicians practicing in this facility are licensed by and
                are regulated by the Medical Board of California. Any
                information regarding your physician may be addressed to:
              </p>
              <p className="text-center lh-center">
                <strong>Medical Board of California</strong>
              </p>
              <p className="text-center">(800) 633-2322. www.mbc.ca.gov</p>
              <p className="text-center">
                I received information on patient rights and responsibilities,
                physician ownership disclosure, advance directive policy and the
                grievance policy at least one.
              </p>
            </div>
            <div className="grievance-container mt-50">
              <div className="gc-item">
                {" "}
                <input
                  className="name-input"
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                />
                <hr className="signature-line"></hr>
                <p>Patient Name</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Patient Name
                </label>
                <input
                  type="date"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  onChange={(e) => setDateOne(e.target.value)}
                  required
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="time"
                  id="time"
                  name="time"
                  className="bottom-border-input"
                  required
                  onChange={(e) => setTimeOne(e.target.value)}
                />
                <p>Time</p>
              </div>

              <div className="gc-item">
                {" "}
                <SignatureButton
                  individualSignature={individualSignature}
                  setIndividualSignature={setIndividualSignature}
                />
                <hr className="signature-line"></hr>
                <p>Patient Signature</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Patient Signature
                </label>
                <input
                  type="date"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  required
                  onChange={(e) => setDateTwo(e.target.value)}
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="time"
                  id="time"
                  name="time"
                  onChange={(e) => setTimeTwo(e.target.value)}
                  className="bottom-border-input"
                  required
                />
                <p>Time</p>
              </div>

              {/* <div className="gc-item">
                {" "}
                <SignatureButton
                  value={signee}
                

                  individualSignature={individualSignature}
                  setIndividualSignature={setIndividualSignature}
                />
                <hr className="signature-line"></hr>
                <p>Witness Signature</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Witness Signature
                </label>
                <input
                  type="date"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  required
                  onChange={(e) => setDateThree(e.target.value)}
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="text"
                  id="time"
                  name="time"
                  className="bottom-border-input"
                  required
                  onChange={(e) => setTimeThree(e.target.value)}
                />
                <p>Time</p>
              </div> */}
            </div>
            <div className="center mt-50">
              <button className="btn-primary hover-pointer" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Grievance;
