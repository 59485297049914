import React from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";

const PatientRegistration = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <div className="form-content">
        <form>
          <h2 className="form-title">Patient Registration</h2>
          <div className="pr-form-container">
            <div className="pr-form-row">
              <label>Date</label>
              <input type="text" className="pr-input-line" />
              <label>Home Phone</label>
              <input type="text" className="pr-input-line" />
              <label>Work Phone</label>
              <input type="text" className="pr-input-line" />
              <label>Email</label>
              <input type="text" className="pr-input-line" />
            </div>
            <div className="pr-form-row">
              <label>Patient: Last Name</label>
              <input type="text" className="pr-input-line" />
              <label>First Name</label>
              <input type="text" className="pr-input-line" />
              <label>Middle Initial</label>
              <input type="text" className="pr-input-line" />
            </div>
            <div className="pr-form-row">
              <label>Street Address</label>
              <input type="text" className="pr-input-long-line" />
            </div>
            <div className="pr-form-row">
              <label>City</label>
              <input type="text" className="pr-input-line" />
              <label>State</label>
              <input type="text" className="pr-input-line" />
              <label>Zip</label>
              <input type="text" className="pr-input-line" />
            </div>
            <div className="pr-form-row">
              <label>Sex</label>
              <div className="radio-group">
                <label>
                  <input type="radio" name="pr-sex" /> M
                </label>
                <label>
                  <input type="radio" name="pr-sex" /> F
                </label>
              </div>
              <label>Age</label>
              <input type="text" className="pr-input-line" />
              <label>Birth date</label>
              <input type="text" className="pr-input-line" />
              <div className="radio-group">
                <label>
                  <input type="radio" name="pr-status" /> Single
                </label>
                <label>
                  <input type="radio" name="pr-status" /> Married
                </label>
                <label>
                  <input type="radio" name="pr-status" /> Widowed
                </label>
                <label>
                  <input type="radio" name="pr-status" /> Separated
                </label>
                <label>
                  <input type="radio" name="pr-status" /> Divorced
                </label>
              </div>
            </div>

            <div className="pr-form-row">
              <label>Social Security #</label>
              <input type="text" className="pr-input-line" />
              <label>Driver's License #</label>
              <input type="text" className="pr-input-line" />
            </div>
            <div className="pr-form-row">
              <label>Insured Name</label>
              <input
                type="text"
                className="pr-input-line"
                placeholder="Last Name"
              />
              <input
                type="text"
                className="pr-input-line"
                placeholder="First Name"
              />
              <input
                type="text"
                className="pr-input-line"
                placeholder="Initial"
              />
              <label>How and where did you learn about this clinic?</label>
              <input type="text" className="pr-input-long-line" />
            </div>

            <div className="pr-form-row">
              <label>Relationship to Insured:</label>
              <div className="radio-group">
                <label>
                  <input type="radio" name="pr-relationship" /> Self
                </label>
                <label>
                  <input type="radio" name="pr-relationship" /> Spouse
                </label>
                <label>
                  <input type="radio" name="pr-relationship" /> Child
                </label>
                <label>
                  <input type="radio" name="pr-relationship" /> Other
                </label>
              </div>
            </div>

            <div className="pr-form-row">
              <label>Condition/Illness Related To:</label>
              <div className="radio-group">
                <label>
                  <input type="radio" name="pr-condition" /> Illness
                </label>
                <label>
                  <input type="radio" name="pr-condition" /> Employment
                </label>
                <label>
                  <input type="radio" name="pr-condition" /> Auto
                </label>
                <label>
                  <input type="radio" name="pr-condition" /> Other
                </label>
              </div>
            </div>
          </div>
          <div className="pr-container">
            <div className="pr-row">
              <div className="pr-col-20">
                <strong>INSURED EMPLOYER </strong>
              </div>
              <div className="pr-col-80">
                <div className="pr-row ie-row">
                  <label>Company Name</label>
                  <input type="text" className="employment-input" />
                  <label>Occupation</label>
                  <input type="text" className="employment-input" />
                </div>
                <div className="pr-row ie-row">
                  <label>Address</label>
                  <input type="text" className="employment-input-long" />
                  <label>Phone</label>
                  <input type="text" className="employment-input" />
                  <label>
                    <input type="radio" name="employment-status" /> Full-time
                  </label>
                  <label>
                    <input type="radio" name="employment-status" /> Part-time
                  </label>
                </div>
                <div className="pr-row ie-row">
                  <label>City</label>
                  <input type="text" className="employment-input " />
                  <label>State</label>
                  <input
                    type="text"
                    className="employment-input ie-short-input"
                  />
                  <label>Zip</label>
                  <input
                    type="text"
                    className="employment-input ie-short-input"
                  />
                  <label>Years Employed</label>
                  <input
                    type="text"
                    className="employment-input ie-short-input"
                  />
                </div>
              </div>
            </div>
            <div className="pr-row">
              <div className="pr-col-20">
                <strong>SPOUSE(PARENT) </strong>
              </div>
              <div className="pr-col-80">
                <div className="employment-form">
                  <div className="employment-row">
                    <label>Name</label>
                    <input
                      type="text"
                      className="employment-input"
                      placeholder="Last Name"
                    />
                    <input
                      type="text"
                      className="employment-input"
                      placeholder="First Name"
                    />
                    <input
                      type="text"
                      className="employment-input"
                      placeholder="Initial"
                    />
                  </div>
                  <div className="employment-row">
                    <label>Birth Date</label>
                    <input type="text" className="employment-input" />
                    <label>SSN:</label>
                    <input type="text" className="employment-input" />
                  </div>
                  <div className="employment-row">
                    <label>Address</label>
                    <input type="text" className="employment-input-long" />
                  </div>
                  <div className="employment-row">
                    <label>City</label>
                    <input type="text" className="employment-input" />
                    <label>State</label>
                    <input type="text" className="employment-input" />
                  </div>
                  <div className="employment-row">
                    <label>Zip</label>
                    <input type="text" className="employment-input" />
                    <label>Phone</label>
                    <input type="text" className="employment-input" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pr-row">
              <div className="pr-col-20">
                <strong>PATIENT INSURANCE INFORMATION </strong>
              </div>
              <div className="pr-col-80">
                <div className="employment-form">
                  <p className="ef-text">
                    Please list any and all insurance and/or employee health
                    care plan coverage you or your spouse may have
                  </p>
                  <div className="employment-row">
                    <label>Insurance Company or Health Care Plan Name</label>
                    <input type="text" className="employment-input-long" />
                  </div>
                  <div className="employment-row">
                    <label>Policy/Group #</label>
                    <input type="text" className="employment-input" />
                    <label>Effective Date</label>
                    <input type="text" className="employment-input" />
                  </div>
                  <div className="employment-row">
                    <label>Name of Insured</label>
                    <input type="text" className="employment-input-long" />
                    <label>ID #</label>
                    <input type="text" className="employment-input" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pr-row">
              <div className="pr-col-20">
                <strong>MEDICAL AND LEGAL INFORMATION </strong>
              </div>
              <div className="pr-col-80">
                <div className="employment-form">
                  <p className="ef-text">
                    <strong>
                      Are your present symptoms or conditions related to or the
                      result of an auto accident, work-related injury or other
                      personal injury{" "}
                      <u>someone else might be legally liable for?</u>
                    </strong>
                  </p>
                  <label>
                    <input type="radio" name="accident-related" /> Yes
                  </label>
                  <label>
                    <input type="radio" name="accident-related" /> No
                  </label>
                  <label> Your Initials:</label>
                  <input type="text" className="insurance-input-short" />
                  <p >
                    If you answered yes, please fill out accident-specific form,
                    available at the front desk.
                  </p>
                  <div className="employment-row">
                    <label>Pregnant:</label>
                    <label>
                      <input type="radio" name="pregnant" /> Yes
                    </label>
                    <label>
                      <input type="radio" name="pregnant" /> No
                    </label>
                    <label>Pacemaker:</label>
                    <label>
                      <input type="radio" name="pacemaker" /> Yes
                    </label>
                    <label>
                      <input type="radio" name="pacemaker" /> No
                    </label>
                  </div>
                  <div className="employment-row">
                    <label>Family Physician</label>
                    <input type="text" className="employment-input-long" />
                  </div>
                  <div className="employment-row">
                    <label>
                      Person to contact in emergency (Name and Phone #)
                    </label>
                    <input type="text" className="employment-input-long" />
                  </div>
                  <div className="employment-row">
                    <label>Attorney</label>
                    <input type="text" className="employment-input" />
                    <label>Telephone:</label>
                    <input type="text" className="employment-input" />
                  </div>
                  <div className="employment-row">
                    <label>Address</label>
                    <input type="text" className="employment-input-long" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pr-row">
              <div className="pr-col-20">
                <strong>
                  {" "}
                  Patient Agreement & Authorization For The Release Of Medical
                  And Health Plan Documents For The Claims Processing &
                  Reimbursement As Required by Federal and State Laws
                </strong>
              </div>
              <div className="pr-col-80">
                <div className="legal-assignment">
                  <p>
                    Legal Assignment of Benefits and Designation Of Authorized
                    Representative
                  </p>
                  <p>
                    In considering the amount of medical expenses to be
                    incurred, I, the undersigned, have insurance and/or employee
                    health care benefits coverage with the above-captioned, and
                    hereby assign and convey directly to the above named
                    healthcare provider(s),{" "}
                    <u>as my designated Authorized Representative(s)</u>, all
                    medical benefits and/or insurance reimbursement, if any,
                    otherwise payable to me for services rendered from such
                    provider(s), regardless of such provider's managed care
                    network participation status. I understand that I am
                    financially responsible for all charges regardless of any
                    applicable insurance or benefit payments.{" "}
                    <u>
                      I hereby authorize the above named provider(s) to release
                      all medical information necessary to process my claims
                      under HIPAA.
                    </u>{" "}
                    I hereby authorize any plan administrator or fiduciary,
                    insurer and my attorney to release to such provider(s) any
                    and all plan documents, insurance policy and/or settlement
                    information upon written request from such provider(s) in
                    order to claim such medical benefits, reimbursement or any
                    applicable remedies. I authorize the use of this signature
                    on all my insurance and/or employee health benefits claim
                    submissions.
                  </p>
                  <p>
                    I hereby convey to the above named provider(s), to the full
                    extent permissible under the law and under any applicable
                    employee group health plan(s), insurance policies or
                    liability claim, any claim, chose in action, or other right
                    I may have to such group health plans, health insurance
                    issuers or tortfeasor or insurer(s) necessary to claim all
                    applicable insurance policies, employee benefits plan(s) or
                    public policies with respect to medical expenses incurred as
                    a result of the medical services I received from the above
                    named provider(s), and to the full extent permissible under
                    the law to claim or lien such medical benefits, settlement,
                    insurance reimbursement and any applicable remedies,
                    including, but not limited to, (1) obtaining information
                    about the claim to the same extent as the assignor; (2)
                    submitting evidence; (3) making statements about facts or
                    law; (4) making any request, or giving, or receiving any
                    notice about appeal proceedings; and (5) any administrative
                    and judicial actions by such provider(s) to pursue such
                    claim, chose in action or right against any liable party or
                    employee group health plan(s), including, if necessary,
                    bring suit by such provider(s) against any such liable party
                    or employee group health plan in my name with standing but
                    at such provider(s) expenses. Unless revoked, this
                    assignment is valid for all administrative and judicial
                    reviews under PPACA, ERISA, Medicare and applicable federal
                    or state laws. A photocopy of this assignment is to be
                    considered as valid as the original. I have read and fully
                    understand this agreement.
                  </p>
                </div>
                <div className="grid-2">
                  <div className="signature-container mt-50">
                    <SignatureButton />
                    <hr className="signature-line"></hr>
                    <p>Patient Signature</p>
                  </div>

                  <div className="mt-50">
                    <label htmlFor="name" className="hidden">
                      Patient Siganture
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="bottom-border-input"
                      required
                    />

                    <p>Date</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PatientRegistration;
