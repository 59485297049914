import React, { useEffect, useState } from "react";
import bglogo from "../../../assets/images/background_logo.webp";
import { Icon } from "@iconify/react";
import "./patientforms.css";
import { Link, useParams } from "react-router-dom";
import { getPatientById, getSurgerybyId } from "../../../utils/api";

const PatientForms = () => {
  const { id: patient_id, surgery_id } = useParams();
  const [patientData, setPatientData] = useState(null);
  const [dbForms, setDbForms] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get the Patient & Surgery forms on page load
  useEffect(() => {
    if (patient_id) {
      getPatientById(patient_id)
        .then((res) => {
          if (!res.ok) {
            throw new Error("Failed to fetch patient data");
          }
          return res.json();
        })
        .then((data) => {
          console.log(data);
          setPatientData(data);

          getSurgerybyId(surgery_id)
            .then((res) => res.json())
            .then((data) => {
              // console.log(data);
              setDbForms(data.form);
              console.log(data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [patient_id]);

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <div className="patient-grid-container patient-forms-view">
        <div className="patient-first-column patient-forms"></div>
        <div className="patient-second-column">
          <div className="background-logo">
            <img
              src={bglogo}
              alt="Cornerstone Logo"
              className="background-image"
            />
          </div>
          <div className="second-column-item-heading patient-heading box-shadow border-none">
            <div className="patient-info-container">
              <div className="patient-name-medical">
                <h1>
                  {patientData
                    ? `${patientData.first_name} ${patientData.last_name}`
                    : "Loading..."}
                </h1>
                <p>Click on a form below to continue signing.</p>
              </div>
            </div>
            <FormList dbForms={dbForms} />

            <div className="patient-views-btn-container">
              <Link
                to={`/patient/${patient_id}/patient-forms/${surgery_id}/thank-you`}
              >
                <button className="patient-forms-btn">Finish</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const FormList = ({ dbForms }) => {
  const { id: patient_id, surgery_id } = useParams();
  console.log(dbForms);
  const forms = [
    {
      name: "Discharge Instructions",
      status: dbForms.dischargeInstruction?.status || "Not Started",
      link: `/patient-form-discharge-instructions/${dbForms.dischargeInstruction.id}`,
    },
    {
      name: "Patient Satisfaction Survey",
      status: dbForms.patientSatisfaction?.status || "Not Started",
      link: `/patient-form-satisfaction/${dbForms.patientSatisfaction.id}`,
    },
    {
      // TODO: What is this form?
      name: "Patient Consent to Procedure",
      status: dbForms.ConsentProcedure?.status || "Not Started",
      link: `/patient-form-procedure-consent/${surgery_id}`,
    },
    {
      name: "Patient Registration",
      status: dbForms.patientRegistration?.status || "Not Started",
      link: `/patient-form-registration/${dbForms.patientRegistration.id}`,
    },
    {
      name: "Assignment of Benefits",
      status: dbForms.assignmentBenefit?.status || "Not Started",
      link: `/patient-form-assignment-benefits/${dbForms.assignmentBenefit.id}`,
    },
    {
      name: "Patient Consent to Anesthesia",
      status: dbForms.consentAnesthesium?.status || "Not Started",
      link: `/patient-form-consent-anethesia/${
        dbForms.consentAnesthesium?.id || ""
      }`,
    },
    {
      name: "Notice of Privacy Practice",
      status: dbForms.noticePrivacy?.status || "Not Started",
      link: `/patient-form-privacy/${dbForms.noticePrivacy.id}`,
    },
    {
      name: "Disclosure of Ownership/Patient's Rights",
      status: dbForms.disclosureOwnership?.status || "Not Started",
      link: `/patient-form-disclosure-ownership/${dbForms.disclosureOwnership.id}`,
    },
    {
      name: "Grievance Policy",
      status: dbForms.grievancePolicy?.status || "Not Started",
      link: `/patient-form-grievance/${dbForms.grievancePolicy.id}`,
    },
    {
      name: "Anesthesia Questionnaire",
      status: dbForms.AnesthesiaQuestionnaire?.status || "Not Started",
      link: `/patient-form-anethesia-ques/${dbForms.AnesthesiaQuestionnaire.id}`,
    },
    {
      name: "Patient History & Physical",
      status: dbForms.patientHistory?.status || "Not Started",
      link: `/patient-form-patient-history/${dbForms.patientHistory.id}`,
    },
  ];

  return (
    <div className="form-list">
      {forms.map((form, index) => (
        <Link className="consent-card" key={index} to={form.link}>
          <span className="consent-card-label">{form.name}</span>
          <div className="consent-card-status-container">
            <span className="consent-card-status">{form.status}</span>
            <span className="consent-card-icon">
              <Icon
                icon="heroicons-outline:exclamation"
                className="patient-view-icon"
              />
            </span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default PatientForms;
