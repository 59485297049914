import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./newsurgeryform.css";
import Menu from "../Menu/Menu";
import { createSurgery, createSurgeryInformation } from "../../../utils/api";
const moment = require("moment");

const NewSurgeryForm = () => {
  const [type, setType] = useState("");
  const [DOS, setDOS] = useState("");
  const [TOS, setTOS] = useState("");

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      patient_id: id,
      type: type,
      DOS: moment(DOS).format("MM/DD/YYYY"),
      TOS: TOS,
    };

    // Create the Surgery
    // NOTE: THE FORM AND EMPTY FORMS ARE GENERATED PER SURGERY IN THE POST ROUTE
    createSurgery(payload)
      .then((res) => res.json())
      .then(async (data) => {
        // console.log(data);
        const payload2 = { surgery_id: data.newSurgery.id };
        const response2 = await createSurgeryInformation(payload2);

        if (response2.ok) {
          window.location.replace(`/patient/${id}`);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Menu />
      <div className="patient-grid-container">
        <div className="patient-first-column"></div>
        <div className="patient-second-column surgery-info">
          <div className="surgery-form-container">
            <form className="surgery-form">
              <h2>New Surgery Information</h2>

              <div className="surgery-form-group">
                <label htmlFor="surgery-type">Surgery Type</label>
                <input
                  id="surgery-type"
                  type="text"
                  required
                  value={type}
                  onChange={(e) => {
                    const input = e.target.value;
                    setType(input);
                  }}
                />
              </div>

              <div className="surgery-form-group">
                <label htmlFor="date-of-service">Date of Service</label>
                <input
                  id="date-of-service"
                  type="date"
                  required
                  value={DOS}
                  onChange={(e) => {
                    const input = e.target.value;
                    setDOS(input);
                  }}
                />
              </div>

              <div className="surgery-form-group">
                <label htmlFor="time-of-service">Time of Service</label>
                <input
                  id="time-of-service"
                  type="time"
                  required
                  value={TOS}
                  onChange={(e) => {
                    const input = e.target.value;
                    setTOS(input);
                  }}
                />
              </div>

              <button
                type="submit"
                className="submit-button"
                onClick={(e) => handleSubmit(e)}
              >
                Add Surgery
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSurgeryForm;
