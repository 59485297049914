import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getPatientHistory,
  updatePatientHistory,
} from "../../../utils/api";

const PatientHistoryandPhysical = () => {
  const { form_id } = useParams();
  const navigate = useNavigate();
  const [individualSignature, setIndividualSignature] = useState("");
  const [notPatientSignature, setNotPatientSignature] = useState("");
  const [loading, setLoading] = useState(true);
  const [respiratory, setRespiratory] = useState([]);
  const [cardio, setCardio] = useState([]);
  const [hepato, setHepato] = useState([]);
  const [renal, setRenal] = useState([]);
  const [neuro, setNeuro] = useState([]);
  const [other, setOther] = useState([]);

  const [formData, setFormData] = useState({
    referring_physician: "",
    time_1: "",
    allergies: "",
    respiratory: "",
    relation_to_patient: "",
    cardio: "",
    hepato: "",
    neuro: "",
    renal: "",
    other: "",
    drug_frequency: "",
    number_of_packs: "",
    alcohol_frequency: "",
    other_1_text: "",
    complications_text: "",
    skin: "",
    head_neck: "",
    heart: "",
    neuro_1: "",
    lungs: "",
    abdomen: "",
    extremities: "",
    assessment: "",
    no_change_text: "",
    date_2: "",
    date_1: "",
    age: "",
    sex: "",
    bp: "",
    pulse: "",
    temp: "",
    pain_level: "",
    chief_complaint: "",
    current_medications: "",
    reason_for_visit: "",
    previous_surgical: "",
    respiratory_no: "",
    hepato_no: "",
    renal_no: "",
    cardio_no: "",
    neuro_no: "",
    other_no: "",
    smoking_no: "",
    smoking_yes: "",
    alcohol_no: "",
    alcohol_yes: "",
    drug_no: "",
    drug_yes: "",
    hbp: "",
    heart_disease: "",
    stroke: "",
    lung_disease: "",
    diabetes: "",
    bleeding_disorder: "",
    cancer: "",
    other_1: "",
    complications_no: "",
    complications_yes: "",
    no_change: "",
  });

  const handleSymptomChange = (key, noneKey, setState) => (event) => {
    const { value, checked } = event.target;

    if (value === "none") {
      setState(() => []);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [key]: "",
        [noneKey]: checked,
      }));
    } else {
      setState((prevState) => {
        const updatedState = checked
          ? [...prevState, value]
          : prevState.filter((symptom) => symptom !== value);

        setFormData((prevFormData) => ({
          ...prevFormData,
          [key]: updatedState.join(", "),
          [noneKey]: false,
        }));

        return updatedState;
      });
    }
  };

  const handleRespiratoryChange = handleSymptomChange(
    "respiratory",
    "respiratory_no",
    setRespiratory
  );
  const handleCardioChange = handleSymptomChange(
    "cardio",
    "cardio_no",
    setCardio
  );
  const handleHepatoChange = handleSymptomChange(
    "hepato",
    "hepato_no",
    setHepato
  );
  const handleRenalChange = handleSymptomChange("renal", "renal_no", setRenal);
  const handleNeuroChange = handleSymptomChange("neuro", "neuro_no", setNeuro);
  const handleOtherChange = handleSymptomChange("other", "other_no", setOther);
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Form Data Before Submission:", formData);
    console.log("Not Patient Signature:", notPatientSignature);

    let patientFileName, formCompleterFileName;

    if (notPatientSignature !== "") {
      console.log("Uploading form completer signature:", notPatientSignature);
    }

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      patientFileName = data.fileName;
    }
    if (notPatientSignature !== "") {
      const response = await addFile(notPatientSignature);
      const data = await response.json();
      console.log("Uploaded form completer signature:", data.fileName);
      formCompleterFileName = data.fileName || null;
    }

    const payload = {
      patient_signature: patientFileName,
      form_completer_signature: formCompleterFileName,
      referring_physician: formData.referring_physician,
      time_1: moment().format("h:mm a"),
      allergies: formData.allergies,
      cardio: formData.cardio,
      hepato: formData.hepato,
      neuro: formData.neuro,
      renal: formData.renal,
      other: formData.other,
      respiratory: formData.respiratory,
      drug_frequency: formData.drug_frequency,
      number_of_packs: formData.number_of_packs,
      alcohol_frequency: formData.alcohol_frequency,
      other_1_text: formData.other_1_text,
      complications_text: formData.complications_text,
      relation_to_patient: formData.relation_to_patient,
      skin: formData.skin,
      head_neck: formData.head_neck,
      heart: formData.heart,
      neuro_1: formData.neuro_1,
      lungs: formData.lungs,
      abdomen: formData.abdomen,
      extremities: formData.extremities,
      assessment: formData.assessment,
      no_change_text: formData.no_change_text,
      date_2: moment().format("MM/DD/YYYY"),
      date_1: moment().format("MM/DD/YYYY"),
      age: formData.age,
      sex: formData.sex,
      bp: formData.bp,
      pulse: formData.pulse,
      temp: formData.temp,
      pain_level: formData.pain_level,
      chief_complaint: formData.chief_complaint,
      current_medications: formData.current_medications,
      reason_for_visit: formData.reason_for_visit,
      previous_surgical: formData.previous_surgical,
      respiratory_no: formData.respiratory_no,
      hepato_no: formData.hepato_no,
      renal_no: formData.renal_no,
      cardio_no: formData.cardio_no,
      neuro_no: formData.neuro_no,
      other_no: formData.other_no,
      smoking_no: formData.smoking_no,
      smoking_yes: formData.smoking_yes,
      alcohol_no: formData.alcohol_no,
      alcohol_yes: formData.alcohol_yes,
      drug_no: formData.drug_no,
      drug_yes: formData.drug_yes,
      hbp: formData.hbp,
      heart_disease: formData.heart_disease,
      stroke: formData.stroke,
      lung_disease: formData.lung_disease,
      diabetes: formData.diabetes,
      bleeding_disorder: formData.bleeding_disorder,
      cancer: formData.cancer,
      other_1: formData.other_1,
      complications_no: formData.complications_no,
      complications_yes: formData.complications_yes,
      no_change: formData.no_change,
      status: "In Progress",
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
    };

    console.log("Payload sent to backend:", payload);

    console.log(payload);

    // Fetch 2:
    updatePatientHistory(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPatientHistory(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setFormData(data);
        setCardio(cardio);
        setHepato(hepato);
        setNeuro(neuro);
        setOther(other);
        setRenal(renal);

        if (data.status !== "Not Started") {
        }

        setLoading("");
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <div className="form-content">
        <form onSubmit={(e) => handleSubmit(e)}>
          <h2 className="form-title">Patient History and Physical</h2>
          <div className="pi-container">
            <div className="pi-form-row">
              <label>Referring Physician:</label>
              <input
                type="text"
                className="pi-short-input"
                value={formData.referring_physician}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    referring_physician: e.target.value,
                  })
                }
              />
              <label>Date:</label>
              <input
                type="date"
                className="pi-short-input"
                value={formData.date_1}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    date_1: e.target.value,
                  })
                }
              />
              <label>Time:</label>
              <input
                type="time"
                className="pi-short-input"
                value={formData.time_1}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    time_1: e.target.value,
                  })
                }
              />
            </div>
            <div className="pi-form-row">
              <label>Patient’s Age:</label>
              <input
                type="text"
                className="pi-short-input"
                value={formData.age}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    age: e.target.value,
                  })
                }
              />
              <label>Sex:</label>
              <input
                type="text"
                className="pi-short-input"
                value={formData.sex}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    sex: e.target.value,
                  })
                }
              />
              <label>B.P.:</label>
              <input
                type="text"
                className="pi-short-input"
                value={formData.bp}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    bp: e.target.value,
                  })
                }
              />
              <label>Pulse:</label>
              <input
                type="text"
                className="pi-short-input"
                value={formData.pulse}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    pulse: e.target.value,
                  })
                }
              />
              <label>Temp:</label>
              <input
                type="text"
                className="pi-short-input"
                value={formData.temp}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    temp: e.target.value,
                  })
                }
              />
              <label>Pain Level:</label>
              <input
                type="text"
                className="pi-short-input"
                value={formData.pain_level}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    pain_level: e.target.value,
                  })
                }
              />
              <p>/10</p>
            </div>
            <div className="pi-form-row">
              <label>Chief Complaint:</label>
              <input
                type="text"
                className="pi-long-input"
                value={formData.chief_complaint}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    chief_complaint: e.target.value,
                  })
                }
              />
            </div>
            <div className="pi-form-row">
              <label>Current Medications:</label>
              <input
                type="text"
                className="pi-long-input"
                value={formData.current_medications}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    current_medications: e.target.value,
                  })
                }
              />
            </div>
            <div className="pi-form-row">
              <label>History of Present Illness/Reason for Visit:</label>
              <input
                type="text"
                className="pi-long-input"
                value={formData.reason_for_visit}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    reason_for_visit: e.target.value,
                  })
                }
              />
            </div>
            <div className="pi-form-row">
              <label>Previous Surgical History:</label>
              <input
                type="text"
                className="pi-long-input"
                value={formData.previous_surgical}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    previous_surgical: e.target.value,
                  })
                }
              />
            </div>
            <div className="pi-form-row">
              <label>Allergies:</label>
              <input
                type="text"
                className="pi-long-input"
                value={formData.allergies}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    allergies: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <p className="form-title">
            <strong>
              <i>
                Please indicate below if you have had any history of the
                conditions listed. Circle condition or checkbox if none.
              </i>
            </strong>
          </p>
          <div className="ph-grid-container">
            <div className="ph-grid-item">
              <label className="ph-label">Respiratory:</label>

              <input
                type="text"
                className="ph-long-input"
                value={respiratory.join(", ")}
                readOnly
              />
              <label className="ph-checkbox-label">
                <input
                  type="radio"
                  className="ph-checkbox"
                  value="none"
                  checked={formData.respiratory_no}
                  onChange={handleRespiratoryChange}
                />{" "}
                <strong>NONE</strong>
              </label>
            </div>

            <div className="ph-grid-item">
              <label className="ph-label">Cardiovascular:</label>
              <input
                type="text"
                className="ph-long-input"
                value={cardio.join(", ")}
              />
              <label className="ph-checkbox-label">
                <input
                  type="radio"
                  className="ph-checkbox"
                  value="none"
                  checked={formData.cardio_no}
                  onChange={handleCardioChange}
                />{" "}
                <strong>NONE</strong>
              </label>
            </div>
            <div className="ph-grid-item">
              {" "}
              <div>
                {[
                  "Asthma",
                  "Bronchitis",
                  "COPD",
                  "Dyspnea",
                  "Orthopnea",
                  "Pneumonia",
                  "Cough",
                  "SOB",
                  "Recent URI",
                  "Tuberculosis",
                ].map((symptom) => (
                  <label key={symptom}>
                    <input
                      type="checkbox"
                      className="ph-checkbox"
                      value={symptom}
                      onChange={handleRespiratoryChange}
                    />{" "}
                    {symptom}
                  </label>
                ))}
              </div>
            </div>
            <div className="ph-grid-item">
              <div>
                {[
                  "Abnormal EKG",
                  "Angina",
                  "ASHD",
                  "CHF",
                  "Exercise Intolerance",
                  "Productive Hypertension",
                  "MI",
                  "Murmur",
                  "Pacemaker",
                  "Valvular Disease",
                  "Rheumatic Fever",
                ].map((symptom) => (
                  <label key={symptom}>
                    <input
                      type="checkbox"
                      className="ph-checkbox"
                      value={symptom}
                      onChange={handleCardioChange}
                    />{" "}
                    {symptom}
                  </label>
                ))}
              </div>
            </div>
            <div className="ph-grid-item">
              <label className="ph-label">Hepato/Gastrointestinal:</label>
              <input
                type="text"
                className="ph-long-input"
                value={hepato.join(", ")}
              />
              <label className="ph-checkbox-label">
                <input
                  type="radio"
                  className="ph-checkbox"
                  value="none"
                  onChange={handleHepatoChange}
                  checked={formData.hepato_no}
                />{" "}
                <strong>NONE</strong>
              </label>
            </div>
            <div className="ph-grid-item">
              <label className="ph-label">Neuro/Musculoskeletal:</label>
              <input
                type="text"
                className="ph-long-input"
                value={neuro.join(", ")}
              />
              <label className="ph-checkbox-label">
                <input
                  type="radio"
                  className="ph-checkbox"
                  value="none"
                  onChange={handleNeuroChange}
                  checked={formData.neuro_no}
                />{" "}
                <strong>NONE</strong>
              </label>
            </div>
            <div className="ph-grid-item">
              <div>
                {[
                  "Bowel Obstruction",
                  "Cirrhosis",
                  "Jaundice/Hepatitis",
                  "Hiatal",
                  "Nausea/Vomiting",
                ].map((symptom) => (
                  <label key={symptom}>
                    <input
                      type="checkbox"
                      className="ph-checkbox"
                      value={symptom}
                      onChange={handleHepatoChange}
                    />{" "}
                    {symptom}
                  </label>
                ))}
              </div>
            </div>
            <div className="ph-grid-item">
              <div>
                {[
                  "Arthritis",
                  "Back problems",
                  "CVA/Stroke/TIA",
                  "Headaches",
                  "Ulcers Hernia/Reflux",
                  "Muscle Weakness",
                  "Syncope",
                  "Seizures",
                  "Paralysis",
                  "Paresthesia",
                ].map((symptom) => (
                  <label key={symptom}>
                    <input
                      type="checkbox"
                      className="ph-checkbox"
                      value={symptom}
                      onChange={handleNeuroChange}
                    />{" "}
                    {symptom}
                  </label>
                ))}
              </div>
            </div>
            <div className="ph-grid-item">
              <label className="ph-label">Renal/Endocrine:</label>
              <input
                type="text"
                className="ph-long-input"
                value={renal.join(", ")}
              />
              <label className="ph-checkbox-label">
                <input
                  type="radio"
                  className="ph-checkbox"
                  value="none"
                  onChange={handleRenalChange}
                  checked={formData.renal_no}
                />{" "}
                <strong>NONE</strong>
              </label>
            </div>
            <div className="ph-grid-item">
              <label className="ph-label">Other:</label>
              <input
                type="text"
                className="ph-long-input"
                value={other.join(", ")}
              />
              <label className="ph-checkbox-label">
                <input
                  type="radio"
                  className="ph-checkbox"
                  value="none"
                  onChange={handleOtherChange}
                  checked={formData.other_no}
                />{" "}
                <strong>NONE</strong>
              </label>
            </div>
            <div className="ph-grid-item">
              <div>
                {[
                  "Diabetes",
                  "Renal failure",
                  "Thyroid disease",
                  "Urinary retention",
                  "Significant Weight Loss/Gain",
                ].map((symptom) => (
                  <label key={symptom}>
                    <input
                      type="checkbox"
                      className="ph-checkbox"
                      value={symptom}
                      onChange={handleRenalChange}
                    />{" "}
                    {symptom}
                  </label>
                ))}
              </div>
            </div>
            <div className="ph-grid-item">
              <div>
                {[
                  "Anemia",
                  "Bleeding tendencies",
                  "Cancer",
                  "Chemotherapy",
                  "Immunosuppressed",
                  "Pregnancy",
                  "Recent steroid use",
                  "Transfusion history",
                  "Sickle Cell",
                  "Urinary Tract Infection",
                ].map((symptom) => (
                  <label key={symptom}>
                    <input
                      type="checkbox"
                      className="ph-checkbox"
                      value={symptom}
                      onChange={handleOtherChange}
                    />{" "}
                    {symptom}
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div className="sa-form">
            <div className="sa-row">
              <p>
                <strong>Smoking:</strong>
              </p>
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.smoking_no}
                checked={formData.smoking_no}
                onChange={() =>
                  setFormData({
                    ...formData,
                    smoking_no: true,
                    smoking_yes: false,
                  })
                }
              />{" "}
              N
              <input
                type="radio"
                className="sa-checkbox "
                value={formData.smoking_yes}
                checked={formData.smoking_yes}
                onChange={() =>
                  setFormData({
                    ...formData,
                    smoking_yes: true,
                    smoking_no: false,
                  })
                }
              />{" "}
              Y
              <p className="sa-text">
                <strong># packs per day/week</strong>
              </p>
              <input
                type="text"
                className="sa-input-short"
                value={formData.number_of_packs}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    number_of_packs: e.target.value,
                  })
                }
              />
              <p className="sa-text">
                <strong>Alcohol Use:</strong>
              </p>
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.alcohol_no}
                checked={formData.alcohol_no}
                onChange={() =>
                  setFormData({
                    ...formData,
                    alcohol_no: true,
                    alcohol_yes: false,
                  })
                }
              />{" "}
              N
              <input
                type="radio"
                className="sa-checkbox "
                value={formData.alcohol_yes}
                checked={formData.alcohol_yes}
                onChange={() =>
                  setFormData({
                    ...formData,
                    alcohol_yes: true,
                    alcohol_no: false,
                  })
                }
              />{" "}
              Y
              <p className="sa-text">
                <strong>Frequency:</strong>
              </p>
              <input
                type="text"
                className="sa-input-short"
                value={formData.alcohol_frequency}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    alcohol_frequency: e.target.value,
                  })
                }
              />
            </div>
            <div className="sa-row">
              <p className="sa-text">
                <strong>Drug Use:</strong>
              </p>
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.drug_no}
                checked={formData.drug_no}
                onChange={() =>
                  setFormData({
                    ...formData,
                    drug_no: true,
                    drug_yes: false,
                  })
                }
              />{" "}
              N
              <input
                type="radio"
                className="sa-checkbox "
                value={formData.drug_yes}
                checked={formData.drug_yes}
                onChange={() =>
                  setFormData({
                    ...formData,
                    drug_yes: true,
                    drug_no: false,
                  })
                }
              />{" "}
              Y
              <p className="sa-text">
                <strong>Freq:</strong>
              </p>
              <input
                type="text"
                className="sa-input-short"
                value={formData.drug_frequency}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    drug_frequency: e.target.value,
                  })
                }
              />
            </div>
            <div className="sa-row">
              <p>
                Is there any history of the following medical conditions in your{" "}
                <u>family</u>?
              </p>
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.hbp}
                checked={formData.hbp}
                onChange={() =>
                  setFormData({
                    ...formData,
                    hbp: true,
                  })
                }
              />{" "}
              High Blood Pressure
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.heart_disease}
                checked={formData.heart_disease}
                onChange={() =>
                  setFormData({
                    ...formData,
                    heart_disease: true,
                  })
                }
              />{" "}
              Heart Disease
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.stroke}
                checked={formData.stroke}
                onChange={() =>
                  setFormData({
                    ...formData,
                    stroke: true,
                  })
                }
              />{" "}
              Stroke
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.lung_disease}
                checked={formData.lung_disease}
                onChange={() =>
                  setFormData({
                    ...formData,
                    lung_disease: true,
                  })
                }
              />{" "}
              Lung Disease
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.diabetes}
                checked={formData.diabetes}
                onChange={() =>
                  setFormData({
                    ...formData,
                    diabetes: true,
                  })
                }
              />{" "}
              Diabetes
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.bleeding_disorder}
                checked={formData.bleeding_disorder}
                onChange={() =>
                  setFormData({
                    ...formData,
                    bleeding_disorder: true,
                  })
                }
              />{" "}
              Bleeding Disorder
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.cancer}
                checked={formData.cancer}
                onChange={() =>
                  setFormData({
                    ...formData,
                    cancer: true,
                  })
                }
              />{" "}
              Cancer
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.other_1}
                checked={formData.other_1}
                onChange={() =>
                  setFormData({
                    ...formData,
                    other_1: true,
                  })
                }
              />{" "}
              <p className="sa-text">Other:</p>
              <input
                type="text"
                className="sa-input-long"
                value={formData.other_1_text}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    other_1_text: e.target.value,
                  })
                }
              />
            </div>
            <div className="sa-row">
              <p>
                Previous surgical/anesthesia complications (patient or family)
              </p>
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.complications_no}
                checked={formData.complications_no}
                onChange={() =>
                  setFormData({
                    ...formData,
                    complications_no: true,
                    complications_yes: false,
                  })
                }
              />{" "}
              No
              <input
                type="radio"
                className="sa-checkbox"
                value={formData.complications_yes}
                checked={formData.complications_yes}
                onChange={() =>
                  setFormData({
                    ...formData,
                    complications_yes: true,
                    complications_no: false,
                  })
                }
              />{" "}
              Yes
              <p className="sa-text">Describe:</p>
              <input
                type="text"
                className="sa-input-long"
                value={formData.complications_text}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    complications_text: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="ph-container mt-50 sig-mobile">
            <div className="gc-item">
              {" "}
              <SignatureButton
                individualSignature={individualSignature}
                setIndividualSignature={setIndividualSignature}
              />
              <hr className="signature-line"></hr>
              <p>Patient Signature</p>
            </div>
            <div className="gc-item">
              {" "}
              <label htmlFor="name" className="hidden">
                Patient Signature
              </label>
              <SignatureButton
                individualSignature={notPatientSignature}
                setIndividualSignature={(file) => {
                  console.log("Captured signature:", file);
                  setNotPatientSignature(file);
                }}
              />
              <hr className="signature-line"></hr>
              <p>Signature of Person Completing Form if Not Patient</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Relationship to Patient
              </label>
              <input
                type="text"
                id="time"
                name="time"
                className="bottom-border-input"
                value={formData.relation_to_patient}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    relation_to_patient: e.target.value,
                  })
                }
              />
              <p> Relationship to Patient</p>
            </div>
          </div>
          <div className="hp-form">
            <p>
              The Goals, Methods and Alternatives (with risks) of this operation
              were discussed. Also, Lack of Guarantee of Results, Possible Need
              of Additional Treatment and Risks and Complications. Patient
              understands and accepts.
            </p>
            <div className="hp-row">
              <p>Skin</p>
              <input
                type="text"
                id="skin"
                name="skin"
                className="hp-bottom-border-input"
                value={formData.skin}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    skin: e.target.value,
                  })
                }
              />
            </div>
            <div className="hp-row">
              <p>Head & Neck</p>
              <input
                type="text"
                id="head-neck"
                name="head-neck"
                className="hp-bottom-border-input"
                value={formData.head_neck}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    head_neck: e.target.value,
                  })
                }
              />
            </div>
            <div className="hp-row">
              <p>
                <strong>Heart</strong>
              </p>
              <input
                type="text"
                id="heart"
                name="heart"
                className="hp-bottom-border-input"
                value={formData.heart}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    heart: e.target.value,
                  })
                }
              />
            </div>
            <div className="hp-row">
              <p>Neurological</p>
              <input
                type="text"
                id="neurological"
                name="neurological"
                className="hp-bottom-border-input"
                value={formData.neuro_1}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    neuro_1: e.target.value,
                  })
                }
              />
            </div>
            <div className="hp-row">
              <p>
                <strong>Lungs</strong>
              </p>
              <input
                type="text"
                id="lungs"
                name="lungs"
                className="hp-bottom-border-input"
                value={formData.lungs}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    lungs: e.target.value,
                  })
                }
              />
            </div>
            <div className="hp-row">
              <p>Abdomen</p>
              <input
                type="text"
                id="abdomen"
                name="abdomen"
                className="hp-bottom-border-input"
                value={formData.abdomen}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    abdomen: e.target.value,
                  })
                }
              />
            </div>
            <div className="hp-row">
              <p>Extremities</p>
              <input
                type="text"
                id="extremities"
                name="extremities"
                className="hp-bottom-border-input"
                value={formData.extremities}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    extremities: e.target.value,
                  })
                }
              />
            </div>
            <div className="hp-row">
              <p>
                <strong>Assessment and Plan</strong>
              </p>
              <input
                type="text"
                id="assessment-plan"
                name="assessment-plan"
                className="hp-bottom-border-input"
                value={formData.assessment}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    assessment: e.target.value,
                  })
                }
              />
            </div>
            <div className="hp-checkbox-row">
              <input
                type="radio"
                className="hp-checkbox"
                value={formData.no_change}
                checked={formData.no_change}
                onChange={() =>
                  setFormData({
                    ...formData,
                    no_change: true,
                  })
                }
              />
              <strong>
                <label>No change from prior H&amp;P made on</label>
              </strong>
              <input
                type="text"
                id="prior-hp"
                name="prior-hp"
                className="hp-bottom-border-input"
                value={formData.no_change_text}
                checked={formData.no_change_text}
                onChange={() =>
                  setFormData({
                    ...formData,
                    no_change_text: true,
                  })
                }
              />
            </div>
            {/* <div className="privacy-container mt-50 sig-mobile">
              <div className="privacy-item">
                {" "}
                <SignatureButton
                  individualSignature={individualSignature}
                  setIndividualSignature={setIndividualSignature}
                />
                <hr className="signature-line"></hr>
                <p>Signature of Physician</p>
              </div>
              <div className="privacy-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Signature of Physician
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  
                />
                <p>Date</p>
              </div> */}
            {/* </div> */}
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PatientHistoryandPhysical;
