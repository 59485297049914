import React, { useState } from "react";
import { createCondition, updateCondition } from "../../../utils/api";
const moment = require("moment");

// Component for questions on Patient Info Page
const ConditionQuestion = ({ condition, ariaId }) => {
  // console.log(condition);
  const [selectedOption, setSelectedOption] = useState(
    condition.value || false
  );
  const [additionalInfo, setAdditionalInfo] = useState(
    condition.condition_obj.condition_note || ""
  );
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);

    e.target.value !== condition.value.toString()
      ? setCanUpdate(true)
      : setCanUpdate(false);
  };

  const handleInputChange = (e) => {
    setAdditionalInfo(e.target.value);

    e.target.value !== condition.condition_obj.condition_note
      ? setCanUpdate(true)
      : setCanUpdate(false);
  };

  const addEntry = () => {
    if (selectedOption.toString() === "false") {
      // Delete the record
      const condition_id = condition.condition_obj.id;
      const payload = {
        date_removed: moment().format("MM/DD/YYYY"),
        isDeleted: true,
      };

      updateCondition(condition_id, payload)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setCanUpdate(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    } else if (
      condition.condition_obj.id !== null &&
      selectedOption.toString() === "true" &&
      additionalInfo !== condition.condition_obj.condition_note
    ) {
      // Update the record text
      const condition_id = condition.condition_obj.id;
      const payload = {
        condition_note: additionalInfo,
      };

      updateCondition(condition_id, payload)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setCanUpdate(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    } else {
      // Create a new Condition record
      const payload = {
        med_history_id: JSON.parse(localStorage.getItem("med_history_id")),
        condition: condition.condition_obj.condition,
        condition_note: additionalInfo || "",
        category: condition.condition_obj.category,
        date_created: moment().format("MM/DD/YYYY"),
      };

      createCondition(payload)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setCanUpdate(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  return (
    <div className="condition-field">
      <label htmlFor={ariaId}>{condition.label}</label>
      <select value={selectedOption} onChange={handleSelectChange} id={ariaId}>
        <option value={false}>No</option>
        <option value={true}>Yes</option>
      </select>

      {selectedOption.toString() === "true" && (
        <input
          type="text"
          className="full-width-input"
          placeholder="Please specify"
          value={additionalInfo}
          onChange={handleInputChange}
        />
      )}

      {canUpdate && (
        <button className="med-history-add-btn" onClick={() => addEntry()}>
          Update
        </button>
      )}

      {errMessage && (
        <p className="err-message">
          Something has gone wrong with your request.
        </p>
      )}
    </div>
  );
};

const MedHistoryQuestions = ({ medHistory }) => {
  //console.log(medHistory);
  if (medHistory === undefined) {
    return <div>Loading...</div>;
  }

  const conditions = medHistory.conditions || [];

  const checkCondition = (condition) => {
    const conditionExists = conditions.find(
      (item) => item.condition === condition
    );

    if (conditionExists) {
      // console.log("Found:", conditionExists);
      return true;
    } else {
      // console.log("Not found");
      return false;
    }
  };

  const getCondition = (condition) => {
    const conditionExists = conditions.find(
      (item) => item.condition === condition
    );

    if (conditionExists) {
      return conditionExists;
    }
  };

  // Patient Info Questions
  const patientInfo = [
    {
      id: 1,
      label: "Neurological Problems",
      value: checkCondition("Neurological Problems"),
      condition_obj: getCondition("Neurological Problems") || {
        id: null,
        condition: "Neurological Problems",
        condition_note: "",
        category: "Neuro",
      },
    },
    {
      id: 2,
      label: "Diabetes",
      value: checkCondition("Diabetes"),
      condition_obj: getCondition("Diabetes") || {
        id: null,
        condition: "Diabetes",
        condition_note: "",
        category: "Renal",
      },
    },
    {
      id: 3,
      label: "Hypertension",
      value: checkCondition("Hypertension"),
      condition_obj: getCondition("Hypertension") || {
        id: null,
        condition: "Hypertension",
        condition_note: "",
        category: "Cardiovascular",
      },
    },
    {
      id: 4,
      label: "Bleeding Problems",
      condition_obj: getCondition("Bleeding Problems") || {
        id: null,
        condition: "Bleeding Problems",
        condition_note: "",
        category: "Other",
      },
    },
    {
      id: 5,
      label: "Hepatitis",
      value: checkCondition("Yellow Jaundice or Hepatitis"),
      condition_obj: getCondition("Yellow Jaundice or Hepatitis") || {
        id: null,
        condition: "Yellow Jaundice or Hepatitis",
        condition_note: "",
        category: "Hepato",
      },
    },
    {
      id: 6,
      label: "Asthma",
      value: checkCondition(
        "Asthma, Emphysema, Bronchitis, or Breathing problems"
      ),
      condition_obj: getCondition(
        "Asthma, Emphysema, Bronchitis, or Breathing problems"
      ) || {
        id: null,
        condition: "Asthma, Emphysema, Bronchitis, or Breathing problems",
        condition_note: "",
        category: "Respiratory",
      },
    },
    {
      id: 7,
      label: "Fainting/Dizziness",
      value: checkCondition("Fainting/Diziness"),
      condition_obj: getCondition("Fainting/Diziness") || {
        id: null,
        condition: "Fainting/Diziness",
        condition_note: "",
        category: "Other",
      },
    },
    {
      id: 8,
      label: "Smoking",
      value: checkCondition("Smoke"),
      condition_obj: getCondition("Smoke") || {
        id: null,
        condition: "Smoke",
        condition_note: "",
        category: null,
      },
    },
    {
      id: 9,
      label: "Heart Disease",
      value: checkCondition("Heart Disease"),
      condition_obj: getCondition("Heart Disease") || {
        id: null,
        condition: "Heart Disease",
        condition_note: "",
        category: "Cardiovascular",
      },
    },
  ];

  return (
    <>
      {patientInfo.map((condition, index) => (
        <ConditionQuestion
          ariaId={`patient-info-condition-${index}`}
          key={condition.id}
          condition={condition}
        />
      ))}
    </>
  );
};

export default MedHistoryQuestions;
