import React from "react";
import { Icon } from "@iconify/react";
import { getFormLink } from "./helpers";

const FormBox = ({ form, index }) => {
  if (!form || !form.formName) {
    console.error("Invalid form data: ", form);
    return null; 
  }
  return (
    <a
      className="form-box"
      key={index}
      href={`${getFormLink(form.formName)}/${form.id}`}
    >
      <span className="form-box-label">{form.formName}</span>
      <div className="form-box-status-container">
        <span className="form-box-status">{form.status}</span>
        <span className="form-box-icon">
          <Icon
            icon="heroicons-outline:exclamation"
            className="patient-view-icon"
          />
        </span>
      </div>
    </a>
  );
};

export default FormBox;
