import React from "react";
import { drawingLogic } from "./logic";
import { Icon } from "@iconify/react";
import "./drawing.css";

const DrawingPad = ({ setIsOpen, setIndividualSignature }) => {
  drawingLogic();

  const submit = () => {
    const canvas = document.getElementById("drawing-board");
    // // Get the Signature
    // const imgData = canvas.toDataURL("image/png", 1.0);

    // // Format File for fetch request
    // const formData = new FormData();
    // formData.append("file", imgData);
    // setIndividualSignature(formData);

    canvas.toBlob(function (blob) {
      const formData = new FormData();
      formData.append("file", blob, "patient-signature.png");

      // Post via axios or other transport method
      setIndividualSignature(formData);
    });

    // Clear the canvas
    canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
    setIsOpen(false);
  };

  return (
    <div className="drawing-modal" id="drawing-pad">
      <div className="drawing-modal-container">
        <header className="drawing-header">
          <button onClick={() => setIsOpen(false)}>
            <Icon icon="material-symbols:close" />
          </button>
          <p>Signature</p>
        </header>

        <section className="drawing-container">
          <div className="drawing-board">
            <canvas id="drawing-board"></canvas>
          </div>
        </section>

        <div id="toolbar">
          <button id="clear">Clear</button>
          {/* <button id="download">Download</button> */}
          <button onClick={() => submit()}>Adopt</button>
        </div>
      </div>
    </div>
  );
};

export default DrawingPad;
