import React, { useState, useEffect } from "react";
import "./patientsearch.css";
import { Icon } from "@iconify/react";
import profileImg from "../../../assets/images/guest.webp";
import BackgroundImage from "../../../components/BackgroundImage";
import { Link } from "react-router-dom";
import {
  getRecentPatients,
  getRecentSurgeries,
  searchPatients,
  getUsersbyDOS,
  getPatientsbyDOB,
} from "../../../utils/api";
import moment from "moment";

// TODO: What does the useEffect on line 25 do? There's already one that runs on page load, is it a duplicate?
// TODO: A patient should populate/be filtered based on ALL of their surgeries. The first one wont necesarrily be the closest to today’s date(???)

const PatientSearch = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState("Name");
  const [userInput, setUserInput] = useState("");

  // Auto-populate patients with today's DOS
  useEffect(() => {
    // Format for today's date
    const today = moment().format("MM/DD/YYYY");

    setLoading(true);
    getUsersbyDOS(today)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.length > 0) {
          // Sort patients by the first surgery's DOS ASC
          const sortedUsers = data.sort((a, b) => {
            const aDOS = new Date(a.surgeries[0]?.DOS);
            const bDOS = new Date(b.surgeries[0]?.DOS);
            return aDOS - bDOS;
          });
          setUsers(sortedUsers);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching patients by DOS:", err);
        setLoading(false);
      });
  }, []);

  /** RUNS WHEN THE USER SUBMITS A SEARCH */
  const handleSearch = (e) => {
    e.preventDefault();

    if (userInput.trim() === "") {
      setUsers([]);
      return;
    }

    setLoading(true);
    setUsers([]);
    console.log(searchType);

    if (searchType === "DOS") {
      /** FETCH USERS BY DATE OF SERVICE */
      getUsersbyDOS(userInput.trim())
        .then((res) => res.json())
        .then((data) => {
          // Sort users by the first surgery's DOS in ascending order
          const sortedUsers = data.sort((a, b) => {
            const aDOS = new Date(a.surgeries[0]?.DOS);
            const bDOS = new Date(b.surgeries[0]?.DOS);
            return aDOS - bDOS;
          });
          setUsers(sortedUsers);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching patients:", err);
          setLoading(false);
        });
    } else if (searchType === "DOB") {
      /** FETCH USERS BY DATE OF BIRTH */
      getPatientsbyDOB(userInput.trim())
        .then((res) => res.json())
        .then((data) => {
          setUsers(data);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching patients:", err);
          setLoading(false);
        });
    } else {
      /** FETCH USERS BY OTHER SEARCH TYPES */
      searchPatients(searchType, userInput.trim())
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.message) {
            if (
              data.message ===
              "No patients found matching the provided criteria."
            ) {
              console.log("here");
              setUsers([]);
              setLoading(false);
            }
          }
          setUsers(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  /** RUN ONCE ON PAGE LOAD */
  useEffect(() => {
    // Fetch recent patients
    getRecentPatients()
      .then((res) => res.json())
      .then(async (data) => {
        // Fetch recent surgeries
        const usersWithSurgeries = await Promise.all(
          data.map(async (user) => {
            const surgeriesRes = await getRecentSurgeries(user.id);
            const surgeriesData = await surgeriesRes.json();
            // Filter surgeries to match the current user
            const filteredSurgeries = surgeriesData.filter(
              (surgery) => surgery.patient_id === user.id
            );
            return { ...user, surgeries: filteredSurgeries };
          })
        );

        // Sort users by the first surgery's DOS in ascending order
        const sortedUsers = usersWithSurgeries.sort((a, b) => {
          const aDOS = new Date(a.surgeries[0]?.DOS);
          const bDOS = new Date(b.surgeries[0]?.DOS);
          return aDOS - bDOS;
        });

        setUsers(sortedUsers);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <div className="create-grid-container">
      <div className="first-column"></div>
      <div className="second-column">
        <BackgroundImage />
        <h1>Patient Search</h1>
        <div className="patient-search-input-group">
          <label htmlFor="select"></label>
          <select
            id="select"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="Name">Name</option>
            <option value="DOB">DOB</option>
            <option value="MRN">MRN</option>
            <option value="DOS">DOS</option>
          </select>
          <label htmlFor="patient-search"></label>
          <input
            id="patient-search"
            type="text"
            className="patient-search-input"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
          />
          <button
            className="patient-search-btn"
            onClick={(e) => handleSearch(e)}
          >
            {isLoading ? "Searching..." : "Search"}
            <Icon icon="ic:round-search" className="patient-search-icon" />
          </button>
        </div>
        <div className="patient-search-card-container">
          {users.length > 0
            ? users.map((user) => <UserCard user={user} key={user.id} />)
            : !isLoading && <p>No Patients Found</p>}
        </div>
      </div>
    </div>
  );
};

/** USER CARD COMPONENT */
const UserCard = ({ user }) => {
  const displayPhoto = (image) => {
    if (!image) {
      return profileImg;
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `/uploads/${image}`;
    }
  };
  const latestSurgery = user.surgeries?.[0];

  return (
    <Link to={`/patient/${user.id}`}>
      <div key={user.id} className="patient-search-card">
        <div className="search-profile-image">
          <img src={displayPhoto(user.image)} alt="user profile" />
          <div>
            <h2 className="patient-search-name">
              {user.first_name} {user.last_name}
            </h2>
            <div className="mrn">
              <p>
                <span className="mrn-dob">MRN: </span>
                <span className="mrn-value">{user.medical_record_number}</span>
              </p>
              <p>
                <span className="mrn-dob">DOB: </span>
                <span className="mrn-value">{user.birthday}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="patient-search-divider"></div>
        <div className="patient-search-proc-dos">
          <div className="procedure">
            Procedure: {latestSurgery ? latestSurgery.type : "N/A"}
          </div>
          <div className="dos">
            DOS: {latestSurgery ? latestSurgery.DOS : "N/A"} &nbsp;
            {latestSurgery ? latestSurgery.TOS : ""}
          </div>
          {/* Display when Documents are missing signatures */}
          <button className="missing-sig-btn hidden">Missing Signatures</button>
        </div>
      </div>
    </Link>
  );
};
export default PatientSearch;
