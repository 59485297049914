import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Auth from "./utils/auth";

import Login from "./components/Login";
import Signup from "./components/Signup";
import Nav from "./components/Nav";
import PatientNav from "./components/Nav/PatientNav";
import SignupSuccess from "./pages/User/SignupSuccess";

// User Pages
import DashboardNav from "./pages/User/DashboardNav";
import Profile from "./pages/User/Profile";
import NewPatientForm from "./pages/User/NewPatientForm";
import PatientSearch from "./pages/User/PatientSearch";
import NewSurgeryForm from "./pages/User/NewSurgeryForm";
import PatientFolder from "./pages/User/PatientFolder";
import PatientInfo from "./pages/User/PatientInfo";
import NursingQuestionnaire from "./pages/User/NursingQuestionnaire";
import SurgeryView from "./pages/User/SurgeryView";
import PatientForms from "./pages/User/PatientForms";
import ThankYou from "./pages/User/ThankYou";
import PinModal from "./pages/User/PinModal";
// Admin Pages
import AdminSideNav from "./pages/Admin/AdminSideNav";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminUserSearch from "./pages/Admin/AdminUserSearch";
import AdminApproval from "./pages/Admin/AdminApproval";
import AdminCreate from "./pages/Admin/AdminCreate";
//Doctor Pages
import DrDashboard from "./pages/Doctor/Dashboard";
import DrSideNav from "./pages/Doctor/SideNav";
import PendingPaperwork from "./pages/Doctor/PendingPaperWork";
// Admin Pages
import AdminEditUser from "./pages/Admin/AdminEditUser";
import AdminViewUser from "./pages/Admin/AdminViewUser";
//Forgot Password Pages
import ForgotPassword from "./components/ForgotPassword";
import OTPValidation from "./components/OTP";
//Forms Folder (Patient View)
import PatientAnethesiaQuestionnaire from "./pages/User/Forms/AnethesiaQuestionnaire";
import PatientAssignmentofBenefits from "./pages/User/Forms/AssignmentofBenefits";
import PatientDischargeInstructions from "./pages/User/Forms/DischargeInstructions";
import PatientDisclosureofOwnership from "./pages/User/Forms/DisclosureofOwnership";
import PatientGrievance from "./pages/User/Forms/Grievance";
import PatientNoticeofPrivacy from "./pages/User/Forms/NoticeofPrivacy";
import PatientPatientConsentAnethesia from "./pages/User/Forms/PatientConsentAnethesia";
import PatientPatientConsentProcedure from "./pages/User/Forms/PatientConsentProcedure";
import PatientPatientHistoryandPhysical from "./pages/User/Forms/PatientHistoryandPhysical";
import PatientPatientRegistration from "./pages/User/Forms/PatientRegistration";
import PatientPatientSatisfaction from "./pages/User/Forms/PatientSatisfaction";
//Forms (Logged in) Folder
import AnethesiaQuestionnaire from "./pages/User/FormsLoggedIn/AnethesiaQuestionnaire";
import AssignmentofBenefits from "./pages/User/FormsLoggedIn/AssignmentofBenefits";
import DischargeInstructions from "./pages/User/FormsLoggedIn/DischargeInstructions";
import DisclosureofOwnership from "./pages/User/FormsLoggedIn/DisclosureofOwnership";
import Grievance from "./pages/User/FormsLoggedIn/Grievance";
import NoticeofPrivacy from "./pages/User/FormsLoggedIn/NoticeofPrivacy";
import PatientConsentAnethesia from "./pages/User/FormsLoggedIn/PatientConsentAnethesia";
import PatientConsentProcedure from "./pages/User/FormsLoggedIn/PatientConsentProcedure";
import PatientHistoryandPhysical from "./pages/User/FormsLoggedIn/PatientHistoryandPhysical";
import PatientRegistration from "./pages/User/FormsLoggedIn/PatientRegistration";
import PatientSatisfaction from "./pages/User/FormsLoggedIn/PatientSatisfaction";

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

const App = () => {
  let user;
  const location = useLocation();

  if (Auth.loggedIn() !== false) {
    user = Auth.getUser();
    // console.log(user);
  }

  // Allow users to access routes IF logged in
  function checkUser() {
    if (user !== undefined) {
      // Conditionally routes based on the user_type
      switch (user.role) {
        case "Doctor":
          //console.log("doctor");
          return doctorRoutes;
        case "Admin":
          //console.log("admin");
          return adminRoutes;
        default:
          //console.log("null");
          return null;
      }
    }
  }

  const adminRoutes = (
    <>
      {/* Admin */}
      <Route path="/admin-nav" element={<AdminSideNav />} />
      <Route path="/admin-dashboard" element={<AdminDashboard />} />
      <Route path="/admin-user-search" element={<AdminUserSearch />} />
      <Route path="/admin-approval" element={<AdminApproval />} />
      <Route path="/admin-create" element={<AdminCreate />} />
      <Route path="/admin-edit-user/:id" element={<AdminEditUser />} />
      <Route path="/admin-view-user/:id" element={<AdminViewUser />} />
    </>
  );

  const doctorRoutes = (
    <>
      {/* Doctor */}
      <Route path="/dr-dashboard" element={<DrDashboard />} />
      <Route path="/dr-sidenav" element={<DrSideNav />} />
      <Route path="/pending-paperwork" element={<PendingPaperwork />} />
    </>
  );

  const isLoggedIn = () => {
    if (user !== undefined) {
      return (
        <>
          {/* Logged In Routes (For all User types) */}
          <Route path="/dashboard" element={<DashboardNav />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/new-patient-form" element={<NewPatientForm />} />
          <Route path="/patient-search" element={<PatientSearch />} />
          <Route path="/new-surgery-form/:id" element={<NewSurgeryForm />} />
          <Route path="/patient/:id" element={<PatientFolder />} />
          <Route path="/patient-information/:id" element={<PatientInfo />} />
          <Route
            path="/surgery-view/:id/surgery/:surgery_id"
            element={<SurgeryView />}
          />
          <Route
            path="patient/:id/nursing-questionnaire/:surgeryInfo_id"
            element={<NursingQuestionnaire />}
          />
          <Route
            path="patient/:id/patient-forms/:surgery_id"
            element={<PatientForms />}
          />
          <Route
            path="patient/:id/patient-forms/:surgery_id/thank-you"
            element={<ThankYou />}
          />

          <Route
            path="patient/:id/patient-forms/:surgery_id/pin-modal"
            element={<PinModal />}
          />
          {/* Forms */}
          <Route
            path="/patient-form-anethesia-ques/:form_id"
            element={<PatientAnethesiaQuestionnaire />}
          />
          <Route
            path="/patient-form-assignment-benefits/:form_id"
            element={<PatientAssignmentofBenefits />}
          />
          <Route
            path="/patient-form-discharge-instructions/:form_id"
            element={<PatientDischargeInstructions />}
          />
          <Route
            path="/patient-form-disclosure-ownership/:form_id"
            element={<PatientDisclosureofOwnership />}
          />
          <Route
            path="/patient-form-grievance/:form_id"
            element={<PatientGrievance />}
          />
          <Route
            path="/patient-form-privacy/:form_id"
            element={<PatientNoticeofPrivacy />}
          />
          <Route
            path="/patient-form-consent-anethesia/:form_id"
            element={<PatientPatientConsentAnethesia />}
          />
          <Route
            path="/patient-form-procedure-consent/:form_id"
            element={<PatientPatientConsentProcedure />}
          />
          <Route
            path="/patient-form-patient-history/:form_id"
            element={<PatientPatientHistoryandPhysical />}
          />
          <Route
            path="/patient-form-registration/:form_id"
            element={<PatientPatientRegistration />}
          />
          <Route
            path="/patient-form-satisfaction/:form_id"
            element={<PatientPatientSatisfaction />}
          />

          {/* Logged in Forms */}
          <Route
            path="/form-anethesia-ques/:form_id"
            element={<AnethesiaQuestionnaire />}
          />
          <Route
            path="/form-assignment-benefits/:form_id"
            element={<AssignmentofBenefits />}
          />
          <Route
            path="/form-discharge-instructions/:form_id"
            element={<DischargeInstructions />}
          />
          <Route
            path="/form-disclosure-ownership/:form_id"
            element={<DisclosureofOwnership />}
          />
          <Route path="/form-grievance/:form_id" element={<Grievance />} />
          <Route path="/form-privacy/:form_id" element={<NoticeofPrivacy />} />
          <Route
            path="/form-consent-anethesia/:form_id"
            element={<PatientConsentAnethesia />}
          />
          <Route
            path="/form-procedure-consent/:form_id"
            element={<PatientConsentProcedure />}
          />
          <Route
            path="/form-patient-history/:form_id"
            element={<PatientHistoryandPhysical />}
          />
          <Route
            path="/form-registration/:form_id"
            element={<PatientRegistration />}
          />
          <Route
            path="/form-satisfaction/:form_id"
            element={<PatientSatisfaction />}
          />
        </>
      );
    }
  };

  // Define an array of paths that should display showPatientNav
  const showPatientNav =
    [
      `/patient/:id/patient-forms/:nursing_id/pin-modal`,
      `/patient/:id/patient-forms/:nursing_id/thank-you`,
      `/patient/:id/patient-forms/:nursing_id`,
    ].some((path) =>
      // Check if the current location's pathname matches any of the paths in the array
      location.pathname.match(
        new RegExp(`^${path.replace(/:id|:nursing_id/g, "\\d+")}$`)
      )
    ) ||
    location.pathname.startsWith("/patient-form") ||
    location.pathname.startsWith("/form");

  return (
    <>
      {showPatientNav ? <PatientNav /> : <Nav />}
      <Routes>
        {/* All User */}
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup-success" element={<SignupSuccess />} />

        {/* Forgot Password */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp" element={<OTPValidation />} />

        {/* Additional Routes */}
        {isLoggedIn()}
        {checkUser()}

        <Route path="*" element={<Login />} />
      </Routes>
    </>
  );
};

export default AppWrapper;
