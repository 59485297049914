import React from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";

const PatientConsentAnethesia = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <h2 className="form-title">Patient Consent Anethesia</h2>
      <div className="form-content">
        <form>
          <p>
            <strong>I understand that: (Initial)</strong>
          </p>
          <ul className="anethesia-ul">
            <li className="anethesia-li">
              <input type="text" id="initial1" />
              <label htmlFor="initial1">
                I will need anesthesia services for the surgical procedures(s)
                to be done on{" "}
                <strong>
                  <u>12/17/2021</u>
                </strong>
                , and that the type of anesthesia to be used will depend upon
                the procedure and my physical condition.
              </label>
            </li>
            <li className="anethesia-li">
              <input type="text" id="initial2" />
              <label htmlFor="initial2">
                Anesthesia is a specialty medical service which manages patients
                who are rendered unconscious or with diminished response to pain
                and stress during the course of a medical, surgical, or
                obstetrical procedure.
              </label>
            </li>
            <li className="anethesia-li">
              <input type="text" id="initial3" />
              <label htmlFor="initial3">
                During the course of the surgical procedure, conditions may
                require additional or different anesthetic monitoring or
                techniques, and I ask that the anesthesiologist provide any
                other necessary services for my benefit and well-being.
              </label>
            </li>
            <li className="anethesia-li">
              <input type="text" id="initial4" />
              <label htmlFor="initial4">
                In addition to the anesthesiologist whose name appears on this
                document, my anesthetic services may be provided by another
                anesthesiologist.
              </label>
            </li>
            <li className="anethesia-li">
              <input type="text" id="initial5" />
              <label htmlFor="initial5">
                No guarantees have been made by anyone regarding the anesthesia
                services which I am agreeing to have.
              </label>
            </li>
          </ul>
          <h3>
            <u>Type of Anesthesia and Definitions</u>
          </h3>

          <h4>
            <u>A. General Anesthesia:</u>
          </h4>
          <ol>
            <li>
              Endotracheal anesthesia: anesthetic and respiratory gases are
              passed through a tube placed in the trachea (windpipe) via the
              nose or mouth.
            </li>
            <li>
              Mask anesthesia: anesthetic gases are passed through a mask which
              covers the nose and mouth.
            </li>
          </ol>

          <h4>
            <u>B. Regional Anesthesia:</u>
          </h4>
          <ol>
            <li>
              Epidural anesthesia: a small catheter is inserted into epidural
              (spinal) space so that anesthetizing agents may be given to
              prolong the duration of anesthesia, or medications given in single
              injection into epidural space.
            </li>
            <li>
              Spinal anesthesia: the anesthetic agents are injected into
              specific areas to inhibit nerve transmission.
            </li>
            <li>
              Caudal anesthesia: anesthetic medications are given into the
              tailbone area.
            </li>
          </ol>

          <h4>
            <u>C. Nerve Blocks:</u>
          </h4>
          <p>
            Sympathetic (cervical, thoracic ganglion and lumbar sympathetic
            block); anesthetic medications given in the neck, chest and back to
            relieve pain.
          </p>

          <h4>
            <u>D. Monitored Anesthesia Care (MAC):</u>
          </h4>
          <p>
            Includes the monitoring of at least blood pressure, oxygenation,
            pulse and mental state, supplementing analgesia as needed.
          </p>

          <h4>
            <u>E. Conscious Sedation:</u>
          </h4>
          <p>
            This sedation induces an altered state of consciousness that
            minimizes pain and discomfort through the use of pain relievers and
            sedatives. Patients, who receive conscious sedation usually, are
            able to speak and respond to verbal cues throughout the procedure,
            communicating any discomfort they experience to the provider. A
            brief period of amnesia may erase any memory of the procedure.
          </p>

          <h4>
            <u>F. Local Anesthesia:</u>
          </h4>
          <p>
            Local anesthesia is any technique to render part of the body
            insensitive to pain without affecting consciousness.
          </p>

          <h4 className="important">
            <u> Risks and Complications may include but are not limited to:</u>
          </h4>
          <p>
            Allergic/adverse reaction, aspiration, backache, brain damage,
            comas, dental injury, headache, inability to reverse the effects of
            anesthesia, infection, localized swelling and/or redness, muscle
            aches, nausea, ophthalmic (eye) injury, pain, paralysis, pneumonia,
            positional nerve injury, recall of sound/noise/speech by others,
            seizures, sore throat, wrong site for injection of anesthesia, and
            death.
          </p>

          <p className="note">
            I have been given the opportunity to ask questions about my
            anesthesia and feel that I have sufficient information to give this
            informed consent. I agree to the administration of the anesthesia
            prescribed for me. I recognize the alternative to acceptance of
            anesthesia might be no anesthesia for the procedure.
          </p>
          <div className="grievance-container mt-50">
            <div className="gc-item">
              {" "}
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Patient Name</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Patient Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
                required
              />
              <p>Date</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Time
              </label>
              <input
                type="text"
                id="time"
                name="time"
                className="bottom-border-input"
                required
              />
              <p>Time</p>
            </div>

            <div className="gc-item">
              {" "}
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Patient Signature</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Patient Signature
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
                required
              />
              <p>Date</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Time
              </label>
              <input
                type="text"
                id="time"
                name="time"
                className="bottom-border-input"
                required
              />
              <p>Time</p>
            </div>

            <div className="gc-item">
              {" "}
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Witness Signature</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Witness Signature
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
                required
              />
              <p>Date</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Time
              </label>
              <input
                type="text"
                id="time"
                name="time"
                className="bottom-border-input"
                required
              />
              <p>Time</p>
            </div>
            <div className="gc-item">
              {" "}
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Anethesia Signature</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
               Anethesia Signature
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
                required
              />
              <p>Date</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Time
              </label>
              <input
                type="text"
                id="time"
                name="time"
                className="bottom-border-input"
                required
              />
              <p>Time</p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PatientConsentAnethesia;
