import React, { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/images/sidebar_logo.webp";
import { loginUser, updatePassword } from "../../utils/api";
import { reroute } from "../../utils/reroute";
import Auth from "../../utils/auth";
import bgImage from "../../assets/images/background_logo.webp";

const Login = () => {
  // Form States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  //otp password states
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Gather Data for query
    const formData = {
      email: email,
      password: password,
    };
    // Try to Log the User in
    try {
      const response = await loginUser(formData);

      // Handle unsuccesfull login
      if (!response.ok) {
        const { message } = await response.json();
        if (message === "Incorrect password for this account.") {
          setErrorMessage("Incorrect credentials for this account.");
        } else if (message === "There is no account found with this email.") {
          setErrorMessage("There is no account found with this email.");
        } else {
          setErrorMessage("Something has gone wrong, please try again.");
        }
      }
      const { token, role } = await response.json();
      // If successfull, save token and re-route to their "dashboard"

      Auth.login(token);
      reroute(role);
    } catch (err) {
      console.error("Login error:", err);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };
  const handlePasswordReset = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("New passwords do not match.");
      return;
    }

    try {
      //request to "/api/users/update-password"
      const response = await updatePassword({ email, otp, newPassword });

      if (response.ok) {
        setErrorMessage(
          "Password successfully updated. Please log in with your new password."
        );
        //hide change password inputs
        setShowChangePassword(false);
      } else {
        const { message } = await response.json();
        setErrorMessage(message || "Failed to update password.");
      }
    } catch (error) {
      console.error("There was an error updating the password:", error);
      setErrorMessage("An error occurred while updating the password.");
    }
  };
  return (
    <>
      <div className="auth-background-logo">
        <img
          src={bgImage}
          alt="Cornerstone Logo"
          className="background-image"
        />
      </div>
      <div className="login-container">
        <div className="login-box">
          <h2 className="login-title">Login to your account</h2>
          <form
            autoComplete="off"
            onSubmit={
              showChangePassword ? handlePasswordReset : handleFormSubmit
            }
          >
            {!showChangePassword ? (
              <>
                <div className="form-group">
                  {/* Email */}
                  <input
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="login-input"
                    type="email"
                    id="email"
                    name="email"
                    required
                    placeholder="Email"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  {/* Password */}
                  <input
                    className="login-input"
                    type="password"
                    id="password"
                    name="password"
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="Password"
                    autoComplete="off"
                  />
                </div>
                {/* Login Button */}
                <button type="submit" className="login-btn">
                  Login
                </button>
                {/* Display Error Message */}
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
              </>
            ) : (
              <>
                {/* Change password inputs */}
                <label htmlFor="newPassword">New Password</label>
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <label htmlFor="confirmNewPassword">Confirm New Password</label>
                <input
                  type="password"
                  id="confirmNewPassword"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                />
                <input
                  type="submit"
                  value="Change Password"
                  aria-label="change-password-button"
                />
                {/* Display Error Message */}
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
              </>
            )}
          </form>
          <div className="links">
            {/* Forgot Password */}
            <Link to="/forgot-password">Forgot Password?</Link>
            <p className="account-text">
              Don't have an account?{" "}
              <span>
                {/* Signup */}
                <Link to="/signup">Sign up now!</Link>
              </span>
            </p>
          </div>
        </div>
      </div>
      <footer className="login-footer">
        <img src={footerLogo} alt="cornerstone" />
      </footer>
    </>
  );
};
export default Login;
