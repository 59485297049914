import React from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";

const PatientHistoryandPhysical = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <div className="form-content">
        <form>
          <h2 className="form-title">Patient History and Physical</h2>
          <div className="pi-container">
            <div className="pi-form-row">
              <label>Referring Physician:</label>
              <input type="text" className="pi-short-input" />
              <label>Date:</label>
              <input type="text" className="pi-short-input" />
              <label>Time:</label>
              <input type="text" className="pi-short-input" />
            </div>
            <div className="pi-form-row">
              <label>Patient’s Age:</label>
              <input type="text" className="pi-short-input" />
              <label>Sex:</label>
              <input type="text" className="pi-short-input" />
              <label>B.P.:</label>
              <input type="text" className="pi-short-input" />
              <label>Pulse:</label>
              <input type="text" className="pi-short-input" />
              <label>Temp:</label>
              <input type="text" className="pi-short-input" />
              <label>Pain Level:</label>
              <input type="text" className="pi-short-input" />
              <p>/10</p>
            </div>
            <div className="pi-form-row">
              <label>Chief Complaint:</label>
              <input type="text" className="pi-long-input" />
            </div>
            <div className="pi-form-row">
              <label>Current Medications:</label>
              <input type="text" className="pi-long-input" />
            </div>
            <div className="pi-form-row">
              <label>History of Present Illness/Reason for Visit:</label>
              <input type="text" className="pi-long-input" />
            </div>
            <div className="pi-form-row">
              <label>Previous Surgical History:</label>
              <input type="text" className="pi-long-input" />
            </div>
            <div className="pi-form-row">
              <label>Allergies:</label>
              <input type="text" className="pi-long-input" />
            </div>
          </div>
          <p className="form-title">
            <strong>
              <i>
                Please indicate below if you have had any history of the
                conditions listed. Circle condition or checkbox if none.
              </i>
            </strong>
          </p>
          <div className="ph-grid-container">
            <div className="ph-grid-item">
              <label className="ph-label">Respiratory:</label>

              <input type="text" className="ph-long-input" />
              <label className="ph-checkbox-label">
                <input type="radio" className="ph-checkbox" />{" "}
                <strong>NONE</strong>
              </label>
            </div>

            <div className="ph-grid-item">
              <label className="ph-label">Cardiovascular:</label>
              <input type="text" className="ph-long-input" />
              <label className="ph-checkbox-label">
                <input type="radio" className="ph-checkbox" />{" "}
                <strong>NONE</strong>
              </label>
            </div>
            <div className="ph-grid-item">
              {" "}
              <div>
                <label>
                  <input type="radio" className="ph-checkbox" /> Asthma
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Bronchitis
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> COPD
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Dyspnea
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Orthopnea
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Pneumonia
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Cough
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> SOB
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Recent URI
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Tuberculosis
                </label>
              </div>
            </div>
            <div className="ph-grid-item">
              <div>
                <label>
                  <input type="radio" className="ph-checkbox" /> Abnormal EKG
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Angina
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> ASHD
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> CHF
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Exercise
                  Intolerance
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Productive
                  Hypertension
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> MI
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Murmur
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Pacemaker
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Valvular
                  Disease
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Rheumatic
                  Fever
                </label>
              </div>
            </div>
            <div className="ph-grid-item">
              <label className="ph-label">Hepato/Gastrointestinal:</label>
              <input type="text" className="ph-long-input" />
              <label className="ph-checkbox-label">
                <input type="radio" className="ph-checkbox" />{" "}
                <strong>NONE</strong>
              </label>
            </div>
            <div className="ph-grid-item">
              <label className="ph-label">Neuro/Musculoskeletal:</label>
              <input type="text" className="ph-long-input" />
              <label className="ph-checkbox-label">
                <input type="radio" className="ph-checkbox" />{" "}
                <strong>NONE</strong>
              </label>
            </div>
            <div className="ph-grid-item">
              <div>
                <label>
                  <input type="radio" className="ph-checkbox" /> Bowel
                  Obstruction
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Cirrhosis
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" />{" "}
                  Jaundice/Hepatitis
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Hiatal
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" />{" "}
                  Nausea/Vomiting
                </label>
              </div>
            </div>
            <div className="ph-grid-item">
              <div>
                <label>
                  <input type="radio" className="ph-checkbox" /> Arthritis
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Back
                  problems
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" />{" "}
                  CVA/Stroke/TIA
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Headaches
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Ulcers
                  Hernia/Reflux
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Muscle
                  Weakness
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Syncope
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Seizures
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Paralysis
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Paresthesia
                </label>
              </div>
            </div>
            <div className="ph-grid-item">
              <label className="ph-label">Renal/Endocrine:</label>
              <input type="text" className="ph-long-input" />
              <label className="ph-checkbox-label">
                <input type="radio" className="ph-checkbox" />{" "}
                <strong>NONE</strong>
              </label>
            </div>
            <div className="ph-grid-item">
              <label className="ph-label">Other:</label>
              <input type="text" className="ph-long-input" />
              <label className="ph-checkbox-label">
                <input type="radio" className="ph-checkbox" />{" "}
                <strong>NONE</strong>
              </label>
            </div>
            <div className="ph-grid-item">
              <div>
                <label>
                  <input type="radio" className="ph-checkbox" /> Diabetes
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Renal
                  failure
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Thyroid
                  disease
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Urinary
                  retention
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Significant
                  Weight Loss/Gain
                </label>
              </div>
            </div>
            <div className="ph-grid-item">
              <div>
                <label>
                  <input type="radio" className="ph-checkbox" /> Anemia
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Bleeding
                  tendencies
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Cancer
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Chemotherapy
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Urinary
                  Tract Infection
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" />{" "}
                  Immunosuppressed
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Pregnancy
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Recent
                  steroid use
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Transfusion
                  history
                </label>
                <label>
                  <input type="radio" className="ph-checkbox" /> Sickle Cell
                </label>
              </div>
            </div>
          </div>
          <div className="sa-form">
            <div className="sa-row">
              <p>
                <strong>Smoking:</strong>
              </p>
              <input type="radio" className="sa-checkbox" /> N
              <input type="radio" className="sa-checkbox " /> Y
              <p className="sa-text">
                <strong># packs per day/week</strong>
              </p>
              <input type="text" className="sa-input-short" />
              <p className="sa-text">
                <strong>Alcohol Use:</strong>
              </p>
              <input type="radio" className="sa-checkbox" /> N
              <input type="radio" className="sa-checkbox " /> Y
              <p className="sa-text">
                <strong>Frequency:</strong>
              </p>
              <input type="text" className="sa-input-short" />
              <p className="sa-text">
                <strong>Drug Use:</strong>
              </p>
              <input type="radio" className="sa-checkbox" /> N
              <input type="radio" className="sa-checkbox " /> Y
              <p className="sa-text">
                <strong>Freq:</strong>
              </p>
              <input type="text" className="sa-input-short" />
            </div>
            <div className="sa-row">
              <p>
                Is there any history of the following medical conditions in your{" "}
                <u>family</u>?
              </p>
              <input type="radio" className="sa-checkbox" /> High Blood
              Pressure
              <input type="radio" className="sa-checkbox" /> Heart Disease
              <input type="radio" className="sa-checkbox" /> Stroke
              <input type="radio" className="sa-checkbox" /> Lung Disease
              <input type="radio" className="sa-checkbox" /> Diabetes
              <input type="radio" className="sa-checkbox" /> Bleeding
              Disorder
              <input type="radio" className="sa-checkbox" /> Cancer
              <p className="sa-text">Other:</p>
              <input type="text" className="sa-input-long" />
            </div>
            <div className="sa-row">
              <p>
                Previous surgical/anesthesia complications (patient or family)
              </p>
              <input type="radio" className="sa-checkbox" /> No
              <input type="radio" className="sa-checkbox" /> Yes
              <p className="sa-text">Describe:</p>
              <input type="text" className="sa-input-long" />
            </div>
          </div>
          <div className="ph-container mt-50 sig-mobile">
            <div className="gc-item">
              {" "}
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Patient Signature</p>
            </div>
            <div className="gc-item">
              {" "}
              <label htmlFor="name" className="hidden">
                Patient Signature
              </label>
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Signature of Person Completing Form if Not Patient</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Relationship to Patient
              </label>
              <input
                type="text"
                id="time"
                name="time"
                className="bottom-border-input"
                required
              />
              <p> Relationship to Patient</p>
            </div>
          </div>
          <div className="hp-form">
            <p>
              The Goals, Methods and Alternatives (with risks) of this operation
              were discussed. Also, Lack of Guarantee of Results, Possible Need
              of Additional Treatment and Risks and Complications. Patient
              understands and accepts.
            </p>
            <div className="hp-row">
              <p>Skin</p>
              <input
                type="text"
                id="skin"
                name="skin"
                className="hp-bottom-border-input"
                required
              />
            </div>
            <div className="hp-row">
              <p>Head & Neck</p>
              <input
                type="text"
                id="head-neck"
                name="head-neck"
                className="hp-bottom-border-input"
                required
              />
            </div>
            <div className="hp-row">
              <p>
                <strong>Heart</strong>
              </p>
              <input
                type="text"
                id="heart"
                name="heart"
                className="hp-bottom-border-input"
                required
              />
            </div>
            <div className="hp-row">
              <p>Neurological</p>
              <input
                type="text"
                id="neurological"
                name="neurological"
                className="hp-bottom-border-input"
                required
              />
            </div>
            <div className="hp-row">
              <p>
                <strong>Lungs</strong>
              </p>
              <input
                type="text"
                id="lungs"
                name="lungs"
                className="hp-bottom-border-input"
                required
              />
            </div>
            <div className="hp-row">
              <p>Abdomen</p>
              <input
                type="text"
                id="abdomen"
                name="abdomen"
                className="hp-bottom-border-input"
                required
              />
            </div>
            <div className="hp-row">
              <p>Extremities</p>
              <input
                type="text"
                id="extremities"
                name="extremities"
                className="hp-bottom-border-input"
                required
              />
            </div>
            <div className="hp-row">
              <p>
                <strong>Assessment and Plan</strong>
              </p>
              <input
                type="text"
                id="assessment-plan"
                name="assessment-plan"
                className="hp-bottom-border-input"
                required
              />
            </div>
            <div className="hp-checkbox-row">
              <input type="radio" className="hp-checkbox" />
              <strong>
                <label>No change from prior H&amp;P made on</label>
              </strong>
              <input
                type="text"
                id="prior-hp"
                name="prior-hp"
                className="hp-bottom-border-input"
                required
              />
            </div>
            <div className="privacy-container mt-50 sig-mobile">
              <div className="privacy-item">
                {" "}
                <SignatureButton />
                <hr className="signature-line"></hr>
                <p>Signature of Physician</p>
              </div>
              <div className="privacy-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                Signature of Physician
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  required
                />
                <p>Date</p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PatientHistoryandPhysical;
