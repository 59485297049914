import React from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";

const AnethesiaQuestionnaire = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form>
          {" "}
          <h2 className="form-title">Anethesia Questionnaire</h2>
          <div className="aq-inputs-row">
            <div className="aq-input-item">
              <label>LAST FOOD</label>
              <input type="text" />
            </div>
            <div className="aq-input-item">
              <label>LAST FLUID</label>
              <input type="text" />
            </div>
            <div className="aq-input-item">
              <label>BP</label>
              <input type="text" />
            </div>
            <div className="aq-input-item">
              <label>PULSE</label>
              <input type="text" />
            </div>
            <div className="aq-input-item">
              <label>RESP</label>
              <input type="text" />
            </div>
            <div className="aq-input-item">
              <label>TEMP</label>
              <input type="text" />
            </div>
            <div className="aq-input-item">
              <label>O2 STAT</label>
              <input type="text" />
            </div>
          </div>
          <div className="aq-medical-form">
            <div className="aq-form-section">
              <h4>DRUGS AND MEDICATION:</h4>
              <p>
                List all medications you take and the dosages, including herbal
                supplements, vitamins, over-the-counter drugs, and recreational
                drugs use if any:
              </p>
              <input type="text" className="long-input" />
              <h4>SURGERIES:</h4>
              <p>
                List ALL previous operations, year and type of anesthesia.
                (general, local, spinal):
              </p>
              <input type="text" className="long-input" />
              <p>
                Height: <input type="text" className="short-input" /> Weight:{" "}
                <input type="text" className="short-input" />
              </p>
              <p>
                Have you Traveled anywhere in the past 30 days?{" "}
                <input type="text" className="long-input" />
              </p>
            </div>
            <div className="aq-form-section">
              <p>
                Do you have any MEDICATION ALLERGIES?
                <input type="checkbox" /> YES <input type="checkbox" /> NO
              </p>
              <p>
                If yes, please list:{" "}
                <input type="text" className="long-input" />
              </p>

              <p>
                Do you have any FOOD ALLERGIES?
                <input type="checkbox" /> YES <input type="checkbox" /> NO
              </p>
              <p>
                If yes, please list:
                <input type="text" className="long-input" />
              </p>

              <p>
                Do you have SLEEP APNEA? (BRING C-PAP)
                <input type="checkbox" /> YES <input type="checkbox" /> NO
              </p>
              <p>If yes, please call 562-270-4327 IMMEDIATELY</p>

              <p>
                Any allergies to LATEX or RUBBER products?
                <input type="checkbox" /> YES <input type="checkbox" /> NO
              </p>
              <p>
                List any other hospitalization with reason and approximate dates
                and any chronic illnesses or conditions. (On back)
              </p>
              <p>Dates and any chronic illnesses or conditions(on back)</p>
              <p>
                Preferred Language: <input type="text" className="long-input" />
              </p>
            </div>
          </div>
          <div>
            <h4>HAVE YOU HAD:</h4>
            <ol className="aq-form-list">
              <li className="aq-form-item">
                <p className="hyh-title">1. High Blood Pressure</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">2. Heart trouble or Heart Attack</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item list-letters">
                <p className="hyh-title">a. Chest pain or angina</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item list-letters">
                <p className="hyh-title">b. Irregular Heart beat</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item list-letters">
                <p className="hyh-title">c. Congestive heart failure</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item list-letters">
                <p className="hyh-title">d. abnormal electrocardiogram</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  3. Gastric Esophageal Reflux, Hiatal Hernia, Ulcers
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">4. Recent cold,cough or sore throat</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  5. Asthms, Emphysema, Bronchitis, or Breathing problems
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">6. Abdominal Chest X-ray</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">7. Diabetes</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">8. Yellow Jaundice or hepatitis</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">9. Kidney Disease</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">10. Abdominal bleeding problems </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">11. Stroke, numbness or weakness</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  12. Epilepsy or convulsive seizures{" "}
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  13. Broken bones of back,neck or face{" "}
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">14. Back trouble </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  15. Unusual muscle problems or diseases{" "}
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  16. Unexpected fevers or heat strokes{" "}
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">17. Bad reaction to anesthesia </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  18. Relatives with bad reaction to anethesia{" "}
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">19. Problems with motion sickness </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
            </ol>
          </div>
          <div>
            <h4>DO YOU:</h4>

            <ol className="aq-form-list">
              <li className="aq-form-item">
                <p className="hyh-title">1. Wear dentures</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">2. Have caps on teeth</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title"> 3. Drink alcohol</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">4. Smoke (how much per day/week)</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">
                  {" "}
                  5. Exercise or have strenuous activity
                </p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
              <li className="aq-form-item">
                <p className="hyh-title">6. Have any physical limitations</p>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
            </ol>
          </div>
          <div>
            <ol className="aq-form-list aq-pregnant">
              <li className="aq-form-item">
                <h4>ARE YOU PREGNANT</h4>
                <div className="aq-form-options">
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> YES
                  </label>
                  <label className="aq-option-label">
                    <input type="checkbox" className="checkbox" /> NO
                  </label>
                </div>
                <input type="text" className="long-input" />
              </li>
            </ol>
          </div>
          <div className="grid-2">
            <div className="signature-container mt-50">
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Patient Signature</p>
            </div>

            <div className="mt-50">
              <label htmlFor="name" className="hidden">
                Patient Siganture
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
                required
              />

              <p>Date</p>
            </div>
          </div>
          <div className="grid-2">
            <div className="signature-container mt-50">
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Nurse Signature</p>
            </div>

            <div className="mt-50">
              <label htmlFor="name" className="hidden">
                Nurse Signature
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
                required
              />

              <p>Date</p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AnethesiaQuestionnaire;
