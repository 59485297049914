import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import {
  udpateInsurance,
  deleteBilling,
  udpateOop,
  udpateCommercial,
  udpateComp,
  udpatePip,
} from "../../../utils/api";
import { replaceSpecialChar, formatPhone } from "../../../utils/helpers";

const moment = require("moment");

/**
 * @param {isAdmin} // Allows Admins to see "edit" & "delete" buttons
 * @param {billings} // Array of billing objects
 * @param {setBillings} // Prop from main Patient Information page - updates state holding billing records from the db
 * @param {postBilling} // Specific record for display
 * @returns BILLING SECTION DISPLAY FOR PATIENT INFORMATION PAGE
 */
const Billing = ({ isAdmin, billings, setBillings, postBilling }) => {
  // console.log(billings);
  const [isEditing, setIsEditing] = useState(false);
  const [billingType, setBillingType] = useState("Insurance");

  // Return Components (Forms) to Create the New Billing Types
  const getNewBillingTable = () => {
    switch (billingType) {
      case "Insurance":
        return (
          <NewInsurance
            isAdmin={isAdmin}
            isEditing={isEditing}
            billings={billings}
            setBillings={setBillings}
            postBilling={postBilling}
          />
        );
      case "Oop":
        return (
          <NewOop
            isAdmin={isAdmin}
            isEditing={isEditing}
            billings={billings}
            setBillings={setBillings}
            postBilling={postBilling}
          />
        );
      case "Pip":
        return (
          <NewPip
            isAdmin={isAdmin}
            isEditing={isEditing}
            billings={billings}
            setBillings={setBillings}
            postBilling={postBilling}
          />
        );
      case "Comp":
        return (
          <NewComp
            isAdmin={isAdmin}
            isEditing={isEditing}
            billings={billings}
            setBillings={setBillings}
            postBilling={postBilling}
          />
        );
      case "Commercial":
        return (
          <NewComInsurance
            isAdmin={isAdmin}
            isEditing={isEditing}
            billings={billings}
            setBillings={setBillings}
            postBilling={postBilling}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="billing-header">
        <h3 className="billing-heading">Billing Type</h3>
        {isAdmin && (
          <button
            className="edit-section-btn"
            onClick={() => setIsEditing((prev) => !prev)}
          >
            {isEditing ? "Close Edit" : "Edit Section"}
          </button>
        )}
      </div>

      {/* Display Billing Information from the DB */}
      {billings.map((bill, index) => (
        <div key={bill.id}>
          {bill.type === "Insurance" && (
            <Insurance
              isAdmin={isAdmin}
              isEditing={isEditing}
              bill={bill}
              setBillings={setBillings}
              billings={billings}
            />
          )}
          {bill.type === "Oop" && (
            <OutOfPocket
              isAdmin={isAdmin}
              isEditing={isEditing}
              bill={bill}
              setBillings={setBillings}
              billings={billings}
            />
          )}
          {bill.type === "Commercial" && (
            <CommercialInsurance
              isAdmin={isAdmin}
              isEditing={isEditing}
              bill={bill}
              setBillings={setBillings}
              billings={billings}
            />
          )}
          {bill.type === "Pip" && (
            <PersonalInjury
              isAdmin={isAdmin}
              isEditing={isEditing}
              bill={bill}
              setBillings={setBillings}
              billings={billings}
            />
          )}
          {bill.type === "Comp" && (
            <WorkersComp
              isAdmin={isAdmin}
              isEditing={isEditing}
              bill={bill}
              setBillings={setBillings}
              billings={billings}
            />
          )}
        </div>
      ))}

      {/* Dropdown & Inputs for Admin Editing */}
      {isEditing && (
        <div>
          <h3 style={{ marginTop: "60px" }}>Add New Billing</h3>
          <div>
            <select
              id="billing"
              value={billingType}
              onChange={(e) => setBillingType(e.target.value)}
            >
              <option value="Insurance">Insurance</option>
              <option value="Oop">Out of Pocket</option>
              <option value="Pip">Personal Injury</option>
              <option value="Comp">Worker's Comp</option>
              <option value="Commercial">Commercial</option>
            </select>
            {getNewBillingTable()}
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * VIEW, EDIT & DELETE INSURANCE BILLING TYPE
 * @param {isAdmin} // Allows Admins to see "edit" & "delete" buttons
 * @param {isEditing} // State to control read-only prop on inputs
 * @param {billings} // Array of billing objects
 * @param {setBillings} // Prop from main Patient Information page - updates state holding billing records from the db
 * @param {bill} // Specific record for display
 * @returns Insurance record
 */
const Insurance = ({ isAdmin, isEditing, billings, setBillings, bill }) => {
  const record = bill.insurances[0] || {};

  const [editBill, setEditBill] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const [dbBilling, setDbBilling] = useState({
    provider: record.provider,
    policy_number: record.policy_number,
    group_number: record.group_number,
    policy_through_employer: record.policy_through_employer,
    name_on_insurance: record.name_on_insurance,
    relation_to_patient: record.relation_to_patient,
    address: record.address,
    phone_number: record.phone_number,
    id_number: record.id_number,
    effective_date: record.effective_date,
  });
  const [newBilling, setNewBilling] = useState({
    provider: record.provider,
    policy_number: record.policy_number,
    group_number: record.group_number,
    policy_through_employer: record.policy_through_employer,
    name_on_insurance: record.name_on_insurance,
    relation_to_patient: record.relation_to_patient,
    address: record.address,
    phone_number: record.phone_number,
    id_number: record.id_number,
    effective_date: record.effective_date,
  });

  const updateBilling = () => {
    const payload = {};

    // Only update information in the db that has been changed
    if (newBilling.provider !== dbBilling.provider) {
      payload.provider = newBilling.provider;
    }
    if (newBilling.policy_number !== dbBilling.policy_number) {
      payload.policy_number = newBilling.policy_number;
    }
    if (newBilling.group_number !== dbBilling.group_number) {
      payload.group_number = newBilling.group_number;
    }
    if (
      newBilling.policy_through_employer !== dbBilling.policy_through_employer
    ) {
      payload.policy_through_employer = newBilling.policy_through_employer;
    }
    if (newBilling.name_on_insurance !== dbBilling.name_on_insurance) {
      payload.name_on_insurance = newBilling.name_on_insurance;
    }
    if (newBilling.relation_to_patient !== dbBilling.relation_to_patient) {
      payload.relation_to_patient = newBilling.relation_to_patient;
    }
    if (newBilling.address !== dbBilling.address) {
      payload.address = newBilling.address;
    }
    if (newBilling.phone_number !== dbBilling.phone_number) {
      payload.phone_number = newBilling.phone_number;
    }
    if (newBilling.id_number !== dbBilling.id_number) {
      payload.id_number = newBilling.id_number;
    }
    if (newBilling.effective_date !== dbBilling.effective_date) {
      payload.effective_date = newBilling.effective_date;
    }

    // If there is information to be updated, run the query
    if (Object.keys(payload).length !== 0) {
      // console.log(payload);
      udpateInsurance(record.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbBilling(newBilling);
          setEditBill(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    // Remove the billing record from state (for display)
    const updated = [];
    billings.map((item) => {
      if (item.id !== bill.id) {
        updated.push(item);
      }
    });

    // FETCH: Delete the Insurance record
    const payload = {
      date_removed: moment().format("MM/DD/YYYY"),
      isDeleted: true,
    };
    udpateInsurance(record.id, payload)
      .then((res) => res.json())
      .then((data) => {
        // FETCH: Delete the associated Billing record
        deleteBilling(bill.id)
          .then((res) => res.json())
          .then((data) => {
            setBillings(updated);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(newBilling, dbBilling) ? setCanUpdate(false) : setCanUpdate(true);
  }, [newBilling]);

  return (
    <div
      className="insurance-table-container"
      style={{ margin: "0", padding: "0" }}
    >
      {/* Insurance */}
      <div className="billing-header" style={{ marginTop: "30px" }}></div>
      <div className="insurance-table insurance-table-billing">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 className="table-label" style={{ marginLeft: "10px" }}>
            Insurance
          </h3>
          {isAdmin && isEditing && (
            <div style={{ marginLeft: "40px" }}>
              <button
                className="medication-edit-btn"
                id="bj-edit-btn"
                aria-label="edit"
                onClick={() => setEditBill(true)}
              >
                <Icon icon="typcn:edit" />
              </button>
              <button
                style={{ marginLeft: "40px" }}
                className="medication-delete-btn"
                id="bj-delete-btn"
                aria-label="delete"
                onClick={() => handleDelete()}
              >
                <Icon icon="bi:trash3" />
              </button>
            </div>
          )}
        </div>
        <hr style={{ borderTop: "1px solid #dacece", width: "100%" }}></hr>
        <div className="form-wrapper">
          <div className="form-inputs-billing">
            <div className="patient-form-group patient-form-group-billing">
              <label>Provider</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="provider"
                  name="provider"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.provider}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      provider: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="provider"
                  name="provider"
                  type="text"
                  value={dbBilling.provider}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Policy Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="policy_number"
                  name="policy_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.policy_number}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      policy_number: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="policy_number"
                  type="text"
                  value={dbBilling.policy_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Group Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="group_number"
                  name="group_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.group_number}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      group_number: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="group_number"
                  type="text"
                  value={dbBilling.group_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Policy through Employer</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="policy_through_employer"
                  name="policy_through_employer"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.policy_through_employer}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      policy_through_employer: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="policy_through_employer"
                  type="text"
                  value={dbBilling.policy_through_employer}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Name on Insurance</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="name_on_insurance"
                  name="name_on_insurance"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.name_on_insurance}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      name_on_insurance: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="name_on_insurance"
                  type="text"
                  value={dbBilling.name_on_insurance}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Relation to Patient</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="relation_to_patient"
                  name="relation_to_patient"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.relation_to_patient}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      relation_to_patient: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="relation_to_patient"
                  type="text"
                  value={dbBilling.relation_to_patient}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Address</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="address"
                  name="address"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.address}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      address: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="address"
                  type="text"
                  value={dbBilling.address}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Phone Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.phone_number}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setNewBilling({
                      ...newBilling,
                      phone_number: phone,
                    });
                  }}
                />
              ) : (
                <input
                  id="phone_number"
                  type="text"
                  value={dbBilling.phone_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Effective Date</label>
              {isAdmin && editBill ? (
                <input
                  type="date"
                  id="effective_date"
                  name="effective_date"
                  className={isEditing ? "" : "read-only"}
                  value={moment(newBilling.effective_date).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      effective_date: moment(e.target.value).format(
                        "MM/DD/YYYY"
                      ),
                    })
                  }
                />
              ) : (
                <input
                  id="effective_date"
                  type="text"
                  value={dbBilling.effective_date}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>ID Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="id_number"
                  name="id_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.id_number}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      id_number: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="id_number"
                  type="text"
                  value={dbBilling.id_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
          </div>
        </div>
        <div className="add-button-container">
          {isAdmin && editBill && (
            <button
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => {
                updateBilling();
              }}
              style={{ margin: "20px 0" }}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * CREATE INSURANCE BILLING TYPE
 * @param {postBilling} // Props from Patient Information page - creates the Billing & Insurance record
 * @returns New Insurance Form
 */
const NewInsurance = ({ postBilling }) => {
  const [newInsurance, setNewInsuracne] = useState({
    provider: "",
    policy_number: "",
    group_number: "",
    policy_through_employer: "",
    name_on_insurance: "",
    relation_to_patient: "",
    address: "",
    phone_number: "",
    id_number: "",
    effective_date: "",
  });

  return (
    <>
      <div
        style={{ margin: "10px 0", padding: "10px", border: "2px solid #bbb" }}
      >
        <div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="provider">Provider</label>
              <input
                id="provider"
                name="provider"
                type="text"
                value={newInsurance.provider}
                onChange={(e) =>
                  setNewInsuracne({ ...newInsurance, provider: e.target.value })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="effective-date">Effective Date</label>
              <input
                id="effective-date"
                name="effective-date"
                type="date"
                style={{ width: "100%" }}
                value={newInsurance.effective_date}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    effective_date: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="policy-number">Policy Number</label>
              <input
                id="policy-number"
                name="policy-number"
                type="text"
                value={newInsurance.policy_number}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    policy_number: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="group-number">Group Number</label>
              <input
                id="group-number"
                name="group-number"
                type="text"
                value={newInsurance.group_number}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    group_number: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="employer-policy">Policy through Employer</label>
              <input
                id="employer-policy"
                name="employer-policy"
                type="text"
                value={newInsurance.policy_through_employer}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    policy_through_employer: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="insurance-name">Name on Insurance</label>
              <input
                id="insurance-name"
                name="insurance-name"
                type="text"
                value={newInsurance.name_on_insurance}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    name_on_insurance: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="patient-relation">Relationship to Patient</label>
              <input
                id="patient-relation"
                name="patient-relation"
                type="text"
                value={newInsurance.relation_to_patient}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    relation_to_patient: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="insurance-address">Address</label>
              <input
                id="insurance-address"
                name="insurance-address"
                type="text"
                value={newInsurance.address}
                onChange={(e) =>
                  setNewInsuracne({ ...newInsurance, address: e.target.value })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="insurance-phone">Phone Number</label>
              <input
                id="insurance-phone"
                name="insurance-phone"
                type="text"
                value={newInsurance.insurancePhone}
                onChange={(e) => {
                  let num = replaceSpecialChar(e.target.value);
                  const phone = formatPhone(num);

                  setNewInsuracne({ ...newInsurance, phone_number: phone });
                }}
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="insurance-id">ID Number</label>
              <input
                id="insurance-id"
                name="insurance-id"
                type="text"
                value={newInsurance.id_number}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    id_number: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <button
            className="medication-add-btn"
            onClick={() => {
              const billingPayload = {
                type: "Insurance",
              };

              const insurancePayload = {
                ...newInsurance,
                date_created: moment().format("MM/DD/YYYY"),
              };

              postBilling("Insurance", billingPayload, insurancePayload);
            }}
          >
            Add
          </button>
        </div>
      </div>
    </>
  );
};

/**
 * VIEW, EDIT & DELETE OUT OF POCKET BILLING TYPE
 * @param {isAdmin} // Allows Admins to see "edit" & "delete" buttons
 * @param {isEditing} // State to control read-only prop on inputs
 * @param {billings} // Array of billing objects
 * @param {setBillings} // Prop from main Patient Information page - updates state holding billing records from the db
 * @param {bill} // Specific record for display
 * @returns Out of Pocket record
 */
const OutOfPocket = ({ isAdmin, isEditing, billings, setBillings, bill }) => {
  const record = bill.oops[0] || {};

  const [editBill, setEditBill] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const [dbBilling, setDbBilling] = useState({
    provider: record.provider,
    policy_number: record.policy_number,
    group_number: record.group_number,
    policy_through_employer: record.policy_through_employer,
    name_on_insurance: record.name_on_insurance,
    relation_to_patient: record.relation_to_patient,
    address: record.address,
    phone_number: record.phone_number,
    id_number: record.id_number,
    effective_date: record.effective_date,
  });
  const [newBilling, setNewBilling] = useState({
    provider: record.provider,
    policy_number: record.policy_number,
    group_number: record.group_number,
    policy_through_employer: record.policy_through_employer,
    name_on_insurance: record.name_on_insurance,
    relation_to_patient: record.relation_to_patient,
    address: record.address,
    phone_number: record.phone_number,
    id_number: record.id_number,
    effective_date: record.effective_date,
  });

  const updateBilling = () => {
    const payload = {};

    // Only update information in the db that has been changed
    if (newBilling.provider !== dbBilling.provider) {
      payload.provider = newBilling.provider;
    }
    if (newBilling.policy_number !== dbBilling.policy_number) {
      payload.policy_number = newBilling.policy_number;
    }
    if (newBilling.group_number !== dbBilling.group_number) {
      payload.group_number = newBilling.group_number;
    }
    if (
      newBilling.policy_through_employer !== dbBilling.policy_through_employer
    ) {
      payload.policy_through_employer = newBilling.policy_through_employer;
    }
    if (newBilling.name_on_insurance !== dbBilling.name_on_insurance) {
      payload.name_on_insurance = newBilling.name_on_insurance;
    }
    if (newBilling.relation_to_patient !== dbBilling.relation_to_patient) {
      payload.relation_to_patient = newBilling.relation_to_patient;
    }
    if (newBilling.address !== dbBilling.address) {
      payload.address = newBilling.address;
    }
    if (newBilling.phone_number !== dbBilling.phone_number) {
      payload.phone_number = newBilling.phone_number;
    }
    if (newBilling.id_number !== dbBilling.id_number) {
      payload.id_number = newBilling.id_number;
    }
    if (newBilling.effective_date !== dbBilling.effective_date) {
      payload.effective_date = newBilling.effective_date;
    }

    // If there is information to be updated, run the query
    if (Object.keys(payload).length !== 0) {
      // console.log(payload);
      udpateOop(record.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbBilling(newBilling);
          setEditBill(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    // Remove the billing record from state (for display)
    const updated = [];
    billings.map((item) => {
      if (item.id !== bill.id) {
        updated.push(item);
      }
    });

    // FETCH: Delete the Out of Pocket record
    const payload = {
      date_removed: moment().format("MM/DD/YYYY"),
      isDeleted: true,
    };
    udpateOop(record.id, payload)
      .then((res) => res.json())
      .then((data) => {
        // FETCH: Delete the associated Billing record
        deleteBilling(bill.id)
          .then((res) => res.json())
          .then((data) => {
            setBillings(updated);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(newBilling, dbBilling) ? setCanUpdate(false) : setCanUpdate(true);
  }, [newBilling]);

  return (
    <div
      className="insurance-table-container"
      style={{ margin: "0", padding: "0" }}
    >
      {/* Out of Pocket */}
      <div className="billing-header" style={{ marginTop: "30px" }}></div>
      <div className="insurance-table insurance-table-billing">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 className="table-label" style={{ marginLeft: "10px" }}>
            Out of Pocket
          </h3>
          {isAdmin && isEditing && (
            <div style={{ marginLeft: "40px" }}>
              <button
                className="medication-edit-btn"
                id="bj-edit-btn"
                aria-label="edit"
                onClick={() => setEditBill(true)}
              >
                <Icon icon="typcn:edit" />
              </button>
              <button
                style={{ marginLeft: "40px" }}
                className="medication-delete-btn"
                id="bj-delete-btn"
                aria-label="delete"
                onClick={() => handleDelete()}
              >
                <Icon icon="bi:trash3" />
              </button>
            </div>
          )}
        </div>
        <hr style={{ borderTop: "1px solid #dacece", width: "100%" }}></hr>
        <div className="form-wrapper">
          <div className="form-inputs-billing">
            <div className="patient-form-group patient-form-group-billing">
              <label>Provider</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="provider"
                  name="provider"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.provider}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      provider: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="provider"
                  name="provider"
                  type="text"
                  value={dbBilling.provider}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Policy Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="policy_number"
                  name="policy_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.policy_number}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      policy_number: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="policy_number"
                  type="text"
                  value={dbBilling.policy_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Group Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="group_number"
                  name="group_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.group_number}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      group_number: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="group_number"
                  type="text"
                  value={dbBilling.group_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Policy through Employer</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="policy_through_employer"
                  name="policy_through_employer"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.policy_through_employer}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      policy_through_employer: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="policy_through_employer"
                  type="text"
                  value={dbBilling.policy_through_employer}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Name on Insurance</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="name_on_insurance"
                  name="name_on_insurance"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.name_on_insurance}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      name_on_insurance: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="name_on_insurance"
                  type="text"
                  value={dbBilling.name_on_insurance}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Relation to Patient</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="relation_to_patient"
                  name="relation_to_patient"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.relation_to_patient}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      relation_to_patient: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="relation_to_patient"
                  type="text"
                  value={dbBilling.relation_to_patient}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Address</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="address"
                  name="address"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.address}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      address: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="address"
                  type="text"
                  value={dbBilling.address}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Phone Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.phone_number}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setNewBilling({
                      ...newBilling,
                      phone_number: phone,
                    });
                  }}
                />
              ) : (
                <input
                  id="phone_number"
                  type="text"
                  value={dbBilling.phone_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Effective Date</label>
              {isAdmin && editBill ? (
                <input
                  type="date"
                  id="effective_date"
                  name="effective_date"
                  className={isEditing ? "" : "read-only"}
                  value={moment(newBilling.effective_date).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      effective_date: moment(e.target.value).format(
                        "MM/DD/YYYY"
                      ),
                    })
                  }
                />
              ) : (
                <input
                  id="effective_date"
                  type="text"
                  value={dbBilling.effective_date}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>ID Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="id_number"
                  name="id_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.id_number}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      id_number: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="id_number"
                  type="text"
                  value={dbBilling.id_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
          </div>
        </div>
        <div className="add-button-container">
          {isAdmin && editBill && (
            <button
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => {
                updateBilling();
              }}
              style={{ margin: "20px 0" }}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * CREATE OUT OF POCKET BILLING TYPE
 * @param {postBilling} // Props from Patient Information page - creates the Billing & Oop record
 * @returns New Out of Pocket Form
 */
const NewOop = ({ postBilling }) => {
  const [newInsurance, setNewInsuracne] = useState({
    provider: "",
    policy_number: "",
    group_number: "",
    policy_through_employer: "",
    name_on_insurance: "",
    relation_to_patient: "",
    address: "",
    phone_number: "",
    id_number: "",
    effective_date: "",
  });

  return (
    <>
      <div
        style={{ margin: "10px 0", padding: "10px", border: "2px solid #bbb" }}
      >
        <div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="provider">Provider</label>
              <input
                id="provider"
                name="provider"
                type="text"
                value={newInsurance.provider}
                onChange={(e) =>
                  setNewInsuracne({ ...newInsurance, provider: e.target.value })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="effective-date">Effective Date</label>
              <input
                id="effective-date"
                name="effective-date"
                value={moment(newInsurance.effective_date).format("YYYY-MM-DD")}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    effective_date: moment(e.target.value).format("MM/DD/YYYY"),
                  })
                }
                type="date"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="policy-number">Policy Number</label>
              <input
                id="policy-number"
                name="policy-number"
                type="text"
                value={newInsurance.policy_number}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    policy_number: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="group-number">Group Number</label>
              <input
                id="group-number"
                name="group-number"
                type="text"
                value={newInsurance.group_number}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    group_number: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="employer-policy">Policy through Employer</label>
              <input
                id="employer-policy"
                name="employer-policy"
                type="text"
                value={newInsurance.policy_through_employer}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    policy_through_employer: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="insurance-name">Name on Insurance</label>
              <input
                id="insurance-name"
                name="insurance-name"
                type="text"
                value={newInsurance.name_on_insurance}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    name_on_insurance: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="patient-relation">Relationship to Patient</label>
              <input
                id="patient-relation"
                name="patient-relation"
                type="text"
                value={newInsurance.relation_to_patient}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    relation_to_patient: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="insurance-address">Address</label>
              <input
                id="insurance-address"
                name="insurance-address"
                type="text"
                value={newInsurance.address}
                onChange={(e) =>
                  setNewInsuracne({ ...newInsurance, address: e.target.value })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="insurance-phone">Phone Number</label>
              <input
                id="insurance-phone"
                name="insurance-phone"
                type="text"
                value={newInsurance.insurancePhone}
                onChange={(e) => {
                  let num = replaceSpecialChar(e.target.value);
                  const phone = formatPhone(num);

                  setNewInsuracne({ ...newInsurance, phone_number: phone });
                }}
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="insurance-id">ID Number</label>
              <input
                id="insurance-id"
                name="insurance-id"
                type="text"
                value={newInsurance.id_number}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    id_number: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <button
            className="medication-add-btn"
            onClick={() => {
              const billingPayload = {
                type: "Oop",
              };

              const insurancePayload = {
                ...newInsurance,
                date_created: moment().format("MM/DD/YYYY"),
              };

              postBilling("Oop", billingPayload, insurancePayload);
            }}
          >
            Add
          </button>
        </div>
      </div>
    </>
  );
};

/**
 * VIEW, EDIT & DELETE PERSONAL INJURY BILLING TYPE
 * @param {isAdmin} // Allows Admins to see "edit" & "delete" buttons
 * @param {isEditing} // State to control read-only prop on inputs
 * @param {billings} // Array of billing objects
 * @param {setBillings} // Prop from main Patient Information page - updates state holding billing records from the db
 * @param {bill} // Specific record for display
 * @returns Personal Injury record
 */
const PersonalInjury = ({
  isAdmin,
  isEditing,
  billings,
  setBillings,
  bill,
}) => {
  const record = bill.pips[0] || {};

  const [editBill, setEditBill] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const [dbBilling, setDbBilling] = useState({
    case_number: record.case_number,
    date_of_injury: record.date_of_injury,
    attorney_name: record.attorney_name,
    attorney_phone: record.attorney_phone,
    attorney_fax: record.attorney_fax,
    case_manager_name: record.case_manager_name,
    case_manager_phone: record.case_manager_phone,
    case_manager_fax: record.case_manager_fax,
    case_manager_email: record.case_manager_email,
    case_manager_address: record.case_manager_address,
    type_of_accident: record.type_of_accident,
  });
  const [newBilling, setNewBilling] = useState({
    case_number: record.case_number,
    date_of_injury: record.date_of_injury,
    attorney_name: record.attorney_name,
    attorney_phone: record.attorney_phone,
    attorney_fax: record.attorney_fax,
    case_manager_name: record.case_manager_name,
    case_manager_phone: record.case_manager_phone,
    case_manager_fax: record.case_manager_fax,
    case_manager_email: record.case_manager_email,
    case_manager_address: record.case_manager_address,
    type_of_accident: record.type_of_accident,
  });

  const updateBilling = () => {
    const payload = {};

    // Only update information in the db that has been changed
    if (newBilling.case_number !== dbBilling.case_number) {
      payload.case_number = newBilling.case_number;
    }
    if (newBilling.date_of_injury !== dbBilling.date_of_injury) {
      payload.date_of_injury = newBilling.date_of_injury;
    }
    if (newBilling.attorney_name !== dbBilling.attorney_name) {
      payload.attorney_name = newBilling.attorney_name;
    }
    if (newBilling.attorney_phone !== dbBilling.attorney_phone) {
      payload.attorney_phone = newBilling.attorney_phone;
    }
    if (newBilling.attorney_fax !== dbBilling.attorney_fax) {
      payload.attorney_fax = newBilling.attorney_fax;
    }
    if (newBilling.case_manager_name !== dbBilling.case_manager_name) {
      payload.case_manager_name = newBilling.case_manager_name;
    }
    if (newBilling.case_manager_phone !== dbBilling.case_manager_phone) {
      payload.case_manager_phone = newBilling.case_manager_phone;
    }
    if (newBilling.case_manager_fax !== dbBilling.case_manager_fax) {
      payload.case_manager_fax = newBilling.case_manager_fax;
    }
    if (newBilling.case_manager_email !== dbBilling.case_manager_email) {
      payload.case_manager_email = newBilling.case_manager_email;
    }
    if (newBilling.case_manager_address !== dbBilling.case_manager_address) {
      payload.case_manager_address = newBilling.case_manager_address;
    }
    if (newBilling.type_of_accident !== dbBilling.type_of_accident) {
      payload.type_of_accident = newBilling.type_of_accident;
    }

    // If there is information to be updated, run the query
    if (Object.keys(payload).length !== 0) {
      // console.log(payload);
      udpatePip(record.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbBilling(newBilling);
          setEditBill(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    // Remove the billing record from state (for display)
    const updated = [];
    billings.map((item) => {
      if (item.id !== bill.id) {
        updated.push(item);
      }
    });

    // FETCH: Delete the Personal Injury record
    const payload = {
      date_removed: moment().format("MM/DD/YYYY"),
      isDeleted: true,
    };
    udpatePip(record.id, payload)
      .then((res) => res.json())
      .then((data) => {
        // FETCH: Delete the associated Billing record
        deleteBilling(bill.id)
          .then((res) => res.json())
          .then((data) => {
            setBillings(updated);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(newBilling, dbBilling) ? setCanUpdate(false) : setCanUpdate(true);
  }, [newBilling]);

  return (
    <div
      className="insurance-table-container"
      style={{ margin: "0", padding: "0" }}
    >
      {/* Personal Injury */}
      <div className="billing-header" style={{ marginTop: "30px" }}></div>
      <div className="insurance-table insurance-table-billing">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 className="table-label" style={{ marginLeft: "10px" }}>
            Personal Injury
          </h3>
          {isAdmin && isEditing && (
            <div style={{ marginLeft: "40px" }}>
              <button
                className="medication-edit-btn"
                id="bj-edit-btn"
                aria-label="edit"
                onClick={() => setEditBill(true)}
              >
                <Icon icon="typcn:edit" />
              </button>
              <button
                style={{ marginLeft: "40px" }}
                className="medication-delete-btn"
                id="bj-delete-btn"
                aria-label="delete"
                onClick={() => handleDelete()}
              >
                <Icon icon="bi:trash3" />
              </button>
            </div>
          )}
        </div>
        <hr style={{ borderTop: "1px solid #dacece", width: "100%" }}></hr>
        <div className="form-wrapper">
          <div className="form-inputs-billing">
            <div className="patient-form-group patient-form-group-billing">
              <label>Case Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="case_number"
                  name="case_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.case_number}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      case_number: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="case_number"
                  name="case_number"
                  type="text"
                  value={dbBilling.case_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Date of Injury</label>
              {isAdmin && editBill ? (
                <input
                  type="date"
                  id="date_of_injury"
                  name="date_of_injury"
                  className={isEditing ? "" : "read-only"}
                  value={moment(newBilling.date_of_injury).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      date_of_injury: moment(e.target.value).format(
                        "MM/DD/YYYY"
                      ),
                    })
                  }
                />
              ) : (
                <input
                  id="date_of_injury"
                  type="text"
                  value={dbBilling.date_of_injury}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Attorney Name</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="attorney_name"
                  name="attorney_name"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.attorney_name}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      attorney_name: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="attorney_name"
                  type="text"
                  value={dbBilling.attorney_name}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Attorney Phone Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="attorney_phone"
                  name="attorney_phone"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.attorney_phone}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setNewBilling({
                      ...newBilling,
                      attorney_phone: phone,
                    });
                  }}
                />
              ) : (
                <input
                  id="attorney_phone"
                  type="text"
                  value={dbBilling.attorney_phone}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Attorney Fax</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="attorney_fax"
                  name="attorney_fax"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.attorney_fax}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      attorney_fax: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="attorney_fax"
                  type="text"
                  value={dbBilling.attorney_fax}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Case Manager Name</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="case_manager_name"
                  name="case_manager_name"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.case_manager_name}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      case_manager_name: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="case_manager_name"
                  type="text"
                  value={dbBilling.case_manager_name}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Case Manager Phone</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="case_manager_phone"
                  name="case_manager_phone"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.case_manager_phone}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setNewBilling({
                      ...newBilling,
                      case_manager_phone: phone,
                    });
                  }}
                />
              ) : (
                <input
                  id="case_manager_phone"
                  type="text"
                  value={dbBilling.case_manager_phone}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Case Manager Fax</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="case_manager_fax"
                  name="case_manager_fax"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.case_manager_fax}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      case_manager_fax: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="case_manager_fax"
                  type="text"
                  value={dbBilling.case_manager_fax}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Case Manager Email</label>
              {isAdmin && editBill ? (
                <input
                  type="email"
                  id="case_manager_email"
                  name="case_manager_email"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.case_manager_email}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      case_manager_email: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="case_manager_email"
                  type="text"
                  value={dbBilling.case_manager_email}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Case Manager Address</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="case_manager_address"
                  name="case_manager_address"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.case_manager_address}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      case_manager_address: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="case_manager_address"
                  type="text"
                  value={dbBilling.case_manager_address}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Type of Accident</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="type_of_accident"
                  name="type_of_accident"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.type_of_accident}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      type_of_accident: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="type_of_accident"
                  type="text"
                  value={dbBilling.type_of_accident}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
          </div>
        </div>
        <div className="add-button-container">
          {isAdmin && editBill && (
            <button
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => {
                updateBilling();
              }}
              style={{ margin: "20px 0" }}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * CREATE PERSONAL INJURY BILLING TYPE
 * @param {postBilling} // Props from Patient Information page - creates the Billing & Oop record
 * @returns New Personal Injury Form
 */
const NewPip = ({ postBilling }) => {
  const [newInsurance, setNewInsuracne] = useState({
    case_number: "",
    date_of_injury: "",
    attorney_name: "",
    attorney_phone: "",
    attorney_fax: "",
    case_manager_name: "",
    case_manager_phone: "",
    case_manager_fax: "",
    case_manager_email: "",
    case_manager_address: "",
    type_of_accident: "",
  });

  return (
    <>
      <div
        style={{ margin: "10px 0", padding: "10px", border: "2px solid #bbb" }}
      >
        <div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="case_number">Case Number</label>
              <input
                id="case_number"
                name="case_number"
                type="text"
                value={newInsurance.case_number}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    case_number: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="date_of_injury">Date of Injury</label>
              <input
                id="date_of_injury"
                name="date_of_injury"
                type="date"
                style={{ width: "100%" }}
                value={moment(newInsurance.date_of_injury).format("YYYY-MM-DD")}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    date_of_injury: moment(e.target.value).format("MM/DD/YYYY"),
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="attorney_name">Attorney Name</label>
              <input
                id="attorney_name"
                name="attorney_name"
                type="text"
                value={newInsurance.attorney_name}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    attorney_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="attorney_phone">Attorney Phone Number</label>
              <input
                id="attorney_phone"
                name="attorney_phone"
                type="text"
                value={newInsurance.attorney_phone}
                onChange={(e) => {
                  let num = replaceSpecialChar(e.target.value);
                  const phone = formatPhone(num);

                  setNewInsuracne({ ...newInsurance, attorney_phone: phone });
                }}
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="attorney_fax">Attorney Fax</label>
              <input
                id="attorney_fax"
                name="attorney_fax"
                type="text"
                value={newInsurance.attorney_fax}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    attorney_fax: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="case_manager_name">Case Manager Name</label>
              <input
                id="case_manager_name"
                name="case_manager_name"
                type="text"
                value={newInsurance.case_manager_name}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    case_manager_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="case_manager_phone">Case Manager Phone</label>
              <input
                id="case_manager_phone"
                name="case_manager_phone"
                type="text"
                value={newInsurance.case_manager_phone}
                onChange={(e) => {
                  let num = replaceSpecialChar(e.target.value);
                  const phone = formatPhone(num);

                  setNewInsuracne({
                    ...newInsurance,
                    case_manager_phone: phone,
                  });
                }}
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="case_manager_fax">Case Manager Fax</label>
              <input
                id="case_manager_fax"
                name="case_manager_fax"
                type="text"
                value={newInsurance.case_manager_fax}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    case_manager_fax: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="case_manager_email">Case Manager Email</label>
              <input
                id="case_manager_email"
                name="case_manager_email"
                type="email"
                style={{ width: "100%", padding: "12px" }}
                value={newInsurance.case_manager_email}
                onChange={(e) => {
                  setNewInsuracne({
                    ...newInsurance,
                    case_manager_email: e.target.value,
                  });
                }}
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="case_manager_address">Case Manager Address</label>
              <input
                id="case_manager_address"
                name="case_manager_address"
                type="text"
                value={newInsurance.case_manager_address}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    case_manager_address: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="type_of_accident">Type of Accident</label>
              <input
                id="type_of_accident"
                name="type_of_accident"
                type="text"
                value={newInsurance.type_of_accident}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    type_of_accident: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <button
            className="medication-add-btn"
            onClick={() => {
              const billingPayload = {
                type: "Pip",
              };

              const insurancePayload = {
                ...newInsurance,
                date_created: moment().format("MM/DD/YYYY"),
              };

              postBilling("Pip", billingPayload, insurancePayload);
            }}
          >
            Add
          </button>
        </div>
      </div>
    </>
  );
};

/**
 * VIEW, EDIT & DELETE WORKERS COMP BILLING TYPE
 * @param {isAdmin} // Allows Admins to see "edit" & "delete" buttons
 * @param {isEditing} // State to control read-only prop on inputs
 * @param {billings} // Array of billing objects
 * @param {setBillings} // Prop from main Patient Information page - updates state holding billing records from the db
 * @param {bill} // Specific record for display
 * @returns Workers Comp record
 */
const WorkersComp = ({ isAdmin, isEditing, billings, setBillings, bill }) => {
  const record = bill.comps[0] || {};

  const [editBill, setEditBill] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const [dbBilling, setDbBilling] = useState({
    case_number: record.case_number,
    date_of_injury: record.date_of_injury,
    adjuster_name: record.adjuster_name,
    adjuster_phone: record.adjuster_phone,
    adjuster_fax: record.adjuster_fax,
    adjuster_email: record.adjuster_email,
    bill_review_address: record.bill_review_address,
    bill_review_phone: record.bill_review_phone,
  });
  const [newBilling, setNewBilling] = useState({
    case_number: record.case_number,
    date_of_injury: record.date_of_injury,
    adjuster_name: record.adjuster_name,
    adjuster_phone: record.adjuster_phone,
    adjuster_fax: record.adjuster_fax,
    adjuster_email: record.adjuster_email,
    bill_review_address: record.bill_review_address,
    bill_review_phone: record.bill_review_phone,
  });

  const updateBilling = () => {
    const payload = {};

    // Only update information in the db that has been changed
    if (newBilling.case_number !== dbBilling.case_number) {
      payload.case_number = newBilling.case_number;
    }
    if (newBilling.date_of_injury !== dbBilling.date_of_injury) {
      payload.date_of_injury = newBilling.date_of_injury;
    }
    if (newBilling.adjuster_name !== dbBilling.adjuster_name) {
      payload.adjuster_name = newBilling.adjuster_name;
    }
    if (newBilling.adjuster_phone !== dbBilling.adjuster_phone) {
      payload.adjuster_phone = newBilling.adjuster_phone;
    }
    if (newBilling.adjuster_fax !== dbBilling.adjuster_fax) {
      payload.adjuster_fax = newBilling.adjuster_fax;
    }
    if (newBilling.adjuster_email !== dbBilling.adjuster_email) {
      payload.adjuster_email = newBilling.adjuster_email;
    }
    if (newBilling.bill_review_address !== dbBilling.bill_review_address) {
      payload.bill_review_address = newBilling.bill_review_address;
    }
    if (newBilling.bill_review_phone !== dbBilling.bill_review_phone) {
      payload.bill_review_phone = newBilling.bill_review_phone;
    }

    // If there is information to be updated, run the query
    if (Object.keys(payload).length !== 0) {
      // console.log(payload);
      udpateComp(record.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbBilling(newBilling);
          setEditBill(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    // Remove the billing record from state (for display)
    const updated = [];
    billings.map((item) => {
      if (item.id !== bill.id) {
        updated.push(item);
      }
    });

    // FETCH: Delete the Worker's Comp record
    const payload = {
      date_removed: moment().format("MM/DD/YYYY"),
      isDeleted: true,
    };
    udpateComp(record.id, payload)
      .then((res) => res.json())
      .then((data) => {
        // FETCH: Delete the associated Billing record
        deleteBilling(bill.id)
          .then((res) => res.json())
          .then((data) => {
            setBillings(updated);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(newBilling, dbBilling) ? setCanUpdate(false) : setCanUpdate(true);
  }, [newBilling]);

  return (
    <div
      className="insurance-table-container"
      style={{ margin: "0", padding: "0" }}
    >
      {/* Workers Comp */}
      <div className="billing-header" style={{ marginTop: "30px" }}></div>
      <div className="insurance-table insurance-table-billing">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 className="table-label" style={{ marginLeft: "10px" }}>
            Workers Comp
          </h3>
          {isAdmin && isEditing && (
            <div style={{ marginLeft: "40px" }}>
              <button
                className="medication-edit-btn"
                id="bj-edit-btn"
                aria-label="edit"
                onClick={() => setEditBill(true)}
              >
                <Icon icon="typcn:edit" />
              </button>
              <button
                style={{ marginLeft: "40px" }}
                className="medication-delete-btn"
                id="bj-delete-btn"
                aria-label="delete"
                onClick={() => handleDelete()}
              >
                <Icon icon="bi:trash3" />
              </button>
            </div>
          )}
        </div>
        <hr style={{ borderTop: "1px solid #dacece", width: "100%" }}></hr>
        <div className="form-wrapper">
          <div className="form-inputs-billing">
            <div className="patient-form-group patient-form-group-billing">
              <label>Case Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="case_number"
                  name="case_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.case_number}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      case_number: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="case_number"
                  name="case_number"
                  type="text"
                  value={dbBilling.case_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Date of Injury</label>
              {isAdmin && editBill ? (
                <input
                  type="date"
                  id="date_of_injury"
                  name="date_of_injury"
                  className={isEditing ? "" : "read-only"}
                  value={moment(newBilling.date_of_injury).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      date_of_injury: moment(e.target.value).format(
                        "MM/DD/YYYY"
                      ),
                    })
                  }
                />
              ) : (
                <input
                  id="date_of_injury"
                  type="text"
                  value={dbBilling.date_of_injury}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Adjuster Name</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="adjuster_name"
                  name="adjuster_name"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.adjuster_name}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      adjuster_name: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="adjuster_name"
                  type="text"
                  value={dbBilling.adjuster_name}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Adjuster Phone Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="adjuster_phone"
                  name="adjuster_phone"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.adjuster_phone}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setNewBilling({
                      ...newBilling,
                      adjuster_phone: phone,
                    });
                  }}
                />
              ) : (
                <input
                  id="adjuster_phone"
                  type="text"
                  value={dbBilling.adjuster_phone}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Adjuster Fax</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="adjuster_fax"
                  name="adjuster_fax"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.adjuster_fax}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      adjuster_fax: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="adjuster_fax"
                  type="text"
                  value={dbBilling.adjuster_fax}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Adjuster Email</label>
              {isAdmin && editBill ? (
                <input
                  type="email"
                  id="adjuster_email"
                  name="adjuster_email"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.adjuster_email}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      adjuster_email: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="adjuster_email"
                  type="text"
                  value={dbBilling.adjuster_email}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Billing Review Phone</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="bill_review_phone"
                  name="bill_review_phone"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.bill_review_phone}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setNewBilling({
                      ...newBilling,
                      bill_review_phone: phone,
                    });
                  }}
                />
              ) : (
                <input
                  id="bill_review_phone"
                  type="text"
                  value={dbBilling.bill_review_phone}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Billing Review Address</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="bill_review_address"
                  name="bill_review_address"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.bill_review_address}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      bill_review_address: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="bill_review_address"
                  type="text"
                  value={dbBilling.bill_review_address}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
          </div>
        </div>
        <div className="add-button-container">
          {isAdmin && editBill && (
            <button
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => {
                updateBilling();
              }}
              style={{ margin: "20px 0" }}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * CREATE WORKERS COMP BILLING TYPE
 * @param {postBilling} // Props from Patient Information page - creates the Billing & Oop record
 * @returns New Worker's Comp Form
 */
const NewComp = ({ postBilling }) => {
  const [newInsurance, setNewInsuracne] = useState({
    case_number: "",
    date_of_injury: "",
    adjuster_name: "",
    adjuster_phone: "",
    adjuster_fax: "",
    adjuster_email: "",
    bill_review_address: "",
    bill_review_phone: "",
  });

  return (
    <>
      <div
        style={{ margin: "10px 0", padding: "10px", border: "2px solid #bbb" }}
      >
        <div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="case_number">Case Number</label>
              <input
                id="case_number"
                name="case_number"
                type="text"
                value={newInsurance.case_number}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    case_number: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="date_of_injury">Date of Injury</label>
              <input
                id="date_of_injury"
                name="date_of_injury"
                type="date"
                style={{ width: "100%" }}
                value={moment(newInsurance.date_of_injury).format("YYYY-MM-DD")}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    date_of_injury: moment(e.target.value).format("MM/DD/YYYY"),
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="adjuster_name">Adjuster Name</label>
              <input
                id="adjuster_name"
                name="adjuster_name"
                type="text"
                value={newInsurance.adjuster_name}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    adjuster_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="adjuster_phone">Adjuster Phone Number</label>
              <input
                id="adjuster_phone"
                name="adjuster_phone"
                type="text"
                value={newInsurance.adjuster_phone}
                onChange={(e) => {
                  let num = replaceSpecialChar(e.target.value);
                  const phone = formatPhone(num);

                  setNewInsuracne({ ...newInsurance, adjuster_phone: phone });
                }}
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="adjuster_fax">Adjuster Fax</label>
              <input
                id="adjuster_fax"
                name="adjuster_fax"
                type="text"
                value={newInsurance.adjuster_fax}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    adjuster_fax: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="adjuster_email">Adjuster Email</label>
              <input
                id="adjuster_email"
                name="adjuster_email"
                type="email"
                value={newInsurance.adjuster_email}
                style={{ width: "100%", padding: "12px" }}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    adjuster_email: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="bill_review_phone">Billing Review Phone</label>
              <input
                id="bill_review_phone"
                name="bill_review_phone"
                type="text"
                value={newInsurance.bill_review_phone}
                onChange={(e) => {
                  let num = replaceSpecialChar(e.target.value);
                  const phone = formatPhone(num);

                  setNewInsuracne({
                    ...newInsurance,
                    bill_review_phone: phone,
                  });
                }}
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="bill_review_address">
                Billing Review Address
              </label>
              <input
                id="bill_review_address"
                name="bill_review_address"
                type="text"
                value={newInsurance.bill_review_address}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    bill_review_address: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <button
            className="medication-add-btn"
            onClick={() => {
              const billingPayload = {
                type: "Comp",
              };

              const insurancePayload = {
                ...newInsurance,
                date_created: moment().format("MM/DD/YYYY"),
              };

              postBilling("Comp", billingPayload, insurancePayload);
            }}
          >
            Add
          </button>
        </div>
      </div>
    </>
  );
};

/**
 * VIEW, EDIT & DELETE COMMERCIAL INSURANCE BILLING TYPE
 * @param {isAdmin} // Allows Admins to see "edit" & "delete" buttons
 * @param {isEditing} // State to control read-only prop on inputs
 * @param {billings} // Array of billing objects
 * @param {setBillings} // Prop from main Patient Information page - updates state holding billing records from the db
 * @param {bill} // Specific record for display
 * @returns Commercial Insurance record
 */
const CommercialInsurance = ({
  isAdmin,
  isEditing,
  billings,
  setBillings,
  bill,
}) => {
  const record = bill.comInsurances[0] || {};

  const [editBill, setEditBill] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const [dbBilling, setDbBilling] = useState({
    provider: record.provider,
    policy_number: record.policy_number,
    group_number: record.group_number,
    policy_through_employer: record.policy_through_employer,
    name_on_insurance: record.name_on_insurance,
    relation_to_patient: record.relation_to_patient,
    address: record.address,
    phone_number: record.phone_number,
    id_number: record.id_number,
    effective_date: record.effective_date,
  });
  const [newBilling, setNewBilling] = useState({
    provider: record.provider,
    policy_number: record.policy_number,
    group_number: record.group_number,
    policy_through_employer: record.policy_through_employer,
    name_on_insurance: record.name_on_insurance,
    relation_to_patient: record.relation_to_patient,
    address: record.address,
    phone_number: record.phone_number,
    id_number: record.id_number,
    effective_date: record.effective_date,
  });

  const updateBilling = () => {
    const payload = {};

    // Only update information in the db that has been changed
    if (newBilling.provider !== dbBilling.provider) {
      payload.provider = newBilling.provider;
    }
    if (newBilling.policy_number !== dbBilling.policy_number) {
      payload.policy_number = newBilling.policy_number;
    }
    if (newBilling.group_number !== dbBilling.group_number) {
      payload.group_number = newBilling.group_number;
    }
    if (
      newBilling.policy_through_employer !== dbBilling.policy_through_employer
    ) {
      payload.policy_through_employer = newBilling.policy_through_employer;
    }
    if (newBilling.name_on_insurance !== dbBilling.name_on_insurance) {
      payload.name_on_insurance = newBilling.name_on_insurance;
    }
    if (newBilling.relation_to_patient !== dbBilling.relation_to_patient) {
      payload.relation_to_patient = newBilling.relation_to_patient;
    }
    if (newBilling.address !== dbBilling.address) {
      payload.address = newBilling.address;
    }
    if (newBilling.phone_number !== dbBilling.phone_number) {
      payload.phone_number = newBilling.phone_number;
    }
    if (newBilling.id_number !== dbBilling.id_number) {
      payload.id_number = newBilling.id_number;
    }
    if (newBilling.effective_date !== dbBilling.effective_date) {
      payload.effective_date = newBilling.effective_date;
    }

    // If there is information to be updated, run the query
    if (Object.keys(payload).length !== 0) {
      // console.log(payload);
      udpateCommercial(record.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbBilling(newBilling);
          setEditBill(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    // Remove the billing record from state (for display)
    const updated = [];
    billings.map((item) => {
      if (item.id !== bill.id) {
        updated.push(item);
      }
    });

    // FETCH: Delete the Commercial Insurance record
    const payload = {
      date_removed: moment().format("MM/DD/YYYY"),
      isDeleted: true,
    };
    udpateCommercial(record.id, payload)
      .then((res) => res.json())
      .then((data) => {
        // FETCH: Delete the associated Billing record
        deleteBilling(bill.id)
          .then((res) => res.json())
          .then((data) => {
            setBillings(updated);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(newBilling, dbBilling) ? setCanUpdate(false) : setCanUpdate(true);
  }, [newBilling]);

  return (
    <div
      className="insurance-table-container"
      style={{ margin: "0", padding: "0" }}
    >
      {/* Commerical Insurance */}
      <div className="billing-header" style={{ marginTop: "30px" }}></div>
      <div className="insurance-table insurance-table-billing">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 className="table-label" style={{ marginLeft: "10px" }}>
            Commerical Insurance
          </h3>
          {isAdmin && isEditing && (
            <div style={{ marginLeft: "40px" }}>
              <button
                className="medication-edit-btn"
                id="bj-edit-btn"
                aria-label="edit"
                onClick={() => setEditBill(true)}
              >
                <Icon icon="typcn:edit" />
              </button>
              <button
                style={{ marginLeft: "40px" }}
                className="medication-delete-btn"
                id="bj-delete-btn"
                aria-label="delete"
                onClick={() => handleDelete()}
              >
                <Icon icon="bi:trash3" />
              </button>
            </div>
          )}
        </div>
        <hr style={{ borderTop: "1px solid #dacece", width: "100%" }}></hr>
        <div className="form-wrapper">
          <div className="form-inputs-billing">
            <div className="patient-form-group patient-form-group-billing">
              <label>Provider</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="provider"
                  name="provider"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.provider}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      provider: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="provider"
                  name="provider"
                  type="text"
                  value={dbBilling.provider}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Policy Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="policy_number"
                  name="policy_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.policy_number}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      policy_number: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="policy_number"
                  type="text"
                  value={dbBilling.policy_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Group Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="group_number"
                  name="group_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.group_number}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      group_number: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="group_number"
                  type="text"
                  value={dbBilling.group_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Policy through Employer</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="policy_through_employer"
                  name="policy_through_employer"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.policy_through_employer}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      policy_through_employer: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="policy_through_employer"
                  type="text"
                  value={dbBilling.policy_through_employer}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Name on Insurance</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="name_on_insurance"
                  name="name_on_insurance"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.name_on_insurance}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      name_on_insurance: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="name_on_insurance"
                  type="text"
                  value={dbBilling.name_on_insurance}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Relation to Patient</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="relation_to_patient"
                  name="relation_to_patient"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.relation_to_patient}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      relation_to_patient: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="relation_to_patient"
                  type="text"
                  value={dbBilling.relation_to_patient}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Address</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="address"
                  name="address"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.address}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      address: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="address"
                  type="text"
                  value={dbBilling.address}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Phone Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.phone_number}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setNewBilling({
                      ...newBilling,
                      phone_number: phone,
                    });
                  }}
                />
              ) : (
                <input
                  id="phone_number"
                  type="text"
                  value={dbBilling.phone_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>Effective Date</label>
              {isAdmin && editBill ? (
                <input
                  type="date"
                  id="effective_date"
                  name="effective_date"
                  className={isEditing ? "" : "read-only"}
                  value={moment(newBilling.effective_date).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      effective_date: moment(e.target.value).format(
                        "MM/DD/YYYY"
                      ),
                    })
                  }
                />
              ) : (
                <input
                  id="effective_date"
                  type="text"
                  value={dbBilling.effective_date}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
            <div className="patient-form-group patient-form-group-billing">
              <label>ID Number</label>
              {isAdmin && editBill ? (
                <input
                  type="text"
                  id="id_number"
                  name="id_number"
                  className={isEditing ? "" : "read-only"}
                  value={newBilling.id_number}
                  onChange={(e) =>
                    setNewBilling({
                      ...newBilling,
                      id_number: e.target.value,
                    })
                  }
                />
              ) : (
                <input
                  id="id_number"
                  type="text"
                  value={dbBilling.id_number}
                  readOnly
                  className="read-only"
                />
              )}
            </div>
          </div>
        </div>
        <div className="add-button-container">
          {isAdmin && editBill && (
            <button
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => {
                updateBilling();
              }}
              style={{ margin: "20px 0" }}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * CREATE COMMERCIAL INSURANCE BILLING TYPE
 * @param {postBilling} // Props from Patient Information page - creates the Billing & ComInsurance record
 * @returns New Commercial Insurance Form
 */
const NewComInsurance = ({ postBilling }) => {
  const [newInsurance, setNewInsuracne] = useState({
    provider: "",
    policy_number: "",
    group_number: "",
    policy_through_employer: "",
    name_on_insurance: "",
    relation_to_patient: "",
    address: "",
    phone_number: "",
    id_number: "",
    effective_date: "",
  });

  return (
    <>
      <div
        style={{ margin: "10px 0", padding: "10px", border: "2px solid #bbb" }}
      >
        <div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="provider">Provider</label>
              <input
                id="provider"
                name="provider"
                type="text"
                value={newInsurance.provider}
                onChange={(e) =>
                  setNewInsuracne({ ...newInsurance, provider: e.target.value })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="effective-date">Effective Date</label>
              <input
                id="effective-date"
                name="effective-date"
                type="date"
                style={{ width: "100%" }}
                value={moment(newInsurance.effective_date).format("YYYY-MM-DD")}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    effective_date: moment(e.target.value).format("MM/DD/YYYY"),
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="policy-number">Policy Number</label>
              <input
                id="policy-number"
                name="policy-number"
                type="text"
                value={newInsurance.policy_number}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    policy_number: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="group-number">Group Number</label>
              <input
                id="group-number"
                name="group-number"
                type="text"
                value={newInsurance.group_number}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    group_number: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="employer-policy">Policy through Employer</label>
              <input
                id="employer-policy"
                name="employer-policy"
                type="text"
                value={newInsurance.policy_through_employer}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    policy_through_employer: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="insurance-name">Name on Insurance</label>
              <input
                id="insurance-name"
                name="insurance-name"
                type="text"
                value={newInsurance.name_on_insurance}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    name_on_insurance: e.target.value,
                  })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="patient-relation">Relationship to Patient</label>
              <input
                id="patient-relation"
                name="patient-relation"
                type="text"
                value={newInsurance.relation_to_patient}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    relation_to_patient: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="patient-form-row insurance-row">
            <div className="patient-form-group">
              <label htmlFor="insurance-address">Address</label>
              <input
                id="insurance-address"
                name="insurance-address"
                type="text"
                value={newInsurance.address}
                onChange={(e) =>
                  setNewInsuracne({ ...newInsurance, address: e.target.value })
                }
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="insurance-phone">Phone Number</label>
              <input
                id="insurance-phone"
                name="insurance-phone"
                type="text"
                value={newInsurance.insurancePhone}
                onChange={(e) => {
                  let num = replaceSpecialChar(e.target.value);
                  const phone = formatPhone(num);

                  setNewInsuracne({ ...newInsurance, phone_number: phone });
                }}
              />
            </div>
            <div className="patient-form-group">
              <label htmlFor="insurance-id">ID Number</label>
              <input
                id="insurance-id"
                name="insurance-id"
                type="text"
                value={newInsurance.id_number}
                onChange={(e) =>
                  setNewInsuracne({
                    ...newInsurance,
                    id_number: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <button
            className="medication-add-btn"
            onClick={() => {
              const billingPayload = {
                type: "Commercial",
              };

              const insurancePayload = {
                ...newInsurance,
                date_created: moment().format("MM/DD/YYYY"),
              };

              postBilling("Commercial", billingPayload, insurancePayload);
            }}
          >
            Add
          </button>
        </div>
      </div>
    </>
  );
};

export default Billing;
