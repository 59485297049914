import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import placeholder from "../../assets/images/guest.webp";
import BackgroundImage from "../BackgroundImage/index";
import { getPatientById } from "../../utils/api";
// Stylesheets
import "../../pages/User/PatientFolder/patientFolder.css";
import "../../pages/User/Menu/menu.css";

const PatientFolderHeader = () => {
  const location = useLocation();
  // Get the patient ID from the URL parameters
  const { id } = useParams();
  const [patient, setPatient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch patient data by ID
  useEffect(() => {
    setIsLoading(true);
    getPatientById(id)
      .then((res) => res.json())
      .then((data) => {
        setPatient(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [id]);

  if (isLoading || !patient) {
    return (
      <div className="patient-grid-container">
        <div className="patient-first-column"></div>
        <div className="patient-second-column">
          <BackgroundImage />
          <div className="second-column-item-heading patient-heading box-shadow">
            <div className="patient-info-container">
              <div className="patient-image">
                <img
                  src={placeholder}
                  className="patient-picture"
                  alt="Patient"
                />
              </div>
              <div className="patient-name-medical">
                <h1>Loading patient data...</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Main container with a grid layout */}
      <div className="patient-grid-container">
        <div className="patient-first-column"></div>
        <div className="patient-second-column">
          <BackgroundImage />
          <div className="second-column-item-heading patient-heading box-shadow border-none">
            <div className="patient-info-container">
              <div className="patient-image">
                <img
                  src={placeholder}
                  className="patient-picture"
                  alt="Patient"
                />
              </div>
              <div className="patient-name-medical">
                {/* first and last name */}
                <h1>
                  {patient.first_name} {patient.last_name}
                </h1>
                {/* MRN */}
                <p>Medical Record Number: {patient.medical_record_number}</p>
              </div>
            </div>
            <div className="patient-phone-dob-container">
              <div className="patient-phone-dob">
                {/* Phone number */}
                <label>Phone Number</label>
                <p style={{ marginTop: "7px" }}>{patient.phone_number}</p>
              </div>
              <div className="patient-phone-dob">
                {/* DOB */}
                <label>Date of Birth</label>
                <p style={{ marginTop: "7px" }}>
                  {new Date(patient.birthday).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
          <div className="patient-second-column-item-links box-shadow">
            <div className="menu-items-patient">
              <Link
                to={`/patient/${id}`}
                className={`patient-tabs ${
                  location.pathname === `/patient/${id}` ||
                  location.pathname.includes("surgery")
                    ? "active"
                    : ""
                }`}
              >
                Surgeries/Procedures
              </Link>
              <Link
                to={`/patient-information/${id}`}
                className={`patient-tabs ${
                  location.pathname === `/patient-information/${id}`
                    ? "active"
                    : ""
                }`}
              >
                Patient Information
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientFolderHeader;
