import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getPatientRegistration,
  updatePatientRegistration,
} from "../../../utils/api";

const PatientRegistration = () => {
  const { form_id } = useParams();
  const navigate = useNavigate();
  const [individualSignature, setIndividualSignature] = useState("");
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    date_1: moment().format("MM/DD/YYYY"),
    home_phone: "",
    work_phone: "",
    email: "",
    last_name: "",
    first_name: "",
    middle_initial: "",
    street_address: "",
    city: "",
    state: "",
    zip: "",
    age_1: "",
    male: "",
    female: "",
    signature_date: "",
    birth_date: "",
    social_security: "",
    drivers_license: "",
    insured_name: "",
    insured_initial: "",
    referral: "",
    condition_related_to: "",
    condition_related_to_text: "",
    company_name: "",
    occupation: "",
    company_address: "",
    company_number: "",
    full_time: "",
    part_time: "",
    company_city: "",
    company_state: "",
    company_zip: "",
    years_employed: "",
    relation_to_insured: "",
    relation_to_insured_text: "",

    spouse_name: "",
    spouse_initial: "",
    spouse_birth: "",
    spouse_ssn: "",
    spouse_address: "",
    spouse_city: "",
    spouse_state: "",
    spouse_zip: "",
    spouse_phone: "",
    insurance_company: "",
    group_number: "",
    effective_date: "",
    name_of_insured: "",
    insurance_id: "",
    liable_yes: "",
    liable_no: "",
    liable_initials: "",
    pregnant_yes: "",
    pregnant_no: "",
    pacemaker_yes: "",
    pacemaker_no: "",
    family_physician: "",
    emergency_contact: "",
    attorney: "",
    attorney_phone: "",
    attorney_address: "",
    marital_status: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Before Submission:", formData);

    let fileName;

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      fileName = await data.fileName;
    }

    const payload = {
      date_1: moment().format("MM/DD/YYYY"),
      home_phone: formData.home_phone,
      work_phone: formData.work_phone,
      email: formData.email,
      last_name: formData.last_name,
      first_name: formData.first_name,
      middle_initial: formData.middle_initial,
      street_address: formData.street_address,
      city: formData.city,
      state: formData.state,
      age_1: formData.age_1,
      male: formData.male,
      female: formData.female,
      relation_to_insured: formData.relation_to_insured,
      relation_to_insured_text: formData.relation_to_insured_text,
      marital_status: formData.marital_status,
      birth_date: moment().format("MM/DD/YYYY"),
      zip: formData.zip,
      signature_date: moment().format("MM/DD/YYYY"),
      patient_signature: fileName,
      social_security: formData.social_security,
      drivers_license: formData.drivers_license,
      insured_name: formData.insured_name,
      insured_initial: formData.insured_initial,
      referral: formData.referral,
      condition_related_to: formData.condition_related_to,
      condition_related_to_text: formData.condition_related_to_text,
      company_name: formData.company_name,
      occupation: formData.occupation,
      company_address: formData.company_address,
      company_number: formData.company_number,
      full_time: formData.full_time,
      part_time: formData.part_time,
      company_city: formData.company_city,
      company_state: formData.company_state,
      company_zip: formData.company_zip,
      years_employed: formData.years_employed,

      spouse_name: formData.spouse_name,
      spouse_initial: formData.spouse_initial,
      spouse_birth: formData.spouse_birth,
      spouse_ssn: formData.spouse_ssn,
      spouse_address: formData.spouse_address,
      spouse_city: formData.spouse_city,
      spouse_state: formData.spouse_state,
      spouse_zip: formData.spouse_zip,
      spouse_phone: formData.spouse_phone,
      insurance_company: formData.insurance_company,
      group_number: formData.group_number,
      effective_date: moment().format("MM/DD/YYYY"),
      name_of_insured: formData.name_of_insured,
      insurance_id: formData.insurance_id,
      liable_yes: formData.liable_yes,
      liable_no: formData.liable_no,
      liable_initials: formData.liable_initials,
      pregnant_yes: formData.pregnant_yes,
      pregnant_no: formData.pregnant_no,
      pacemaker_yes: formData.pacemaker_yes,
      pacemaker_no: formData.pacemaker_no,
      family_physician: formData.family_physician,
      emergency_contact: formData.emergency_contact,
      attorney: formData.attorney,
      attorney_phone: formData.attorney_phone,
      attorney_address: formData.attorney_address,

      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };
    console.log("Payload sent to backend:", payload);

    console.log(payload);

    // Fetch 2: ≈ Disclosure of Ownerhsip Model
    updatePatientRegistration(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPatientRegistration(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            ...data, 
          }));
        }

        if (data.status !== "Not Started") {
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <div className="form-content">
        <form onSubmit={(e) => handleSubmit(e)}>
          <h2 className="form-title">Patient Registration</h2>
          <div className="pr-form-container">
            <div className="pr-form-row">
              <label>Date</label>
              <input
                type="date"
                className="pr-input-line"
                value={formData.date_1}
                onChange={(e) =>
                  setFormData({ ...formData, date_1: e.target.value })
                }
              />
              <label>Home Phone</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.home_phone}
                onChange={(e) =>
                  setFormData({ ...formData, home_phone: e.target.value })
                }
              />
              <label>Work Phone</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.work_phone}
                onChange={(e) =>
                  setFormData({ ...formData, work_phone: e.target.value })
                }
              />
              <label>Email</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>
            <div className="pr-form-row">
              <label>Patient: Last Name</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.last_name}
                onChange={(e) =>
                  setFormData({ ...formData, last_name: e.target.value })
                }
              />
              <label>First Name</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.first_name}
                onChange={(e) =>
                  setFormData({ ...formData, first_name: e.target.value })
                }
              />
              <label>Middle Initial</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.middle_initial}
                onChange={(e) =>
                  setFormData({ ...formData, middle_initial: e.target.value })
                }
              />
            </div>
            <div className="pr-form-row">
              <label>Street Address</label>
              <input
                type="text"
                className="pr-input-long-line"
                value={formData.street_address}
                onChange={(e) =>
                  setFormData({ ...formData, street_address: e.target.value })
                }
              />
            </div>
            <div className="pr-form-row">
              <label>City</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.city}
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
              />
              <label>State</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.state}
                onChange={(e) =>
                  setFormData({ ...formData, state: e.target.value })
                }
              />
              <label>Zip</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.zip}
                onChange={(e) =>
                  setFormData({ ...formData, zip: e.target.value })
                }
              />
            </div>
            <div className="pr-form-row">
              <label>Sex</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="pr-sex"
                    value="male"
                    checked={formData.male}
                    onChange={() =>
                      setFormData({ ...formData, male: true, female: false })
                    }
                  />
                  M
                  <input
                    type="radio"
                    name="pr-sex"
                    value="female"
                    checked={formData.female}
                    onChange={() =>
                      setFormData({ ...formData, male: false, female: true })
                    }
                  />
                  F
                </label>
              </div>
              <label>Age</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.age_1}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, age_1: e.target.value }))
                }
              />
              <label>Birth date</label>
              <input
                type="date"
                className="pr-input-line"
                value={formData.birth_date}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    birth_date: e.target.value,
                  }))
                }
              />
            </div>
            <div className="pr-form-row">
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="pr-status"
                    value="single"
                    checked={formData.marital_status === "single"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        marital_status: e.target.value,
                      })
                    }
                  />
                  Single
                </label>
                <label>
                  <input
                    type="radio"
                    name="pr-status"
                    value="married"
                    checked={formData.marital_status === "married"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        marital_status: e.target.value,
                      })
                    }
                  />
                  Married
                </label>
                <label>
                  <input
                    type="radio"
                    name="pr-status"
                    value="widowed"
                    checked={formData.marital_status === "widowed"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        marital_status: e.target.value,
                      })
                    }
                  />
                  Widowed
                </label>
                <label>
                  <input
                    type="radio"
                    name="pr-status"
                    value="separated"
                    checked={formData.marital_status === "separated"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        marital_status: e.target.value,
                      })
                    }
                  />
                  Separated
                </label>
                <label>
                  <input
                    type="radio"
                    name="pr-status"
                    value="divorced"
                    checked={formData.marital_status === "divorced"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        marital_status: e.target.value,
                      })
                    }
                  />
                  Divorced
                </label>
              </div>
            </div>

            <div className="pr-form-row">
              <label>Social Security #</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.social_security}
                onChange={(e) =>
                  setFormData({ ...formData, social_security: e.target.value })
                }
              />
              <label>Driver's License #</label>
              <input
                type="text"
                className="pr-input-line"
                value={formData.drivers_license}
                onChange={(e) =>
                  setFormData({ ...formData, drivers_license: e.target.value })
                }
              />
            </div>
            <div className="pr-form-row">
              <label>Insured Name</label>
              <input
                type="text"
                className="pr-input-long-line"
                placeholder="Last Name First Name"
                value={formData.insured_name}
                onChange={(e) =>
                  setFormData({ ...formData, insured_name: e.target.value })
                }
              />
           
              <input
                type="text"
                className="pr-input-line"
                placeholder="Initial"
                value={formData.insured_initial}
                onChange={(e) =>
                  setFormData({ ...formData, insured_initial: e.target.value })
                }
              />
              <label>How and where did you learn about this clinic?</label>
              <input
                type="text"
                className="pr-input-long-line"
                value={formData.referral}
                onChange={(e) =>
                  setFormData({ ...formData, referral: e.target.value })
                }
              />
            </div>

            <div className="pr-form-row">
              <label>Relationship to Insured:</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="pr-relationship"
                    value="self"
                    checked={formData.relation_to_insured === "self"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        relation_to_insured: e.target.value,
                      })
                    }
                  />{" "}
                  Self
                </label>
                <label>
                  <input
                    type="radio"
                    name="pr-relationship"
                    value="spouse"
                    checked={formData.relation_to_insured === "spouse"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        relation_to_insured: e.target.value,
                      })
                    }
                  />{" "}
                  Spouse
                </label>
                <label>
                  <input
                    type="radio"
                    name="pr-relationship"
                    value="child"
                    checked={formData.relation_to_insured === "child"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        relation_to_insured: e.target.value,
                      })
                    }
                  />{" "}
                  Child
                </label>
                <label>
                  <input
                    type="radio"
                    name="pr-relationship"
                    value="other"
                    checked={formData.relation_to_insured === "other"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        relation_to_insured: e.target.value,
                      })
                    }
                  />{" "}
                  Other
                  <input
                    type="text"
                    className="pr-input-long-line"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        relation_to_insured_text: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
            </div>

            <div className="pr-form-row">
              <label>Condition/Illness Related To:</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="pr-condition"
                    value="illness"
                    checked={formData.condition_related_to === "illness"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        condition_related_to: e.target.value,
                      })
                    }
                  />
                  Illness
                </label>
                <label>
                  <input
                    type="radio"
                    name="pr-condition"
                    value="employment"
                    checked={formData.condition_related_to === "employment"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        condition_related_to: e.target.value,
                      })
                    }
                  />{" "}
                  Employment
                </label>
                <label>
                  <input
                    type="radio"
                    name="pr-condition"
                    value="auto"
                    checked={formData.condition_related_to === "auto"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        condition_related_to: e.target.value,
                      })
                    }
                  />{" "}
                  Auto
                </label>
                <label>
                  <input
                    type="radio"
                    name="pr-condition"
                    value="other"
                    checked={formData.condition_related_to === "other"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        condition_related_to: e.target.value,
                      })
                    }
                  />{" "}
                  Other
                  <input
                    type="text"
                    className="pr-input-long-line"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        condition_related_to_text: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="pr-container">
            <div className="pr-row">
              <div className="pr-col-20">
                <strong>INSURED EMPLOYER </strong>
              </div>
              <div className="pr-col-80">
                <div className="pr-row ie-row">
                  <label>Company Name</label>
                  <input
                    type="text"
                    className="employment-input"
                    value={formData.company_name}
                    onChange={(e) =>
                      setFormData({ ...formData, company_name: e.target.value })
                    }
                  />
                  <label>Occupation</label>
                  <input
                    type="text"
                    className="employment-input"
                    value={formData.occupation}
                    onChange={(e) =>
                      setFormData({ ...formData, occupation: e.target.value })
                    }
                  />
                </div>
                <div className="pr-row ie-row">
                  <label>Address</label>
                  <input
                    type="text"
                    className="employment-input-long"
                    value={formData.company_address}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        company_address: e.target.value,
                      })
                    }
                  />
                  <label>Phone</label>
                  <input
                    type="text"
                    className="employment-input"
                    value={formData.company_number}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        company_number: e.target.value,
                      })
                    }
                  />
                  <label>
                    <input
                      type="radio"
                      name="employment-status"
                      value={formData.full_time}
                      checked={formData.full_time}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          full_time: true,
                          part_time: false,
                        })
                      }
                    />{" "}
                    Full-time
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="employment-status"
                      value={formData.part_time}
                      checked={formData.part_time}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          part_time: true,
                          full_time: false,
                        })
                      }
                    />{" "}
                    Part-time
                  </label>
                </div>
                <div className="pr-row ie-row">
                  <label>City</label>
                  <input
                    type="text"
                    className="employment-input "
                    value={formData.company_city}
                    onChange={(e) =>
                      setFormData({ ...formData, company_city: e.target.value })
                    }
                  />
                  <label>State</label>
                  <input
                    type="text"
                    className="employment-input ie-short-input"
                    value={formData.company_state}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        company_state: e.target.value,
                      })
                    }
                  />
                  <label>Zip</label>
                  <input
                    type="text"
                    className="employment-input ie-short-input"
                    value={formData.company_zip}
                    onChange={(e) =>
                      setFormData({ ...formData, company_zip: e.target.value })
                    }
                  />
                  <label>Years Employed</label>
                  <input
                    type="text"
                    className="employment-input ie-short-input"
                    value={formData.years_employed}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        years_employed: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="pr-row">
              <div className="pr-col-20">
                <strong>SPOUSE(PARENT) </strong>
              </div>
              <div className="pr-col-80">
                <div className="employment-form">
                  <div className="employment-row">
                    <label>Name</label>
                    <input
                      type="text"
                      className="employment-input"
                      placeholder="Last Name First Name"
                      value={formData.spouse_name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          spouse_name: e.target.value,
                        })
                      }
                    />
                
                    <input
                      type="text"
                      className="employment-input"
                      placeholder="Initial"
                      value={formData.spouse_initial}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          spouse_initial: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="employment-row">
                    <label>Birth Date</label>
                    <input
                      type="text"
                      className="employment-input"
                      value={formData.spouse_birth}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          spouse_birth: e.target.value,
                        })
                      }
                    />
                    <label>SSN:</label>
                    <input
                      type="text"
                      className="employment-input"
                      value={formData.spouse_ssn}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          spouse_ssn: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="employment-row">
                    <label>Address</label>
                    <input
                      type="text"
                      className="employment-input-long"
                      value={formData.spouse_address}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          spouse_address: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="employment-row">
                    <label>City</label>
                    <input
                      type="text"
                      className="employment-input"
                      value={formData.spouse_city}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          spouse_city: e.target.value,
                        })
                      }
                    />
                    <label>State</label>
                    <input
                      type="text"
                      className="employment-input"
                      value={formData.spouse_state}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          spouse_state: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="employment-row">
                    <label>Zip</label>
                    <input
                      type="text"
                      className="employment-input"
                      value={formData.spouse_zip}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          spouse_zip: e.target.value,
                        })
                      }
                    />
                    <label>Phone</label>
                    <input
                      type="text"
                      className="employment-input"
                      value={formData.spouse_phone}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          spouse_phone: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pr-row">
              <div className="pr-col-20">
                <strong>PATIENT INSURANCE INFORMATION </strong>
              </div>
              <div className="pr-col-80">
                <div className="employment-form">
                  <p className="ef-text">
                    Please list any and all insurance and/or employee health
                    care plan coverage you or your spouse may have
                  </p>
                  <div className="employment-row">
                    <label>Insurance Company or Health Care Plan Name</label>
                    <input
                      type="text"
                      className="employment-input-long"
                      value={formData.insurance_company}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          insurance_company: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="employment-row">
                    <label>Policy/Group #</label>
                    <input
                      type="text"
                      className="employment-input"
                      value={formData.group_number}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          group_number: e.target.value,
                        })
                      }
                    />
                    <label>Effective Date</label>
                    <input
                      type="date"
                      className="employment-input"
                      value={formData.effective_date}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          effective_date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="employment-row">
                    <label>Name of Insured</label>
                    <input
                      type="text"
                      className="employment-input-long"
                      value={formData.name_of_insured}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          name_of_insured: e.target.value,
                        })
                      }
                    />
                    <label>ID #</label>
                    <input
                      type="text"
                      className="employment-input"
                      value={formData.insurance_id}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          insurance_id: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pr-row">
              <div className="pr-col-20">
                <strong>MEDICAL AND LEGAL INFORMATION </strong>
              </div>
              <div className="pr-col-80">
                <div className="employment-form">
                  <p className="ef-text">
                    <strong>
                      Are your present symptoms or conditions related to or the
                      result of an auto accident, work-related injury or other
                      personal injury{" "}
                      <u>someone else might be legally liable for?</u>
                    </strong>
                  </p>
                  <label>
                    <input
                      type="radio"
                      name="accident-related"
                      value="liable_yes"
                      checked={formData.liable_yes}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          liable_yes: true,
                          liable_no: false,
                        })
                      }
                    />{" "}
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="accident-related"
                      value="liable_no"
                      checked={formData.liable_no}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          liable_no: true,
                          liable_yes: false,
                        })
                      }
                    />{" "}
                    No
                  </label>
                  <label> Your Initials:</label>
                  <input
                    type="text"
                    className="insurance-input-short"
                    value={formData.liable_initials}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        liable_initials: e.target.value,
                      })
                    }
                  />
                  <p>
                    If you answered yes, please fill out accident-specific form,
                    available at the front desk.
                  </p>
                  <div className="employment-row">
                    <label>Pregnant:</label>
                    <label>
                      <input
                        type="radio"
                        name="pregnant"
                        value="pregnant_yes"
                        checked={formData.pregnant_yes}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            pregnant_yes: true,
                            pregnant_no: false,
                          })
                        }
                      />{" "}
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="pregnant"
                        value="pregnant_no"
                        checked={formData.pregnant_no}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            pregnant_no: true,
                            pregnant_yes: false,
                          })
                        }
                      />{" "}
                      No
                    </label>
                    <label>Pacemaker:</label>
                    <label>
                      <input
                        type="radio"
                        name="pacemaker"
                        value="pacemaker_yes"
                        checked={formData.pacemaker_yes}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            pacemaker_yes: true,
                            pacemaker_no: false,
                          })
                        }
                      />{" "}
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="pacemaker"
                        value="pacemaker_no"
                        checked={formData.pacemaker_no}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            pacemaker_no: true,
                            pacemaker_yes: false,
                          })
                        }
                      />{" "}
                      No
                    </label>
                  </div>
                  <div className="employment-row">
                    <label>Family Physician</label>
                    <input
                      type="text"
                      className="employment-input-long"
                      value={formData.family_physician}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          family_physician: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="employment-row">
                    <label>
                      Person to contact in emergency (Name and Phone #)
                    </label>
                    <input
                      type="text"
                      className="employment-input-long"
                      value={formData.emergency_contact}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          emergency_contact: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="employment-row">
                    <label>Attorney</label>
                    <input
                      type="text"
                      className="employment-input"
                      value={formData.attorney}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          attorney: e.target.value,
                        })
                      }
                    />
                    <label>Telephone:</label>
                    <input
                      type="text"
                      className="employment-input"
                      value={formData.attorney_phone}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          attorney_phone: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="employment-row">
                    <label>Address</label>
                    <input
                      type="text"
                      className="employment-input-long"
                      value={formData.attorney_address}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          attorney_address: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pr-row">
              <div className="pr-col-20">
                <strong>
                  {" "}
                  Patient Agreement & Authorization For The Release Of Medical
                  And Health Plan Documents For The Claims Processing &
                  Reimbursement As Required by Federal and State Laws
                </strong>
              </div>
              <div className="pr-col-80">
                <div className="legal-assignment">
                  <p>
                    Legal Assignment of Benefits and Designation Of Authorized
                    Representative
                  </p>
                  <p>
                    In considering the amount of medical expenses to be
                    incurred, I, the undersigned, have insurance and/or employee
                    health care benefits coverage with the above-captioned, and
                    hereby assign and convey directly to the above named
                    healthcare provider(s),{" "}
                    <u>as my designated Authorized Representative(s)</u>, all
                    medical benefits and/or insurance reimbursement, if any,
                    otherwise payable to me for services rendered from such
                    provider(s), regardless of such provider's managed care
                    network participation status. I understand that I am
                    financially responsible for all charges regardless of any
                    applicable insurance or benefit payments.{" "}
                    <u>
                      I hereby authorize the above named provider(s) to release
                      all medical information necessary to process my claims
                      under HIPAA.
                    </u>{" "}
                    I hereby authorize any plan administrator or fiduciary,
                    insurer and my attorney to release to such provider(s) any
                    and all plan documents, insurance policy and/or settlement
                    information upon written request from such provider(s) in
                    order to claim such medical benefits, reimbursement or any
                    applicable remedies. I authorize the use of this signature
                    on all my insurance and/or employee health benefits claim
                    submissions.
                  </p>
                  <p>
                    I hereby convey to the above named provider(s), to the full
                    extent permissible under the law and under any applicable
                    employee group health plan(s), insurance policies or
                    liability claim, any claim, chose in action, or other right
                    I may have to such group health plans, health insurance
                    issuers or tortfeasor or insurer(s) necessary to claim all
                    applicable insurance policies, employee benefits plan(s) or
                    public policies with respect to medical expenses incurred as
                    a result of the medical services I received from the above
                    named provider(s), and to the full extent permissible under
                    the law to claim or lien such medical benefits, settlement,
                    insurance reimbursement and any applicable remedies,
                    including, but not limited to, (1) obtaining information
                    about the claim to the same extent as the assignor; (2)
                    submitting evidence; (3) making statements about facts or
                    law; (4) making any request, or giving, or receiving any
                    notice about appeal proceedings; and (5) any administrative
                    and judicial actions by such provider(s) to pursue such
                    claim, chose in action or right against any liable party or
                    employee group health plan(s), including, if necessary,
                    bring suit by such provider(s) against any such liable party
                    or employee group health plan in my name with standing but
                    at such provider(s) expenses. Unless revoked, this
                    assignment is valid for all administrative and judicial
                    reviews under PPACA, ERISA, Medicare and applicable federal
                    or state laws. A photocopy of this assignment is to be
                    considered as valid as the original. I have read and fully
                    understand this agreement.
                  </p>
                </div>
                <div className="grid-2">
                  <div className="signature-container mt-50">
                    <SignatureButton
                      individualSignature={individualSignature}
                      setIndividualSignature={setIndividualSignature}
                    />
                    <hr className="signature-line"></hr>
                    <p>Patient Signature</p>
                  </div>

                  <div className="mt-50">
                    <label htmlFor="name" className="hidden">
                      Patient Siganture
                    </label>
                    <input
                      type="date"
                      id="name"
                      name="name"
                      className="bottom-border-input"
                      required
                      value={formData.signature_date}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          signature_date: e.target.value,
                        })
                      }
                    />

                    <p>Date</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PatientRegistration;
