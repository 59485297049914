import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  getPatientSatisfaction,
  updatePatientSatisfaction,
} from "../../../utils/api";
import "./styles.css";

const PatientSatisfaction = () => {
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [formFields, setFormFields] = useState({
    reasonable_wait: null,
    unreasonable_wait: null,
    forms_explained: null,
    forms_not_explained: null,
    cared_for: null,
    not_cared_for: null,
    surgeon_speak: null,
    surgeon_not_speak: null,
    questions_answered: null,
    questions_not_answered: null,
    signs_explained: null,
    signs_not_explained: null,
    call_explained: null,
    call_not_explained: null,
    care_explained: null,
    care_not_explained: null,
    privacy_respected: null,
    privacy_not_respected: null,
    respected: null,
    not_respected: null,
    financial_explained: null,
    financial_not_explained: null,
    feel_safe: null,
    feel_not_safe: null,
    comments: null,
  });
  const [comments, setComments] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isComplete = [
      formFields.reasonable_wait,
      formFields.forms_explained,
      formFields.cared_for,
      formFields.surgeon_speak,
      formFields.questions_answered,
      formFields.signs_explained,
      formFields.call_explained,
      formFields.care_explained,
      formFields.privacy_respected,
      formFields.respected,
      formFields.financial_explained,
      formFields.feel_safe,
    ].every((field) => field !== null && field !== false);
    const payload = {
      question_1: formFields.reasonable_wait,
      question_2: formFields.forms_explained,
      question_3: formFields.cared_for,
      question_4: formFields.surgeon_speak,
      question_5: formFields.questions_answered,
      question_6: formFields.signs_explained,
      question_7: formFields.call_explained,
      question_8: formFields.care_explained,
      question_9: formFields.privacy_respected,
      question_10: formFields.respected,
      question_11: formFields.financial_explained,
      question_12: formFields.feel_safe,
      comments,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: isComplete ? "Complete" : "In Progress",
    };

    console.log(payload);

    // Fetch 2:
    updatePatientSatisfaction(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  const handleInputChange = (fieldYes, fieldNo, isYes) => {
    setFormFields((prev) => ({
      ...prev,
      [fieldYes]: isYes,
      [fieldNo]: !isYes,
    }));
  };

  useEffect(() => {
    getPatientSatisfaction(form_id)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);

        if (data.status !== "Not Started") {
          setFormFields({
            ...formFields,
            reasonable_wait: data.question_1,
            forms_explained: data.question_2,
            cared_for: data.question_3,
            surgeon_speak: data.question_4,
            questions_answered: data.question_5,
            signs_explained: data.question_6,
            call_explained: data.question_7,
            care_explained: data.question_8,
            privacy_respected: data.question_9,
            respected: data.question_10,
            financial_explained: data.question_11,
            feel_safe: data.question_12,
          });

          setComments(data.comments || "");
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    console.log(formFields);
  }, [formFields]);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <div className="form-content">
        <div>
          <form onSubmit={handleSubmit}>
            <div className="satisfaction-container">
              <h2>Patient Satisfaction Survey</h2>
              <p>
                Your opinion means a great deal to us. We care what you think.
                We want your experience to be the best it can. We think that
                means the friendliest, most responsive staff providing personal,
                professional service. But, what do YOU think? Won't you please
                take a few minutes of your time and let us know what you like
                (or don't like) about our services? Any comments would be
                appreciated. Thank you for your assistance.
              </p>

              <div className="survey">
                <div className="pa-question">
                  <label className="question-label">
                    1. Was the waiting time reasonable for your procedure?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="reasonable_wait"
                        checked={formFields.reasonable_wait || false}
                        onChange={() =>
                          handleInputChange(
                            "reasonable_wait",
                            "unreasonable_wait",
                            true
                          )
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="unreasonable_wait"
                        checked={formFields.unreasonable_wait || false}
                        onChange={() =>
                          handleInputChange(
                            "reasonable_wait",
                            "unreasonable_wait",
                            false
                          )
                        }
                      />
                    </label>
                  </div>
                </div>

                <div className="pa-question">
                  <label className="question-label">
                    2. Were the forms that you signed explained to you?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="forms_explained"
                        value="true"
                        checked={formFields.forms_explained || false}
                        onChange={() =>
                          handleInputChange(
                            "forms_explained",
                            "forms_not_explained",
                            true
                          )
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="forms_explained"
                        value="false"
                        checked={formFields.forms_not_explained || false}
                        onChange={() =>
                          handleInputChange(
                            "forms_explained",
                            "forms_not_explained",
                            false
                          )
                        }
                      />
                    </label>
                  </div>
                </div>

                <div className="pa-question">
                  <label className="question-label">
                    3. Did you feel you were cared for efficiently and
                    competently?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="cared_for"
                        value="true"
                        checked={formFields.cared_for || false}
                        onChange={() =>
                          handleInputChange("cared_for", "not_cared_for", true)
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="not_cared_for"
                        value="false"
                        checked={formFields.not_cared_for || false}
                        onChange={() =>
                          handleInputChange("cared_for", "not_cared_for", false)
                        }
                      />
                    </label>
                  </div>
                </div>

                <div className="pa-question">
                  <label className="question-label">
                    4. Did your surgeon speak with you/your “ride”/family member
                    following surgery?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="surgeon_speak"
                        value="true"
                        checked={formFields.surgeon_speak || false}
                        onChange={() =>
                          handleInputChange(
                            "surgeon_speak",
                            "surgeon_not_speak",
                            true
                          )
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="surgeon_not_speak"
                        value="false"
                        checked={formFields.surgeon_not_speak || false}
                        onChange={() =>
                          handleInputChange(
                            "surgeon_speak",
                            "surgeon_not_speak",
                            false
                          )
                        }
                      />
                    </label>
                  </div>
                </div>

                <div className="pa-question">
                  <label className="question-label">
                    5. Were your questions answered by the nursing staff prior
                    to the procedure?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="questions_answered"
                        value="true"
                        checked={formFields.questions_answered || false}
                        onChange={() =>
                          handleInputChange(
                            "questions_answered",
                            "questions_not_answered",
                            true
                          )
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="questions_not_answered"
                        value="false"
                        checked={formFields.questions_not_answered || false}
                        onChange={() =>
                          handleInputChange(
                            "questions_answered",
                            "questions_not_answered",
                            false
                          )
                        }
                      />
                    </label>
                  </div>
                </div>

                <div className="pa-question">
                  <label className="question-label">
                    6. Were signs and symptoms of possible problems that may
                    occur at home explained to you?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="signs_explained"
                        value="true"
                        checked={formFields.signs_explained || false}
                        onChange={() =>
                          handleInputChange(
                            "signs_explained",
                            "signs_not_explained",
                            true
                          )
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="signs_not_explained"
                        value="false"
                        checked={formFields.signs_not_explained || false}
                        onChange={() =>
                          handleInputChange(
                            "signs_explained",
                            "signs_not_explained",
                            false
                          )
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className="pa-question">
                  <label className="question-label">
                    7. If problems occurred at home, did you know whom to call?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="call_explained"
                        value="true"
                        checked={formFields.call_explained || false}
                        onChange={() =>
                          handleInputChange(
                            "call_explained",
                            "call_not_explained",
                            true
                          )
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="call_not_explained"
                        value="false"
                        checked={formFields.call_not_explained || false}
                        onChange={() =>
                          handleInputChange(
                            "call_explained",
                            "call_not_explained",
                            false
                          )
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className="pa-question">
                  <label className="question-label">
                    8. Did you understand how to take care of yourself at home?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="care_explained"
                        value="true"
                        checked={formFields.care_explained || false}
                        onChange={() =>
                          handleInputChange(
                            "care_explained",
                            "care_not_explained",
                            true
                          )
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="care_not_explained"
                        value="false"
                        checked={formFields.care_not_explained || false}
                        onChange={() =>
                          handleInputChange(
                            "care_explained",
                            "care_not_explained",
                            false
                          )
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className="pa-question">
                  <label className="question-label">
                    9. Was your privacy provided for and respected?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="privacy_respected"
                        value="true"
                        checked={formFields.privacy_respected || false}
                        onChange={() =>
                          handleInputChange(
                            "privacy_respected",
                            "privacy_not_respected",
                            true
                          )
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="privacy_not_respected"
                        value="false"
                        checked={formFields.privacy_not_respected || false}
                        onChange={() =>
                          handleInputChange(
                            "privacy_respected",
                            "privacy_not_respected",
                            false
                          )
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className="pa-question">
                  <label className="question-label">
                    10. Did you feel you were treated with respect and courtesy?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="respected"
                        value="true"
                        checked={formFields.respected || false}
                        onChange={() =>
                          handleInputChange("respected", "not_respected", true)
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="not_respected"
                        value="false"
                        checked={formFields.not_respected || false}
                        onChange={() =>
                          handleInputChange("respected", "not_respected", false)
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className="pa-question">
                  <label className="question-label">
                    11. Was financial responsibility for your procedure made
                    clear?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="financial_explained"
                        value="true"
                        checked={formFields.financial_explained || false}
                        onChange={() =>
                          handleInputChange(
                            "financial_explained",
                            "financial_not_explained",
                            true
                          )
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="financial_not_explained"
                        value="false"
                        checked={formFields.financial_not_explained || false}
                        onChange={() =>
                          handleInputChange(
                            "financial_explained",
                            "financial_not_explained",
                            false
                          )
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className="pa-question">
                  <label className="question-label">
                    12. Did you feel safe before, during, and after your
                    surgery?
                  </label>
                  <div className="options">
                    <label>
                      Yes{" "}
                      <input
                        type="checkbox"
                        name="feel_safe"
                        value="true"
                        checked={formFields.feel_safe || false}
                        onChange={() =>
                          handleInputChange("feel_safe", "feel_not_safe", true)
                        }
                      />
                    </label>
                    <label>
                      No{" "}
                      <input
                        type="checkbox"
                        name="feel_not_safe"
                        value="false"
                        checked={formFields.feel_not_safe || false}
                        onChange={() =>
                          handleInputChange("feel_safe", "feel_not_safe", false)
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className="comments-section">
                  <label>
                    COMMENTS: (Please use the space below to provide us
                    suggestions that you feel would help us improve the care we
                    provide.)
                  </label>
                  <textarea
                    value={comments}
                    name="comments"
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="center mt-50">
              <button className="btn-primary hover-pointer" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default PatientSatisfaction;
