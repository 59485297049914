import React, { useState, useEffect } from "react";
import "./nursingQuestionnaire.css";
import ToggleSwitch from "./ToggleSwitch";
import Questionnaire from "./Questionnaire";
import BottomQuestionnaire from "./BottomQuestionnaire";
import { useParams } from "react-router-dom";
import {
  getPatientById,
  getPatientInformation,
  addMedicine,
  addPriorSurgery,
  addMedAllergy,
  addFoodAllergy,
  udpatePatient,
  updateMedHistory,
  getSurgeryInfoById,
  updateSurgeryInfoById,
} from "../../../utils/api";
import { initialNqMedHistoryState } from "./data";
import MedicationAllergies from "../../../components/NQ&PI/med-allergies";
import CurrentMedications from "../../../components/NQ&PI/medications";
import FoodAllergies from "../../../components/NQ&PI/food-allergies";
import Surgery from "../../../components/NQ&PI/surgery";
import { Link } from "react-router-dom";
const moment = require("moment");

const NursingQuestionnaire = () => {
  const [loading, setLoading] = useState(true);
  const { id, surgeryInfo_id } = useParams();
  const [selectedPatient, setSelectedPatient] = useState([]); // Patient Details (id's)
  const [pI, setpI] = useState([]); // "Patient Information" from the db

  // ID's for submit queries
  const [patientId, setPatientId] = useState(null);
  const [surgeryId, setSurgeryId] = useState(null);
  const [medHistoryId, setmedHistoryId] = useState(null);
  const [surgeryInfoId, setsurgeryInfoId] = useState(null);

  // Previous db values
  const [dbMedHistory, setDbMedHistory] = useState(null);

  // Form data
  const [nQ, setNq] = useState([]);
  const [isMetric, setIsMetric] = useState(false);
  const [height, setHeight] = useState({ feet: "0", inches: "0", cm: "0" });
  const [weight, setWeight] = useState({ pounds: "0", kg: "0" });
  const [age, setAge] = useState(moment());
  const [sex, setSex] = useState("male");
  const [diagnosis, setDiagnosis] = useState("");
  const [bmi, setBmi] = useState("");
  const [surgeries, setSurgeries] = useState([]);
  const [medAllergies, setMedAllergies] = useState([]);
  const [nkfa, setNkfa] = useState(false);
  const [nkma, setNkma] = useState(false);
  const [nqHistory, setNqHistory] = useState([]);
  const [nqMedHistory, setNqMedHistory] = useState(initialNqMedHistoryState);
  const [foodAllergies, setFoodAllergies] = useState([]);
  const [medications, setMedications] = useState([]);
  const [recentlyTraveled, setRecentlyTraveled] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [noKnownMedicalHistory, setNoKnownMedicalHistory] = useState(false);
  const [medHistory, setMedHistory] = useState([]);

  // Get data on page load
  useEffect(() => {
    // Fetch patient data by id
    getPatientById(id)
      .then((res) => res.json())
      .then((data) => {
        setSelectedPatient(data);
        // console.log(data);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setAge(data.birthday);
        setSex(data.sex);

        // Update Id's
        setPatientId(data.id);
        setsurgeryInfoId(surgeryInfo_id);

        // Fetch Patient Information by id
        getPatientInformation(data.patientInformation.id)
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            // Save a copy of original data
            setpI(data);

            // Update Display State
            setMedications(data.medications);
            setMedAllergies(data.medAllergies);
            setFoodAllergies(data.foodAllergies);
            setSurgeries(data.priorSurgeries);
            setMedHistory(data.medHistory);
            setNkfa(data.medHistory.NKFA);
            setNkma(data.medHistory.NKMA);
            setNoKnownMedicalHistory(data.medHistory.NKMH);

            // Update Id's
            setmedHistoryId(data.medHistory.id);
            localStorage.setItem(
              "med_history_id",
              JSON.stringify(data.medHistory.id)
            );
            setDbMedHistory(data.medHistory);

            getSurgeryInfoById(surgeryInfo_id)
              .then((res) => res.json())
              .then((data) => {
                console.log(data);

                setSurgeryId(data.surgery_id);

                if (data.height) {
                  if (data.height.includes("ft")) {
                    const [feet, inches] = data.height
                      .replace("ft", "")
                      .replace("in", "")
                      .split(" ");
                    setHeight({
                      feet: feet.trim(),
                      inches: inches.trim(),
                      cm: "",
                    });
                    setIsMetric(false);
                  } else {
                    setHeight({ feet: "0", inches: "0", cm: data.height });
                    setIsMetric(true);
                  }
                } else {
                  setHeight({ feet: "0", inches: "0", cm: "0" });
                }

                setWeight({
                  pounds: data.weight || "0",
                  kg: data.weight || "0",
                });

                setBmi(data.bmi || "");
                setDiagnosis(data.diagnosis || "");
                setRecentlyTraveled(data.recently_traveled);

                setNqHistory({
                  sleep_apnea: data.sleep_apnea || false,
                  sleep_apnea_text: data.sleep_apnea_text || "",
                  latex_allergy: data.latex_allergy || false,
                  latex_allergy_text: data.latex_allergy_text || "",
                  last_menstrual: data.last_menstrual || "",
                  menstrual_na: data.menstrual_na || false,
                  pregnant: data.pregnant || false,
                });

                setLoading(false);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // New entry form state for medications, surgeries, allergies
  const [newEntry, setNewEntry] = useState({
    medications: {
      name: "",
      dose: "",
      route: "",
      frequency: "",
      status: "Active",
    },
    surgeries: { surgery_name: "" },
    medAllergies: { medication: "", reaction: "" },
    foodAllergies: { food: "", reaction: "" },
  });

  const handleToggle = (active) => {
    setIsMetric(active);

    if (active) {
      // Convert feet and inches to cm if metric is active
      const totalInches =
        parseFloat(height.feet || 0) * 12 + parseFloat(height.inches || 0);
      const cm = (totalInches * 2.54).toFixed(2);
      setHeight({ ...height, cm, feet: "0", inches: "0" });
      // Convert pounds to kg
      const kg = (parseFloat(weight.pounds || 0) / 2.20462).toFixed(2);
      setWeight({ ...weight, kg });
    } else {
      // Convert cm to feet and inches
      const totalInches = parseFloat(height.cm || 0) / 2.54;
      const feet = Math.floor(totalInches / 12);
      const inches = (totalInches % 12).toFixed(2);
      setHeight({
        ...height,
        feet: feet.toString(),
        inches: inches.toString(),
        cm: "",
      });
      // Convert kg to pounds
      const pounds = (parseFloat(weight.kg || 0) * 2.20462).toFixed(2);
      setWeight({ ...weight, pounds });
    }
  };

  // BMI calculation logic
  useEffect(() => {
    if (isMetric) {
      // Metric BMI calculation
      const heightInMeters = parseFloat(height.cm) / 100;
      if (heightInMeters > 0 && parseFloat(weight.kg) > 0) {
        const calculatedBmi = (
          parseFloat(weight.kg) /
          heightInMeters ** 2
        ).toFixed(2);
        setBmi(calculatedBmi);
      }
    } else {
      // Imperial BMI calculation
      const totalInches =
        parseFloat(height.feet) * 12 + parseFloat(height.inches);
      if (totalInches > 0 && parseFloat(weight.pounds) > 0) {
        const calculatedBmi = (
          (parseFloat(weight.pounds) / totalInches ** 2) *
          703
        ).toFixed(2);
        setBmi(calculatedBmi);
      }
    }
  }, [height, weight, isMetric]);

  // Submit handler for form
  const handleSubmit = () => {
    const queryArr = [];

    const surgeryInfoPayload = {
      height: isMetric ? height.cm : `${height.feet}ft ${height.inches}in`,
      weight: isMetric ? weight.kg : weight.pounds,
      calculated_bmi: bmi || null,
      diagnosis: diagnosis,
      recently_traveled: recentlyTraveled,
      ...nqHistory,
    };

    const query1 = updateSurgeryInfoById(surgeryInfoId, surgeryInfoPayload)
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));

    queryArr.push(query1);

    if (selectedPatient.sex !== sex) {
      const patientPayload = {
        sex: sex,
      };

      const query2 = udpatePatient(patientId, patientPayload)
        .then((res) => res.json())
        .then((data) => console.log(data))
        .catch((err) => console.log(err));

      queryArr.push(query2);
    }

    // if (
    //   noKnownMedicalHistory !== dbMedHistory.NKMH ||
    //   nkma !== dbMedHistory.NKMA ||
    //   nkfa !== dbMedHistory.NKFA
    // ) {
    //   const medHistoryPayload = {
    //     NKMH: noKnownMedicalHistory,
    //     NKMA: nkma,
    //     NKFA: nkfa,
    //   };

    //   const query3 = updateMedHistory(medHistoryId, medHistoryPayload)
    //     .then((res) => res.json())
    //     .then((data) => console.log(data))
    //     .catch((err) => console.log(err));

    //   queryArr.push(query3);
    // }

    Promise.all(queryArr).then((values) => {
      console.log(values);
      window.location.href = `/surgery-view/${patientId}/surgery/${surgeryId}`;
    });
  };

  // Handle form input changes
  const handleInputChange = (e, type, field = null) => {
    const value = e.target.value;

    setNewEntry((prev) => ({
      ...prev,
      [type]: field ? { ...prev[type], [field]: value } : value,
    }));
  };

  // Add new entry (medications, surgeries, allergies)
  const addEntry = (type) => {
    if (type === "medications") {
      const medicationEntry = {
        name: newEntry.medications.name,
        dose: newEntry.medications.dose,
        route: newEntry.medications.route,
        frequency: newEntry.medications.frequency,
        status: newEntry.medications.status,
        patient_info_id: pI.id,
      };

      addMedicine(medicationEntry)
        .then((res) => res.json())
        .then((data) => {
          if (data.newMedications && data.newMedications.id) {
            newEntry.medications.id = data.newMedications.id;

            setMedications((prev) => [...prev, newEntry.medications]);

            setNewEntry((prev) => ({
              ...prev,
              medications: {
                name: "",
                dose: "",
                route: "",
                frequency: "",
                status: "Active",
              },
            }));
          }
        })
        .catch((err) => console.error("Error adding medication:", err));
    } else if (type === "surgeries") {
      const surgeryEntry = {
        surgery_name: newEntry.surgeries.surgery_name,
        patient_info_id: pI.id,
      };

      addPriorSurgery(surgeryEntry)
        .then((res) => res.json())
        .then((data) => {
          if (data.newPriorSurgeries && data.newPriorSurgeries.id) {
            newEntry.surgeries.id = data.newPriorSurgeries.id;

            setSurgeries((prev) => [...prev, newEntry.surgeries]);
            setNewEntry((prev) => ({
              ...prev,
              surgeries: { surgery_name: "" },
            }));
          }
        })
        .catch((err) => console.error("Error adding surgery:", err));
    } else if (type === "medAllergies") {
      newEntry.medAllergies.patient_info_id = pI.id;

      addMedAllergy(newEntry.medAllergies)
        .then((res) => res.json())
        .then((data) => {
          if (data && data.newMedAllergies && data.newMedAllergies.id) {
            newEntry.medAllergies.id = data.newMedAllergies.id;

            setMedAllergies((prev) => [...prev, newEntry.medAllergies]);
            setNewEntry((prev) => ({
              ...prev,
              medAllergies: { medication: "", reaction: "" },
            }));
          } else {
            console.error("Invalid API response:", data);
          }
        })
        .catch((err) => console.error("Error adding medAllergies:", err));
    } else if (type === "foodAllergies") {
      newEntry.foodAllergies.patient_info_id = pI.id;

      addFoodAllergy(newEntry.foodAllergies)
        .then((res) => res.json())
        .then((data) => {
          if (data && data.newFoodAllergies && data.newFoodAllergies.id) {
            newEntry.foodAllergies.id = data.newFoodAllergies.id;

            setFoodAllergies((prev) => [...prev, newEntry.foodAllergies]);
            setNewEntry((prev) => ({
              ...prev,
              foodAllergies: { food: "", reaction: "" },
            }));
          } else {
            console.error("Invalid API response:", data);
          }
        })
        .catch((err) => console.error("Error adding foodAllergies:", err));
    }
  };

  const handleMedHistory = (e, type) => {
    const value = e.target.checked;
    const payload = {};

    if (type === "medAllergies") {
      payload.NKMA = value;
    } else if (type === "foodAllergies") {
      payload.NKFA = value;
    } else if (type === "medHistory") {
      payload.NKMH = value;
    }

    updateMedHistory(medHistory.id, payload)
      .then((res) => res.json())
      .then((data) => {
        setNkma(value);
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* Nursing Questionnaire */}
      <div className="patient-grid-container">
        <div className="patient-first-column"></div>
        <div className="patient-second-column patient-info-card">
          <section className="nq-nav">
            <Link
              to="/patient-search"
              style={{ color: "#5895ee", textDecoration: "underline" }}
            >
              Patients
            </Link>
            &nbsp; &gt; &nbsp;
            <Link
              to={`/patient/${id}`}
              style={{ color: "#5895ee", textDecoration: "underline" }}
            >
              {firstName} {lastName}
            </Link>
            &nbsp; &gt; &nbsp;
            <Link to="/patient-search">Nursing Questionnaire</Link>
          </section>
          <div className="nurse-ques-form">
            <div className="nurse-ques-container">
              <h1>Nursing Questionnaire</h1>
              <div className="border-bottom"></div>

              {/* Height, Weight, and Toggle */}
              <div className="nurse-ques-form-row">
                <div className="nurse-ques-form-group">
                  <label htmlFor="height">Height</label>
                  <div className="nurse-ques-input-group">
                    {!isMetric ? (
                      <>
                        <div className="nurse-ques-field">
                          <input
                            id="height-feet"
                            type="text"
                            placeholder="ft."
                            value={height.feet}
                            onChange={(e) =>
                              setHeight({ ...height, feet: e.target.value })
                            }
                          />
                          <label className="below-label" htmlFor="height-feet">
                            Feet
                          </label>
                        </div>
                        <div className="nurse-ques-field">
                          <input
                            type="text"
                            placeholder="inch."
                            id="height-inches"
                            value={height.inches}
                            onChange={(e) =>
                              setHeight({ ...height, inches: e.target.value })
                            }
                          />
                          <label
                            className="below-label"
                            htmlFor="height-inches"
                          >
                            Inches
                          </label>
                        </div>
                      </>
                    ) : (
                      <div className="nurse-ques-field">
                        <input
                          id="height-cm"
                          type="text"
                          placeholder="cm"
                          value={height.cm}
                          onChange={(e) =>
                            setHeight({ ...height, cm: e.target.value })
                          }
                        />
                        <label className="below-label" htmlFor="height-cm">
                          Centimeters
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                <div className="nurse-ques-form-group">
                  <label htmlFor="weight">Weight</label>
                  <div className="nurse-ques-field">
                    {!isMetric ? (
                      <>
                        <input
                          id="weight"
                          type="text"
                          placeholder="lbs."
                          value={weight.pounds}
                          onChange={(e) =>
                            setWeight({ ...weight, pounds: e.target.value })
                          }
                        />
                        <label className="below-label" htmlFor="weight">
                          Pounds
                        </label>
                      </>
                    ) : (
                      <>
                        <input
                          id="weight-kg"
                          type="text"
                          placeholder="kg"
                          value={weight.kg}
                          onChange={(e) =>
                            setWeight({ ...weight, kg: e.target.value })
                          }
                        />
                        <label className="below-label" htmlFor="weight-kg">
                          Kilograms
                        </label>
                      </>
                    )}
                  </div>
                </div>

                <div className="nurse-ques-form-group switch-group">
                  <ToggleSwitch id="toggle" onToggle={handleToggle} />
                </div>
              </div>

              {/* BMI, age, sex, diagnosis */}
              <div className="nurse-ques-form-row bmi">
                <div className="nurse-ques-form-group bmi-group">
                  <label htmlFor="bmi">Calculated BMI</label>
                  <input
                    type="text"
                    disabled
                    className="bmi-input"
                    id="bmi"
                    value={bmi || ""}
                  />
                </div>
                {/* Age calculated from birthday */}
                <div className="nurse-ques-form-group">
                  <label htmlFor="age">Age</label>
                  <input
                    type="number"
                    id="age"
                    value={moment().diff(`${age}`, "years")}
                    readOnly
                    // onChange={(e) => setAge(e.target.value)}
                  />
                </div>
                <div className="nurse-ques-form-group">
                  <label htmlFor="sex">Sex</label>
                  <select
                    id="sex"
                    value={sex}
                    onChange={(e) => setSex(e.target.value)}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>

              <div className="nurse-ques-form-row">
                <div className="nurse-ques-form-group diagnosis-group">
                  <label htmlFor="diagnosis">Diagnosis</label>
                  <input
                    type="text"
                    id="diagnosis"
                    value={diagnosis}
                    onChange={(e) => setDiagnosis(e.target.value)}
                  />
                </div>
              </div>

              {/* Current Medications */}
              <div className="table-container">
                <h2 className="table-label">Current Medications</h2>
                <table className="medications-table">
                  <thead>
                    <tr>
                      <th className="table-label">Name</th>
                      <th className="table-label">Dose</th>
                      <th className="table-label">Route</th>
                      <th className="table-label">Frequency</th>
                      <th className="table-label">Status</th>
                      <th className="table-label">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {medications.map((med, index) => (
                      <CurrentMedications
                        med={med}
                        key={med.id}
                        medications={medications}
                        setMedications={setMedications}
                      />
                    ))}
                    <tr>
                      <td data-label="Name">
                        <input
                          type="text"
                          name="name"
                          value={newEntry.medications.name}
                          onChange={(e) =>
                            handleInputChange(e, "medications", "name")
                          }
                        />
                      </td>
                      <td data-label="Dose">
                        <input
                          type="text"
                          name="dose"
                          value={newEntry.medications.dose}
                          onChange={(e) =>
                            handleInputChange(e, "medications", "dose")
                          }
                        />
                      </td>
                      <td data-label="Route">
                        <input
                          type="text"
                          name="route"
                          value={newEntry.medications.route}
                          onChange={(e) =>
                            handleInputChange(e, "medications", "route")
                          }
                        />
                      </td>
                      <td data-label="Frequency">
                        <input
                          type="text"
                          name="frequency"
                          value={newEntry.medications.frequency}
                          onChange={(e) =>
                            handleInputChange(e, "medications", "frequency")
                          }
                        />
                      </td>
                      <td data-label="Status" className="nowrap">
                        <select
                          name="status"
                          value={newEntry.medications.status}
                          onChange={(e) =>
                            handleInputChange(e, "medications", "status")
                          }
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </td>
                      <td data-label="" className="flex-center">
                        <button
                          className="medication-add-btn"
                          onClick={() => addEntry("medications")}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Prior Surgeries */}
              <div>
                <h3 className="table-label">Prior Surgeries</h3>
                <table className="surgeries-table">
                  <tbody>
                    {surgeries.map((surgery, index) => (
                      <Surgery
                        key={surgery.id}
                        surgery={surgery}
                        surgeries={surgeries}
                        setSurgeries={setSurgeries}
                      />
                    ))}
                    <tr>
                      <td>
                        <input
                          type="text"
                          placeholder="Enter surgery"
                          value={newEntry.surgeries.surgery_name}
                          onChange={(e) =>
                            handleInputChange(e, "surgeries", "surgery_name")
                          }
                        />
                      </td>
                      <td>
                        <button
                          className="surgery-add-btn"
                          onClick={() => {
                            addEntry("surgeries");
                          }}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Questionnaire */}
              {dbMedHistory !== null && (
                <Questionnaire
                  nqMedHistory={nqMedHistory}
                  nQ={nQ}
                  setRecentlyTraveled={setRecentlyTraveled}
                  recentlyTraveled={recentlyTraveled}
                  noKnownMedicalHistory={noKnownMedicalHistory}
                  setNoKnownMedicalHistory={setNoKnownMedicalHistory}
                  dbMedHistory={dbMedHistory}
                  handleMedHistory={handleMedHistory}
                />
              )}

              {/* Medication Allergies */}

              <div className="allergy-heading">
                <h3 className="table-label">Medication Allergies</h3>
                {medAllergies.length === 0 ? (
                  <label>
                    <input
                      type="checkbox"
                      checked={nkma}
                      onChange={(e) => {
                        setNkma(e.target.checked);
                        if (e.target.checked) {
                          handleMedHistory(e, "medAllergies");
                        }
                      }}
                    />
                    NKA
                  </label>
                ) : (
                  <></>
                )}
              </div>
              <table
                className={
                  nkma ? "medications-table dimmed" : "medications-table"
                }
              >
                <thead>
                  <tr>
                    <th className="table-label">Medication</th>
                    <th className="table-label">Reaction</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {medAllergies.map((allergy, index) => (
                    <MedicationAllergies
                      key={allergy.id}
                      allergy={allergy}
                      setMedAllergies={setMedAllergies}
                      medAllergies={medAllergies}
                    />
                  ))}
                  <tr>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Medication:</label>
                      <input
                        type="text"
                        name="medication"
                        value={newEntry.medAllergies.medication}
                        onChange={(e) =>
                          handleInputChange(e, "medAllergies", "medication")
                        }
                      />
                    </td>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Reaction:</label>
                      <input
                        type="text"
                        name="reaction"
                        value={newEntry.medAllergies.reaction}
                        onChange={(e) =>
                          handleInputChange(e, "medAllergies", "reaction")
                        }
                      />
                    </td>
                    <td className="flex-center">
                      <button
                        className="medication-add-btn"
                        onClick={() => addEntry("medAllergies")}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Food Allergies */}

              <div className="allergy-heading">
                <h3 className="table-label">Food Allergies</h3>
                {foodAllergies.length === 0 ? (
                  <label>
                    <input
                      type="checkbox"
                      checked={nkfa}
                      onChange={(e) => {
                        setNkfa(e.target.checked);
                        handleInputChange(e, "foodAllergies", "nka");
                        handleMedHistory(e, "foodAllergies");
                      }}
                    />{" "}
                    NKA
                  </label>
                ) : (
                  <></>
                )}
              </div>
              <table
                className={
                  nkfa ? "medications-table dimmed" : "medications-table"
                }
              >
                <thead>
                  <tr>
                    <th className="table-label">Food</th>
                    <th className="table-label">Reaction</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {foodAllergies.map((allergy, index) => (
                    <FoodAllergies
                      key={allergy.id}
                      allergy={allergy}
                      setFoodAllergies={setFoodAllergies}
                      foodAllergies={foodAllergies}
                    />
                  ))}
                  <tr>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Food:</label>
                      <input
                        type="text"
                        name="food"
                        value={newEntry.foodAllergies.food}
                        onChange={(e) =>
                          handleInputChange(e, "foodAllergies", "food")
                        }
                      />
                    </td>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Reaction:</label>
                      <input
                        type="text"
                        name="reaction"
                        value={newEntry.foodAllergies.reaction}
                        onChange={(e) =>
                          handleInputChange(e, "foodAllergies", "reaction")
                        }
                      />
                    </td>
                    <td className="flex-center">
                      <button
                        className="medication-add-btn"
                        onClick={() => addEntry("foodAllergies")}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Bottom Questionnaire */}
              <BottomQuestionnaire
                nqHistory={nqHistory}
                nQ={nQ}
                setNqHistory={setNqHistory}
              />

              {/* Submit Button */}
              <div className="ques-submit-btn-container">
                <button className="ques-submit-btn" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NursingQuestionnaire;
