/**
 * Removes Special Characters ()-
 * @param {string} arg The phone number the user entered on the front-end.
 */
const replaceSpecialChar = (value) => {
  if (typeof value !== "string") {
    // Make sure it is a string
    return value.toString().replace(/[-()]/g, "");
  } else {
    return value.replace(/[-()]/g, "");
  }
};

/**
 * Formats Phone Numer for display --> (XXX)XXX-XXXX
 * @param {string} arg The phone number the user entered on the front-end.
 */
const formatPhone = (num) => {
  return `${num.substring(4) ? "(" : ""}${num.substring(0, 3)}${
    num.substring(4) ? ")" : ""
  }${num.substring(3, 6)}${num.substring(7) ? "-" : ""}${num.substring(6, 10)}`;
};

/**
 * Formats Time for display --> XX:XX AM
 * @param {string} arg The military time the user entered on the front-end.
 */
const formatTime = (num) => {
  if (num.includes("am") || num.includes("pm")) {
    return num;
  }

  var time = num.split(":"); // convert to array

  // Format
  var hours = Number(time[0]);
  var minutes = Number(time[1]);

  // Calc
  var newTime;

  if (hours > 0 && hours <= 12) {
    newTime = "" + hours;
  } else if (hours > 12) {
    newTime = "" + (hours - 12);
  } else if (hours === 0) {
    newTime = "12";
  }

  if (minutes === 0) {
    newTime += ":00";
  } else if (minutes < 10) {
    newTime += ":0" + minutes;
  } else {
    newTime += ":" + minutes;
  }

  newTime += hours >= 12 ? " P.M." : " A.M."; // get AM/PM

  return newTime;
};

/**
 * If localhost, use localhost
 */
const getCurrentURl = () => {
  const url = window.location.href;
  if (url.includes("localhost")) {
    return `http://localhost:3001/`;
  } else {
    return `https://cornerstonesurgeryapp.com/`;
  }
};

module.exports = {
  formatPhone,
  replaceSpecialChar,
  formatTime,
  getCurrentURl,
};
