import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import {
  addFile,
  getAssignmentOfBenefits,
  updateAssignmentOfBenefits,
} from "../../../utils/api";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";

const AssignmentofBenefits = () => {
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [patientSigDate, setPatientSigDate] = useState("");
  const [individualSignature, setIndividualSignature] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    let fileName;

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);
      const data = await response.json();
      fileName = await data.fileName;
    }

    const payload = {
      patient_signature: fileName,
      patient_signature_date: patientSigDate,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log(payload);

    // Fetch 2: Update the Assignment of Benefits Model
    updateAssignmentOfBenefits(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAssignmentOfBenefits(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setPatientSigDate(data.patient_signature_date);
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <h3 className="form-title">Assignment of Benefits</h3>
      <div className="form-content">
        <h4 className="form-title">Anethesia Services</h4>
        <form onSubmit={(e) => handleSubmit(e)}>
          <p>Dear Patient,</p>
          <p>
            {" "}
            Thank you for choosing SEA Surgery Center. Our primary concern is
            patient well-being, and we strive to give our patients the necessary
            treatment regardless of the type of insurance they may have.
          </p>
          <p>
            {" "}
            SEA Surgery Center is a non-contracted Ambulatory Surgical Center,
            as a courtesy we will bill your insurance carrier for our services.
            Anesthesia will be billed by the Anesthesia provider and is not
            included in the charges for our claim.
          </p>
          <p className="text-underline">
            It is possible that you receive a bill for the anethesia services,
            depending on your insurance coverage
          </p>
          <p className="text-underline text-center">
            Anethesia will be provided by:
          </p>
          <p className="text-underline text-center">Daydream Anethesiology</p>
          <p>
            By law, we are required to secure your signature indicating you have
            read and understand this notification document. If you have any
            questions about any of the matters discussed in this document,
            please do not hesitate to ask for further clarification.
          </p>
          <p>
            <strong>
              I HAVE READ AND UNDERSTAND THE PATIENT NOTIFICATION FOR ANESTHESIA
              SERVICES.
            </strong>
          </p>
          <div className="grid-2">
            <div className="signature-container mt-50">
              <SignatureButton
                individualSignature={individualSignature}
                setIndividualSignature={setIndividualSignature}
              />
              <hr className="signature-line"></hr>
              <p>Signature of Patient or Responsible Party</p>
            </div>

            <div className="mt-50">
              <label htmlFor="name" className="hidden">
                Patient Name
              </label>
              <input
                type="date"
                id="patient_signature_date"
                name="patient_signature_date"
                className="bottom-border-input"
                required
                value={patientSigDate || ""}
                onChange={(e) => setPatientSigDate(e.target.value)}
              />

              <p>Date</p>
            </div>
          </div>
          {/* <div className="grid-2">
            <div className="signature-container mt-50">
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>SEA Surgery Center Staff Witness</p>
            </div>

            <div className="mt-50">
              <label htmlFor="name" className="hidden">
                Patient Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
                required
              />

              <p>Date</p>
            </div>
          </div> */}
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default AssignmentofBenefits;
