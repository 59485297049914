import React, { useState, useEffect } from "react";
import "./Profile.css";
import {
  udpateUser,
  addFile,
  getUserProfile,
  updateTokenImage,
} from "../../../utils/api";
import Auth from "../../../utils/auth";
import BackgroundImage from "../../../components/BackgroundImage";
import profilePic from "../../../assets/images/guest.webp";

const Profile = () => {
  // User Type States
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role: "",
    pin: "",
    password: "",
    image: "",
  });
  const [dbUser, setDBUser] = useState({});
  // Holds uploaded profile image
  const [profileImage, setProfileImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // Run Query Once
  useEffect(() => {
    const user = Auth.getUser();
    if (user && user.id) {
      setIsLoading(true);
      getUserProfile(user.id)
        .then((res) => res.json())
        .then((data) => {
          setDBUser({
            first_name: data.first_name || "",
            last_name: data.last_name || "",
            email: data.email || "",
            role: data.role || "",
            pin: data.pin || "",
            password: data.password || "",
            image: data.image || "",
          });
          setUserData({
            first_name: data.first_name || "",
            last_name: data.last_name || "",
            email: data.email || "",
            role: data.role || "",
            pin: data.pin || "",
            password: data.password || "",
            image: data.image || "",
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);
  /*
    Three fetch requests due to different headers types + content:
    1. addFile: uploads the profile image and returns the new file name
    2. udpateUser: updates the user model with form data (+ new file name if applicable)
    3. updateTokenImage: updates the token with new image for display in the header
  */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const user = Auth.getUser();
    if (!user || !user.id) {
      return;
    }

    // Skip
    // Requests to handle updating the User and Instructor Models
    const handleUpdateUser = async (fileName) => {
      const newUserData = {};

      // IF a new image is uploaded, add the image name to the user model
      if (fileName !== undefined) {
        newUserData.image = fileName;
      }

      // Validate that the fields have changed before submitting
      if (
        userData.first_name !== "" &&
        userData.first_name !== dbUser.first_name
      ) {
        newUserData.first_name = userData.first_name;
      }
      if (
        userData.last_name !== "" &&
        userData.last_name !== dbUser.last_name
      ) {
        newUserData.last_name = userData.last_name;
      }
      if (userData.email !== "" && userData.email !== dbUser.email) {
        newUserData.email = userData.email;
      }
      if (userData.role !== "" && userData.role !== dbUser.role) {
        newUserData.role = userData.role;
      }
      if (userData.password !== "" && userData.password !== dbUser.password) {
        newUserData.password = userData.password;
      }
      if (userData.pin !== "" && userData.pin !== dbUser.pin) {
        newUserData.pin = userData.pin;
      }

      // // Only update User IF there is new data to update else 400 error will be thrown
      if (Object.keys(newUserData).length === 0) {
        return;
      } else {
        // Fetch 2: Update the User model's data
        const userResponse = await udpateUser(newUserData, user.id);

        // IF a new image is uploaded, generate a new token
        if (fileName !== undefined) {
          const userData = await userResponse.json();
          console.log(userData);

          // Fetch 3: Update the User's token with the new image
          const response = await updateTokenImage(user.id);
          const data = await response.json();
          const { token } = await data;

          // If successfull, save the new token
          Auth.login(token);
        }
      }
    };

    // Handle profile image first
    if (profileImage !== null) {
      // Fetch 1: Upload profile photo
      const response = await addFile(profileImage);
      const data = await response.json();
      const fileName = await data.fileName;
      // Complete user fetch with the new file name
      handleUpdateUser(fileName).then(() => {
        window.location.reload();
      });
    } else {
      handleUpdateUser().then(() => {
        window.location.reload();
      });
    }
  };

  const handleImageChange = (e) => {
    // Don't update state if the user clicks "cancel"
    if (!e.target.files[0]) {
      return;
    }
    // Format File for fetch request
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    setProfileImage(formData);
  };

  return (
    <div className="create-grid-container">
      <h1 className="visually-hidden">Profile Management</h1>
      <header className="first-column"></header>
      <main className="second-column">
        <BackgroundImage />
        <div className="form-container">
          <form
            className="profile-container"
            encType="multipart/form-data"
            onSubmit={handleFormSubmit}
          >
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <div className="profile-card">
                <div className="profile-avatar">
                  <img
                    accept="image/png, image/jpeg"
                    name="file"
                    src={
                      !userData.image
                        ? profilePic
                        : `/uploads/${userData.image}`
                    }
                    alt="Profile"
                  />
                  <input
                    type="file"
                    id="file-input"
                    className="edit-button"
                    accept="image/png, image/jpeg"
                    name="file"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    label="profile-image"
                  />
                  <label htmlFor="file-input" className="edit-button">
                    Edit
                  </label>
                </div>
                <div className="profile-form">
                  <div className="form-row">
                    <div className="profile-form-group">
                      <label className="profile-text-label" for="first-name">
                        Name
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        id="first-name"
                        value={userData.first_name}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            first_name: e.target.value,
                          })
                        }
                      />
                      <label className="under-text-label">First Name</label>
                    </div>
                    <div className="profile-form-group">
                      <label className="profile-text-label">
                        {" "}
                        &nbsp; &nbsp;
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        id="last-name"
                        value={userData.last_name}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            last_name: e.target.value,
                          })
                        }
                        required
                      />
                      <label className="under-text-label" for="last-name">
                        Last Name
                      </label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="profile-form-group">
                      <label className="profile-text-label" for="role">
                        Role
                      </label>
                      <select
                        className="signup-dropdown"
                        name="role"
                        id="role"
                        value={userData.role}
                        onChange={(e) =>
                          setUserData({ ...userData, role: e.target.value })
                        }
                      >
                        <option value="">Role</option>
                        <option value="Doctor">Doctor</option>
                        <option value="Anesthesiologist">
                          Anesthesiologist
                        </option>
                        <option value="RN">RN (Registered Nurse)</option>
                        <option value="LVN">
                          LVN (Licensed Vocational Nurse)
                        </option>
                        <option value="Tech">Tech</option>
                      </select>
                    </div>
                    <div className="profile-form-group">
                      <label className="profile-text-label" for="pin">
                        Update PIN
                      </label>
                      <input
                        type="password"
                        name="pin"
                        id="pin"
                        value={userData.pin}
                        onChange={(e) =>
                          setUserData({ ...userData, pin: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="profile-form-group">
                      <label className="profile-text-label" for="email">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={userData.email}
                        onChange={(e) =>
                          setUserData({ ...userData, email: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="profile-form-group">
                      <label
                        className="profile-text-label"
                        for="update-password"
                      >
                        Update Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="update-password"
                        value={userData.password}
                        onChange={(e) => {
                          if (e.target.value.trim() === "") {
                            setUserData({ ...userData, password: "" });
                          } else {
                            setUserData({
                              ...userData,
                              password: e.target.value.trim(),
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-actions">
                    <button type="submit" className="save-button">
                      Save
                    </button>
                    <button
                      type="button"
                      className="logout-button"
                      onClick={() => Auth.logout()}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </main>
    </div>
  );
};

export default Profile;
