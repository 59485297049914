import React from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";
const NoticeofPrivacy = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <h2 className="form-title">NOTICE OF PRIVACY PRACTICES</h2>
      <div className="form-content">
        <form>
          <p className="text-center">
            <strong>ACKNOWLEDGEMENT OF RECEIPT</strong>
          </p>
          <p className="text-center">
            <strong>OF</strong>
          </p>
          <p className="text-center">
            <strong>NOTICE OF PRIVACY PRACTICES</strong>
          </p>
          <p className="mt-50">
            I acknowledge that I was provided a copy of the Notice of Privacy
            Practices and that I have read, or have had the opportunity to read,
            this Notice and I understand the notice.
          </p>
          <div className="privacy-container">
            <div className="privacy-item">
              {" "}
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Patient Name(Please Print)</p>
            </div>
            <div className="privacy-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Patient Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
                required
              />
              <p>Date</p>
            </div>
            <div className="privacy-item">
              {" "}
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Patient's or Authorized Representative's Signature</p>
            </div>
            <div className="privacy-item">
              {" "}
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Authorized Representative(Please print if applicable)</p>
            </div>
            <div className="privacy-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Relationship to Patient
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
                required
              />
              <p>Relationship to Patient</p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default NoticeofPrivacy;
