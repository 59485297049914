import React from "react";
import "./pendingpaperwork.css";


const PendingPaperwork = () => {
  return (
    <div className="paperwork-container">
      <h1>PendingPaperwork</h1>
    </div>
  );
};

export default PendingPaperwork;
