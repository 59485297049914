import React from "react";
import Logo from "../../assets/images/navbar_logo.webp";
import "./Nav.css"

const PatientNav = () => {
  return (
    <div className="patient-nav">
      <img src={Logo} className="patient-nav-logo"/>
    </div>
  );
};

export default PatientNav;
