import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./pinmodal.css";
import PatientNav from "../../../components/Nav/PatientNav";
import Auth from "../../../utils/auth";
import { userRole } from "../../../utils/api";

const PinModal = () => {
  const { id: patient_id } = useParams();
  const [userPin, setUserPin] = useState("");
  const [enteredPin, setEnteredPin] = useState(Array(6).fill(""));
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  useEffect(() => {
    const user = Auth.getUser();
    if (user && user.id) {
      userRole(user.id)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setUserPin(data.pin);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  // Handle input change
  const handleInputChange = (value, index) => {
    const updatedPin = [...enteredPin];
    updatedPin[index] = value;
    setEnteredPin(updatedPin);

    // Move to next input if a number is entered
    if (value && index < enteredPin.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Handle continue button click
  const handleContinue = () => {
    const fullEnteredPin = enteredPin.join("");
    if (fullEnteredPin === userPin) {
      navigate(`/patient/${patient_id}`);
    } else {
      setErrorMessage("Incorrect PIN entered. Please try again.");
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <PatientNav />
      <div className="pin-modal-container">
        <div className="pin-container">
          <button className="pin-close-btn">
            <Icon icon="carbon:close-outline" className="pin-icon" />
          </button>
          <h2>Enter your PIN</h2>
          <div className="pin-inputs">
            {enteredPin.map((pin, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                maxLength="1"
                value={pin}
                onChange={(e) => handleInputChange(e.target.value, index)}
              />
            ))}
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button className="continue-btn" onClick={handleContinue}>
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default PinModal;
