import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import "./nursingQuestionnaire.css";
import { updateSurgeryInfoById } from "../../../utils/api";

// Reusable BottomQuestion Component
const BottomQuestion = ({ question, ariaId, nqHistory, setNqHistory }) => {
  //console.log(nqHistory);
  const { id, surgeryInfo_id } = useParams();

  // Initialize text if it's not "0", otherwise empty string
  let text = question.text !== "0" ? question.text : "";
  const [selectedOption, setSelectedOption] = useState(false);
  const [dbValue, setDbValue] = useState({
    value: question.value,
    text: text,
  });

  const [additionalInfo, setAdditionalInfo] = useState(text || "");
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  // Effect to update selected option and database values when the question changes
  useEffect(() => {
    setSelectedOption(question.value);
    setDbValue({ value: question.value, text: question.text });
    setAdditionalInfo(question.text || "");
  }, [question]);

  // Handle changes to the Yes/No radio buttons
  const handleRadioChange = (e) => {
    const value = e.target.value === "true";
    setSelectedOption(value);
    // Enable the update button if the selected option differs from the database value
    value !== dbValue.value ? setCanUpdate(true) : setCanUpdate(false);
  };

  // Handle changes to the additional information text input
  const handleInputChange = (e) => {
    setAdditionalInfo(e.target.value);
    e.target.value !== dbValue.text ? setCanUpdate(true) : setCanUpdate(false);
  };

  // Function to update the Nursing Questionnaire history in the database
  const addEntry = () => {
    const payload = {};

    if (selectedOption !== dbValue.value.toString()) {
      const column = `${question.db}`;
      payload[column] = selectedOption;
    }

    if (additionalInfo !== dbValue.text) {
      const column = `${question.db}_text`;
      payload[column] = additionalInfo;
    }

    const updatedHistory = {
      ...nqHistory,
      [question.db]: selectedOption,
      [`${question.db}_text`]: additionalInfo,
    };

    setNqHistory(updatedHistory);

    // Make the API call to update the Nursing Questionnaire history
    updateSurgeryInfoById(surgeryInfo_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCanUpdate(false);
        setErrMessage(false);
        setDbValue({
          value: selectedOption,
          text: additionalInfo,
        });
      })
      .catch((err) => {
        setErrMessage(true);
        console.log(err);
      });
  };

  return (
    <div className="bottom-question-row">
      <div className="bottom-question-container">
        <label htmlFor={ariaId}>{question.label}</label>
        <div className="radio-inline">
          <label>
            <input
              type="radio"
              value={true}
              checked={selectedOption === true}
              onChange={handleRadioChange}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              value={false}
              checked={selectedOption === false}
              onChange={handleRadioChange}
            />
            No
          </label>
        </div>
      </div>

      {selectedOption === true && question.label !== "ARE YOU PREGNANT?" && (
        <input
          type="text"
          id="answer"
          className="full-width-input"
          placeholder="Please provide additional information"
          value={additionalInfo}
          onChange={handleInputChange}
        />
      )}

      {canUpdate && (
        <button className="med-history-add-btn" onClick={() => addEntry()}>
          Update
        </button>
      )}

      {errMessage && <p className="err-message">Error updating history.</p>}
    </div>
  );
};

const BottomQuestionnaire = ({ nqHistory, nQ, setNqHistory }) => {
  // console.log(nqHistory);
  // console.log(nQ);
  if (nqHistory === undefined) {
    return <div>Loading...</div>;
  }

  const nqHistoryId = nQ.id;

  // Section 4 - Bottom Questions
  const bottomQuestions = [
    {
      id: 1,
      label: "Do you have SLEEP APNEA?",
      value: nqHistory.sleep_apnea || false,
      text: nqHistory.sleep_apnea_text || "",
      db: "sleep_apnea",
    },
    {
      id: 2,
      label: "Any allergies to LATEX or RUBBER products?",
      value: nqHistory.latex_allergy || false,
      text: nqHistory.latex_allergy_text || "",
      db: "latex_allergy",
    },
  ];

  return (
    <div className="bottom-questionnaire-container">
      <div className="bottom-question-section">
        <div className="bottom-question-grid">
          {bottomQuestions.map((question, index) => (
            <BottomQuestion
              key={question.id}
              question={question}
              nqHistoryId={nqHistoryId}
              ariaId={`bottomQuestions-question-${index}`}
              nqHistory={nqHistory}
              setNqHistory={setNqHistory}
            />
          ))}

          {/* Last Menstrual Period (above ARE YOU PREGNANT?) */}
          <div className="bottom-question-row">
            <div className="bottom-question-container">
              <label htmlFor="date-of-service">Last Menstrual Period?</label>
              <div className="n-a-checkbox">
                <input
                  type="checkbox"
                  id="n-a"
                  checked={nqHistory.menstrual_na || false}
                  onChange={(e) => {
                    setNqHistory({
                      ...nqHistory,
                      menstrual_na: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="n-a">N/A</label>
              </div>
              <div className="input-group">
                <input
                  type="date"
                  id="date-of-service"
                  className="pregnant"
                  value={nqHistory.last_menstrual || ""}
                  onChange={(e) => {
                    setNqHistory({
                      ...nqHistory,
                      last_menstrual: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          {/* ARE YOU PREGNANT? */}
          <BottomQuestion
            key="pregnant"
            question={{
              label: "ARE YOU PREGNANT?",
              value: nqHistory.pregnant || false,
              text: nqHistory.pregnant_text || "",
              db: "pregnant",
            }}
            nqHistoryId={nqHistoryId}
            setNqHistory={setNqHistory}
          />
        </div>
      </div>
    </div>
  );
};

export default BottomQuestionnaire;
