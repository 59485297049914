import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { replaceSpecialChar, formatPhone } from "../../../utils/helpers";
import auth from "../../../utils/auth";
import {
  getPatientById,
  getPatientInformation,
  addMedicine,
  addMedAllergy,
  addFoodAllergy,
  addPriorSurgery,
  addPharmacy,
  updateMedHistory,
  createBilling,
  createInsurance,
  createOop,
  createCommercial,
  createComp,
  createPip,
  getBilling,
} from "../../../utils/api";
// Components
import PatientFolderHeader from "../../../components/PatientFolderHeader";
import MedicationAllergies from "../../../components/NQ&PI/med-allergies";
import FoodAllergies from "../../../components/NQ&PI/food-allergies";
import Surgery from "../../../components/NQ&PI/surgery";
import CurrentMedications from "../../../components/NQ&PI/medications";
import Pharmacy from "./pharmacy";
import Billing from "./billing";
import MedHistoryQuestions from "./medHistory";
// Stylesheets
import "./patient-info.css";

const PatientInfo = () => {
  const { id } = useParams();
  const [selectedPatient, setSelectedPatient] = useState([]); // Patient Details (id's)
  const [pI, setpI] = useState([]); // "Patient Information" from the db

  const [medications, setMedications] = useState([]);
  const [pharmacy, setPharmacy] = useState([]);
  const [medHistory, setMedHistory] = useState([]);
  const [medAllergies, setMedAllergies] = useState([]);
  const [foodAllergies, setFoodAllergies] = useState([]);
  const [surgeries, setSurgeries] = useState([]);
  const [nfa, setNfa] = useState(false);
  const [nma, setNma] = useState(false);
  const [billings, setBillings] = useState([]);

  // Hold Updates to form (user input)
  const [newEntry, setNewEntry] = useState({
    medications: {
      name: "",
      dose: "",
      route: "",
      frequency: "",
      status: "Active",
    },
    medAllergies: { medication: "", reaction: "" },
    foodAllergies: { food: "", reaction: "" },
    surgeries: { surgery_name: "" },
    pharmacy: { name: "", phone_number: "", address: "" },
  });

  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch User Role and Check IF Admin
    const user = auth.getUser();
    if (!user || !user.id) {
      return;
    } else {
      if (user.role === "Admin") {
        setIsAdmin(true);
      }
    }

    // Fetch patient data by id
    getPatientById(id)
      .then((res) => res.json())
      .then((data) => {
        setSelectedPatient(data);
        // console.log(data);

        // Fetch Patient Information by id
        getPatientInformation(data.patientInformation.id)
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            // Save a copy of original data
            setpI(data);

            // Update Display State
            setMedications(data.medications);
            setMedAllergies(data.medAllergies);
            setFoodAllergies(data.foodAllergies);
            setSurgeries(data.priorSurgeries);
            setPharmacy(data.pharmacies);
            setMedHistory(data.medHistory);
            setNfa(data.medHistory.NKFA);
            setNma(data.medHistory.NKMA);
            setBillings(data.billings);

            localStorage.setItem(
              "med_history_id",
              JSON.stringify(data.medHistory.id)
            );

            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (e, type, field = null) => {
    const value = e.target.value;

    setNewEntry((prev) => ({
      ...prev,
      [type]: field ? { ...prev[type], [field]: value } : value,
    }));
  };

  const addEntry = (type) => {
    if (type === "medications") {
      newEntry.medications.patient_info_id = pI.id;

      addMedicine(newEntry.medications)
        .then((res) => res.json())
        .then((data) => {
          newEntry.medications.id = data.newMedications.id;
          setMedications((prev) => [...prev, newEntry.medications]);

          setNewEntry((prev) => ({
            ...prev,
            medications: {
              name: "",
              dose: "",
              route: "",
              frequency: "",
              status: "Active",
            },
          }));
        })
        .catch((err) => console.log(err));
    } else if (type === "surgeries") {
      newEntry.surgeries.patient_info_id = pI.id;

      addPriorSurgery(newEntry.surgeries)
        .then((res) => res.json())
        .then((data) => {
          newEntry.surgeries.id = data.newPriorSurgeries.id;

          setSurgeries((prev) => [...prev, newEntry.surgeries]);
          setNewEntry((prev) => ({ ...prev, surgeries: { surgery_name: "" } }));
        })
        .catch((err) => console.log(err));
    } else if (type === "medAllergies") {
      newEntry.medAllergies.patient_info_id = pI.id;

      addMedAllergy(newEntry.medAllergies)
        .then((res) => res.json())
        .then((data) => {
          newEntry.medAllergies.id = data.newMedAllergies.id;

          setMedAllergies((prev) => [...prev, newEntry.medAllergies]);
          setNewEntry((prev) => ({
            ...prev,
            medAllergies: { medication: "", reaction: "" },
          }));
        })
        .catch((err) => console.log(err));
    } else if (type === "foodAllergies") {
      newEntry.foodAllergies.patient_info_id = pI.id;

      addFoodAllergy(newEntry.foodAllergies)
        .then((res) => res.json())
        .then((data) => {
          newEntry.foodAllergies.id = data.newFoodAllergies.id;

          setFoodAllergies((prev) => [...prev, newEntry.foodAllergies]);
          setNewEntry((prev) => ({
            ...prev,
            foodAllergies: { food: "", reaction: "" },
          }));
        })
        .catch((err) => console.log(err));
    } else if (type === "pharmacy") {
      newEntry.pharmacy.patient_info_id = pI.id;

      addPharmacy(newEntry.pharmacy)
        .then((res) => res.json())
        .then((data) => {
          newEntry.pharmacy.id = data.newPharmacy.id;

          setPharmacy((prev) => [...prev, newEntry.pharmacy]);
          setNewEntry((prev) => ({
            ...prev,
            pharmacy: { name: "", phone_number: "", address: "" },
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  const handleMedHistory = (e, type) => {
    const value = e.target.checked;
    const payload = {};

    if (type === "medAllergies") {
      payload.NKMA = value;
    } else if (type === "foodAllergies") {
      payload.NKFA = value;
    }

    updateMedHistory(medHistory.id, payload)
      .then((res) => res.json())
      .then((data) => {
        setNma(value);
      })
      .catch((err) => console.log(err));
  };

  const postBilling = (type, billingPayload, typePayload) => {
    billingPayload.patient_info_id = pI.id;

    createBilling(billingPayload)
      .then((res) => res.json())
      .then((data) => {
        switch (type) {
          case "Insurance":
            typePayload.billing_id = data.newBilling.id;

            createInsurance(typePayload)
              .then((res) => res.json())
              .then((insData) => {
                getBilling(insData.newInsurance.billing_id)
                  .then((res) => res.json())
                  .then((data) => {
                    setBillings([...billings, data]);
                  });
              })
              .catch((err) => console.log(err));
            break;
          case "Oop":
            typePayload.billing_id = data.newBilling.id;

            createOop(typePayload)
              .then((res) => res.json())
              .then((insData) => {
                getBilling(insData.newOop.billing_id)
                  .then((res) => res.json())
                  .then((data) => {
                    setBillings([...billings, data]);
                  });
              })
              .catch((err) => console.log(err));
            break;
          case "Commercial":
            typePayload.billing_id = data.newBilling.id;

            createCommercial(typePayload)
              .then((res) => res.json())
              .then((insData) => {
                getBilling(insData.newComInsurance.billing_id)
                  .then((res) => res.json())
                  .then((data) => {
                    setBillings([...billings, data]);
                  });
              })
              .catch((err) => console.log(err));
            break;
          case "Pip":
            typePayload.billing_id = data.newBilling.id;

            createPip(typePayload)
              .then((res) => res.json())
              .then((insData) => {
                getBilling(insData.newPip.billing_id)
                  .then((res) => res.json())
                  .then((data) => {
                    setBillings([...billings, data]);
                  });
              })
              .catch((err) => console.log(err));
            break;
          case "Comp":
            typePayload.billing_id = data.newBilling.id;

            createComp(typePayload)
              .then((res) => res.json())
              .then((insData) => {
                getBilling(insData.newComp.billing_id)
                  .then((res) => res.json())
                  .then((data) => {
                    setBillings([...billings, data]);
                  });
              })
              .catch((err) => console.log(err));
            break;
          default:
            return;
        }
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PatientFolderHeader />
      <div className="patient-grid-container">
        <div className="patient-first-column"></div>
        <div className="patient-second-column patient-info-card">
          <div className="patient-info-form">
            {/* Current Medications */}
            <div className="medications-table-container">
              <h3 className="table-label">Current Medications</h3>
              <table className="medications-table">
                <thead>
                  <tr>
                    <th className="table-label">Name</th>
                    <th className="table-label">Dose</th>
                    <th className="table-label">Route</th>
                    <th className="table-label">Frequency</th>
                    <th className="table-label">Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {medications.map((med, index) => (
                    <CurrentMedications
                      med={med}
                      key={med.id}
                      medications={medications}
                      setMedications={setMedications}
                    />
                  ))}
                  <tr>
                    <td data-label="Name">
                      <input
                        type="text"
                        name="name"
                        value={newEntry.medications.name}
                        onChange={(e) =>
                          handleInputChange(e, "medications", "name")
                        }
                      />
                    </td>
                    <td data-label="Dose">
                      <input
                        type="text"
                        name="dose"
                        value={newEntry.medications.dose}
                        onChange={(e) =>
                          handleInputChange(e, "medications", "dose")
                        }
                      />
                    </td>
                    <td data-label="Route">
                      <input
                        type="text"
                        name="route"
                        value={newEntry.medications.route}
                        onChange={(e) =>
                          handleInputChange(e, "medications", "route")
                        }
                      />
                    </td>
                    <td data-label="Frequency">
                      <input
                        type="text"
                        name="frequency"
                        value={newEntry.medications.frequency}
                        onChange={(e) =>
                          handleInputChange(e, "medications", "frequency")
                        }
                      />
                    </td>
                    <td data-label="Status" className="nowrap">
                      <select
                        name="status"
                        value={newEntry.medications.status}
                        onChange={(e) =>
                          handleInputChange(e, "medications", "status")
                        }
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </td>
                    <td data-label="" className="flex-center">
                      <button
                        className="medication-add-btn"
                        onClick={() => addEntry("medications")}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Medication Allergies */}
            <div className="medications-table-container">
              <div className="allergy-heading">
                <h3 className="table-label">Medication Allergies</h3>
                {medAllergies.length === 0 ? (
                  <label>
                    <input
                      type="checkbox"
                      checked={nma}
                      onChange={(e) => {
                        handleInputChange(e, "medAllergies", "nka");
                        handleMedHistory(e, "medAllergies");
                      }}
                    />
                    NKA
                  </label>
                ) : (
                  <></>
                )}
              </div>
              <table
                className={
                  nma ? "medications-table dimmed" : "medications-table"
                }
              >
                <thead>
                  <tr>
                    <th className="table-label">Medication</th>
                    <th className="table-label">Reaction</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {medAllergies.map((allergy, index) => (
                    <MedicationAllergies
                      key={allergy.id}
                      allergy={allergy}
                      setMedAllergies={setMedAllergies}
                      medAllergies={medAllergies}
                    />
                  ))}
                  <tr>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Medication:</label>
                      <input
                        type="text"
                        name="medication"
                        value={newEntry.medAllergies.medication}
                        onChange={(e) =>
                          handleInputChange(e, "medAllergies", "medication")
                        }
                      />
                    </td>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Reaction:</label>
                      <input
                        type="text"
                        name="reaction"
                        value={newEntry.medAllergies.reaction}
                        onChange={(e) =>
                          handleInputChange(e, "medAllergies", "reaction")
                        }
                      />
                    </td>
                    <td className="flex-center">
                      <button
                        className="medication-add-btn"
                        onClick={() => addEntry("medAllergies")}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Food Allergies */}
            <div className="medications-table-container">
              <div className="allergy-heading">
                <h3 className="table-label">Food Allergies</h3>
                {foodAllergies.length === 0 ? (
                  <label>
                    <input
                      type="checkbox"
                      checked={nfa}
                      onChange={(e) => {
                        handleInputChange(e, "foodAllergies", "nka");
                        handleMedHistory(e, "foodAllergies");
                      }}
                    />{" "}
                    NKA
                  </label>
                ) : (
                  <></>
                )}
              </div>
              <table
                className={
                  nfa ? "medications-table dimmed" : "medications-table"
                }
              >
                <thead>
                  <tr>
                    <th className="table-label">Food</th>
                    <th className="table-label">Reaction</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {foodAllergies.map((allergy, index) => (
                    <FoodAllergies
                      key={allergy.id}
                      allergy={allergy}
                      setFoodAllergies={setFoodAllergies}
                      foodAllergies={foodAllergies}
                    />
                  ))}
                  <tr>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Food:</label>
                      <input
                        type="text"
                        name="food"
                        value={newEntry.foodAllergies.food}
                        onChange={(e) =>
                          handleInputChange(e, "foodAllergies", "food")
                        }
                      />
                    </td>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Reaction:</label>
                      <input
                        type="text"
                        name="reaction"
                        value={newEntry.foodAllergies.reaction}
                        onChange={(e) =>
                          handleInputChange(e, "foodAllergies", "reaction")
                        }
                      />
                    </td>
                    <td className="flex-center">
                      <button
                        className="medication-add-btn"
                        onClick={() => addEntry("foodAllergies")}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Prior Surgeries */}
            <div className="surgeries-table-container">
              <h3 className="table-label">Prior Surgeries</h3>
              <table className="surgeries-table">
                <tbody>
                  {surgeries.map((surgery, index) => (
                    <Surgery
                      surgery={surgery}
                      key={surgery.id}
                      surgeries={surgeries}
                      setSurgeries={setSurgeries}
                    />
                  ))}
                  <tr>
                    <td className="surg-col-width">
                      <input
                        type="text"
                        placeholder="Enter surgery"
                        value={newEntry.surgeries.surgery_name}
                        onChange={(e) =>
                          handleInputChange(e, "surgeries", "surgery_name")
                        }
                      />
                    </td>
                    <td className="surgery-actions">
                      <button
                        className="surgery-add-btn"
                        onClick={() => addEntry("surgeries")}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Symptom Input Fields */}
            <div className="medical-conditions-container">
              <MedHistoryQuestions medHistory={medHistory} />
            </div>

            {/* Billing Section */}
            <div className="billing-type-container">
              <Billing
                isAdmin={isAdmin}
                billings={billings}
                setBillings={setBillings}
                postBilling={postBilling}
              />
            </div>

            {/* Preferred Pharmacy */}
            <div className="insurance-table-container">
              <h3 className="table-label">Preferred Pharmacy</h3>
              <table className="insurance-table">
                <thead>
                  <tr>
                    <th className="table-label">Name</th>
                    <th className="table-label">Phone Number</th>
                    <th className="table-label">Address</th>
                  </tr>
                </thead>
                <tbody>
                  {pharmacy.map((pharm, index) => (
                    <Pharmacy
                      key={pharm.id}
                      item={pharm}
                      setPharmacy={setPharmacy}
                      pharmacy={pharmacy}
                    />
                  ))}
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="pharmacyName"
                        value={newEntry.pharmacy.name}
                        onChange={(e) =>
                          handleInputChange(e, "pharmacy", "name")
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="phoneNumber"
                        value={newEntry.pharmacy.phone_number}
                        onChange={(e) => {
                          let num = replaceSpecialChar(e.target.value);
                          const phone = formatPhone(num);

                          const updatedPhone = {
                            ...newEntry.pharmacy,
                            phone_number: phone,
                          };

                          setNewEntry({
                            ...newEntry,
                            pharmacy: updatedPhone,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="address"
                        value={newEntry.pharmacy.address}
                        onChange={(e) =>
                          handleInputChange(e, "pharmacy", "address")
                        }
                      />
                    </td>
                    <td>
                      <button
                        className="medication-add-btn"
                        onClick={() => addEntry("pharmacy")}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientInfo;
