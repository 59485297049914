import React, { useState, useEffect } from "react";
import "./AdminApproval.css";
import { Icon } from "@iconify/react";
import { Link, useLocation } from "react-router-dom";
import { getPendingUsers, udpateUser } from "../../../utils/api";
import BackgroundImage from "../../../components/BackgroundImage";
import placeholder from "../../../assets/images/guest.webp";

const AdminApproval = () => {
  // Get the current location object
  const location = useLocation();
  // State to manage the menu's open/close status
  const [menuOpen, setMenuOpen] = useState(false);

  // Function to toggle the menu open or closed
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to get the active tab name based on the current URL path
  const getActiveTabName = () => {
    switch (location.pathname) {
      case "/admin-user-search":
        return "Search for Users";
      case "/admin-approval":
        return "Pending Approval";
      case "/admin-create":
        return "Create New User";
      default:
        return "";
    }
  };
  /** Track Users */
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);

  /** Run Query Once */
  useEffect(() => {
    getPendingUsers()
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setUsers(data);

        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <div className="grid-container">
        <div className="first-column"></div>
        <div className="second-column">
          <BackgroundImage />
          <div className="second-column-item-heading">
            {/* Heading */}
            <h2 className="search-heading">Manage Users</h2>
          </div>
          <div className="second-column-item-links">
            <div className="menu-header">
              {/* Hamburger menu for toggling */}
              <div className="hamburger-menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* Display the active tab name */}
              <div className="active-tab-name">{getActiveTabName()}</div>
            </div>
            {/* Menu items that toggle open/close */}
            <div className={`menu-items ${menuOpen ? "open" : ""}`}>
              <Link
                to="/admin-user-search"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-user-search" ? "active" : ""
                }`}
              >
                Search for Users
              </Link>
              <Link
                to="/admin-approval"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-approval" ? "active" : ""
                }`}
              >
                Pending Approval
              </Link>
              <Link
                to="/admin-create"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-create" ? "active" : ""
                }`}
              >
                Create New User
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Main container with a grid layout */}
      <div className="create-grid-container">
        <div className="first-column"></div>
        <div className="second-column">
          <BackgroundImage />

          <div className="second-column-item-heading">
            {/* Heading */}
            <h2 className="search-heading">Manage Users</h2>
          </div>
          <div className="second-column-item-links">
            <div className="menu-header">
              {/* Hamburger menu for toggling */}
              <div className="hamburger-menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* Display the active tab name */}
              <div className="active-tab-name">{getActiveTabName()}</div>
            </div>
            {/* Menu items that toggle open/close */}
            <div className={`menu-items ${menuOpen ? "open" : ""}`}>
              <Link
                to="/admin-user-search"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-user-search" ? "active" : ""
                }`}
              >
                Search for Users
              </Link>
              <Link
                to="/admin-approval"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-approval" ? "active" : ""
                }`}
              >
                Pending Approval
              </Link>
              <Link
                to="/admin-create"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-create" ? "active" : ""
                }`}
              >
                Create New User
              </Link>
            </div>
          </div>
          {/* Cards for approval */}
          <div className="approval-container">
            {/* Container for each approval card */}
            {users.map((user) => (
              <UserCard user={user} key={user.id} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const UserCard = ({ user }) => {
  const [showError, setShowError] = useState(false);

  const displayPhoto = (image) => {
    if (!image) {
      return placeholder;
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `/uploads/${image}`;
    }
  };

  return (
    <div className="admin-card-container-approval">
      <div className="profile-info-container">
        <div className="profile-image">
          <img
            src={displayPhoto(user.image)}
            alt="Profile"
            className="user-img-approval"
          />
        </div>
        <div className="profile-info-text-approval">
          {/* User's name */}
          <h3 className="card-heading-approval">
            {user.first_name} {user.last_name}
          </h3>
          <div className="info-item-approval">
            <Icon icon="ic:outline-email" />
            {/* Email  */}
            <p>{user.email}</p>
          </div>
          {/* User's job*/}
          <div className="info-item-approval">
            <Icon icon="ph:briefcase" />
            <p>{user.role}</p>
          </div>
          {/* Error Message */}
          {showError ? (
            <div className="info-item-approval">
              <p className="password-error">
                Something has gone wrong, please try again later.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="approval-btn-container">
        {/* Delete button */}
        <button
          className="delete-btn"
          onClick={(e) => {
            e.preventDefault();

            udpateUser({ isDeleted: true }, user.id)
              .then((res) => res.json())
              .then((data) => {
                // console.log(data);
                window.location.reload();
              })
              .catch((err) => {
                console.log(err);
                setShowError(true);
              });
          }}
        >
          Delete
        </button>
        {/* Approve button */}
        <button
          className="approve-btn"
          onClick={(e) => {
            e.preventDefault();

            udpateUser({ pendingApproval: false }, user.id)
              .then((res) => res.json())
              .then((data) => {
                // console.log(data);
                window.location.reload();
              })
              .catch((err) => {
                console.log(err);
                setShowError(true);
              });
          }}
        >
          Approve
        </button>
      </div>
    </div>
  );
};

export default AdminApproval;
