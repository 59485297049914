import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { udpateMedications, deleteMedications } from "../../utils/api";
const moment = require("moment");

const CurrentMedications = ({ med, medications, setMedications }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  const [dbmed, setDbMed] = useState({
    name: med.name,
    dose: med.dose,
    route: med.route,
    frequency: med.frequency,
    status: med.status,
  });

  const [medication, setMedication] = useState({
    name: med.name,
    dose: med.dose,
    route: med.route,
    frequency: med.frequency,
    status: med.status,
  });

  const handleInputChange = (e, type) => {
    const value = e.target.value;

    setMedication((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const updateEntry = () => {
    const payload = {};

    if (medication.name !== dbmed.name) {
      const column = `name`;
      payload[column] = medication.name;
    }
    if (medication.dose !== dbmed.dose) {
      const column = `dose`;
      payload[column] = medication.dose;
    }
    if (medication.route !== dbmed.route) {
      const column = `route`;
      payload[column] = medication.route;
    }
    if (medication.frequency !== dbmed.frequency) {
      const column = `frequency`;
      payload[column] = medication.frequency;
    }
    if (medication.status !== dbmed.status) {
      const column = `status`;
      payload[column] = medication.status;
    }

    if (Object.keys(payload).length !== 0) {
      // console.log(payload);

      udpateMedications(med.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbMed(medication);
          setIsEditing(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    const updated = [];

    medications.map((medication) => {
      if (medication.id !== med.id) {
        updated.push(medication);
      }
    });

    const payload = {
      date_removed: moment().format("MM/DD/YYYY"),
      isDeleted: true,
    };
    deleteMedications(med.id, payload)
      .then((res) => res.json())
      .then((data) => {
        setMedications(updated);
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(medication, dbmed) ? setCanUpdate(false) : setCanUpdate(true);
  }, [medication]);

  return (
    <>
      {isEditing === false ? (
        <tr>
          <td data-label="Name">{medication.name}</td>
          <td data-label="Dose">{medication.dose}</td>
          <td data-label="Route">{medication.route}</td>
          <td data-label="Frequency">{medication.frequency}</td>
          <td className="nowrap" data-label="Status">
            <span className={`status-badge ${medication.status.toLowerCase()}`}>
              {medication.status}
            </span>
          </td>
          <td className="edit-trash-container w-100" data-label="">
            <button
              className="medication-edit-btn"
              id="bj-edit-btn"
              aria-label="edit"
              onClick={() => setIsEditing(true)}
            >
              <Icon icon="typcn:edit" />
            </button>
            <button
              className="medication-delete-btn"
              id="bj-delete-btn"
              aria-label="delete"
              onClick={() => {
                handleDelete();
              }}
            >
              <Icon icon="bi:trash3" />
            </button>
          </td>
        </tr>
      ) : (
        <tr>
          <td data-label="Name">
            <input
              type="text"
              name="name"
              value={medication.name}
              onChange={(e) => handleInputChange(e, "name")}
            />
          </td>
          <td data-label="Dose">
            <input
              type="text"
              name="dose"
              value={medication.dose}
              onChange={(e) => handleInputChange(e, "dose")}
            />
          </td>
          <td data-label="Route">
            <input
              type="text"
              name="route"
              value={medication.route}
              onChange={(e) => handleInputChange(e, "route")}
            />
          </td>
          <td data-label="Frequency">
            <input
              type="text"
              name="frequency"
              value={medication.frequency}
              onChange={(e) => handleInputChange(e, "frequency")}
            />
          </td>
          <td data-label="Status" className="nowrap">
            <select
              name="status"
              value={medication.status}
              onChange={(e) => handleInputChange(e, "status")}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </td>
          {errMessage ? (
            <td data-label="">
              <p className="err-message">
                Something has gone wrong with your request.
              </p>
            </td>
          ) : (
            <td data-label="" className="flex-space-between w-100">
              <button
                className={
                  canUpdate
                    ? "medication-add-btn update"
                    : "medication-add-btn update disabled"
                }
                onClick={() => updateEntry()}
              >
                Update
              </button>
              <button
                className="medication-close-btn"
                id="bj-close-btn"
                aria-label="close"
                onClick={() => setIsEditing(false)}
              >
                <Icon icon="material-symbols:close" />
              </button>
            </td>
          )}
        </tr>
      )}
    </>
  );
};

export default CurrentMedications;
