export const getFormLink = (formName) => {
  switch (formName) {
    case "Anesthesia Questionnaire":
      return "/form-anethesia-ques";
    case "Assignement of Benefits":
      return "/form-assignment-benefits";
    case "Discharge Instructions":
      return "/form-discharge-instructions";
    case "Disclosure of Ownership":
      return "/form-disclosure-ownership";
    case "Grievance Policy":
      return "/form-grievance";
    case "Notice of Privacy Practice":
      return "/form-privacy";
    case "Consent To Anesthesia":
      return "/form-consent-anethesia";
    case "Patient History":
      return "/form-patient-history";
    case "Patient Registration":
      return "/form-registration";
    case "Patient Satisfaction Survey":
      return "/form-satisfaction";
    default:
      return "/form-anethesia-ques";
  }
};
