import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { udpateFoodAllergies, deleteFoodAllergies } from "../../utils/api";
const moment = require("moment");

const FoodAllergies = ({ allergy, foodAllergies, setFoodAllergies }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  const [dbFood, setDbFood] = useState({
    food: allergy.food,
    reaction: allergy.reaction,
  });

  const [food, setFood] = useState({
    food: allergy.food,
    reaction: allergy.reaction,
  });

  const handleInputChange = (e, type) => {
    const value = e.target.value;

    setFood((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const updateEntry = () => {
    const payload = {};

    if (food.food !== dbFood.food) {
      const column = `food`;
      payload[column] = food.food;
    }
    if (food.reaction !== dbFood.reaction) {
      const column = `reaction`;
      payload[column] = food.reaction;
    }

    if (Object.keys(payload).length !== 0) {
      // console.log(payload);

      udpateFoodAllergies(allergy.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbFood(food);
          setIsEditing(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    console.log(allergy.id);

    const updated = [];

    foodAllergies.map((item) => {
      if (item.id !== allergy.id) {
        updated.push(item);
      }
    });

    const payload = {
      date_removed: moment().format("MM/DD/YYYY"),
      isDeleted: true,
    };
    deleteFoodAllergies(allergy.id, payload)
      .then((res) => res.json())
      .then((data) => {
        setFoodAllergies(updated);
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(food, dbFood) ? setCanUpdate(false) : setCanUpdate(true);
  }, [food]);

  return (
    <>
      {isEditing === false ? (
        <tr>
          <td className="medications-col-width">{dbFood.food}</td>
          <td className="medications-col-width">{dbFood.reaction}</td>
          <td className="edit-trash-container w-100">
            <button
              className="medication-edit-btn"
              id="bj-edit-btn"
              aria-label="edit"
            >
              <Icon icon="typcn:edit" onClick={() => setIsEditing(true)} />
            </button>
            <button
              className="medication-delete-btn"
              id="bj-delete-btn"
              aria-label="delete"
              onClick={() => {
                handleDelete();
              }}
            >
              <Icon icon="bi:trash3" />
            </button>
          </td>
        </tr>
      ) : (
        <tr>
          <td className="medications-col-width">
            <label style={{ display: "none" }}>Food:</label>
            <input
              type="text"
              name="food"
              value={food.food}
              onChange={(e) => handleInputChange(e, "food")}
            />
          </td>
          <td className="medications-col-width">
            <label style={{ display: "none" }}>Reaction:</label>
            <input
              type="text"
              name="reaction"
              value={food.reaction}
              onChange={(e) => handleInputChange(e, "reaction")}
            />
          </td>
          <td className="flex-space-between w-100">
            <button
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => updateEntry()}
            >
              Update
            </button>
            <button
              className="medication-close-btn"
              id="bj-close-btn"
              aria-label="close"
              onClick={() => setIsEditing(false)}
            >
              <Icon icon="material-symbols:close" />
            </button>
          </td>
        </tr>
      )}
    </>
  );
};

export default FoodAllergies;
