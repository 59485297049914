import React, { useEffect, useState } from "react";
import PatientNav from "../../../components/Nav/PatientNav";
import { Icon } from "@iconify/react";
import "./thankyou.css";
import { Link, useParams } from "react-router-dom";
import { getPatientById } from "../../../utils/api";

const ThankYou = () => {
  const { id: patient_id, surgery_id } = useParams();
  const [patientData, setPatientData] = useState(null);

  useEffect(() => {
    if (patient_id) {
      getPatientById(patient_id)
        .then((res) => {
          if (!res.ok) {
            throw new Error("Failed to fetch patient data");
          }
          return res.json();
        })
        .then((data) => {
          setPatientData(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [patient_id]);

  return (
    <>
      <PatientNav />
      <section className="thank-you-container">
        <div>
          <Icon icon="akar-icons:check" className="thank-you-icon" />
        </div>
        <h1>Thank You!</h1>
        <p className="thank-you-text">
          Your information was successfully submitted.
        </p>
        <p className="thank-you-text text-two">
          Please hand the tablet back to medical staff.
        </p>
        {/* Link to PinModal */}
        <Link
          to={`/patient/${patient_id}/patient-forms/${surgery_id}/pin-modal`}
        >
          <button className="thank-you-btn">Continue</button>
        </Link>
      </section>
    </>
  );
};

export default ThankYou;
