import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import {
  addFile,
  getDisclosureofOwnership,
  updateDisclosureOfOwnership,
} from "../../../utils/api";
import { useParams } from "react-router-dom";
import moment from "moment";
import SignatureButton from "../../../components/SignatureButton";
import "./styles.css";

const DisclosureofOwnership = () => {
  const { form_id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [policyAcceptance, setPolicyAcceptance] = useState();
  const [advancedDirective, setAdvancedDirective] = useState();
  const [individualSignature, setIndividualSignature] = useState("");
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Fetch 1: Upload Signature
    const response = await addFile(individualSignature);
    const data = await response.json();
    const fileName = await data.fileName;

    const payload = {
      advanced_directive: advancedDirective,
      policy_accepted: policyAcceptance,
      patient_signature: fileName,
      patient_name: name,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log(payload);

    // Fetch 2: Update the Disclosure of Ownerhsip Model
    updateDisclosureOfOwnership(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDisclosureofOwnership(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          if (data.advanced_directive === false) {
            setAdvancedDirective(false);
          } else {
            setAdvancedDirective(data.advanced_directive || null);
          }
          if (data.policy_accepted === false) {
            setPolicyAcceptance(false);
          } else {
            setPolicyAcceptance(data.policy_accepted || null);
          }
        }

        setName(data.patient_name || "");

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <h1 className="form-title">Disclosure of Ownership</h1>

      <div className="form-content">
        <div>
          <form>
            <div>
              <input
                type="radio"
                id="yes"
                name="financial_interest"
                value="yes"
                defaultChecked
              />
              <label htmlFor="yes">
                Jack Shohet, MD has financial interest in this facility
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="no"
                name="financial_interest"
                value="no"
                disabled
              />
              <label htmlFor="no">
                Jack Shohet, MD does not have financial interest in this
                facility
              </label>
            </div>
          </form>
        </div>

        <div>
          <h2>PATIENT RIGHTS</h2>
          <ul>
            <li>
              The patient has the right to be informed of his/her rights in
              advance of receiving care. The patient may appoint a
              representative to receive this information should he/she so
              desire.
            </li>
            <li>
              Exercise these rights without regard to sex, cultural, economic,
              education, religious background or the source of payment for care.
            </li>
            <li>
              Considerate, respectful and dignified care, provide in a safe
              environment, free from all forms of abuse, neglect, harassment
              and/or exploitation. Access protective and advocacy services or
              have these services accessed on the patient&apos;s behalf.
            </li>
            <li>Appropriate assessment and management of pain.</li>
            <li>
              Knowledge of the name of the physician who has primary
              responsibility for coordinating his/her care and the names of
              professional relationships of other physicians and healthcare
              providers who will see them. The patient has a right to change
              providers if other qualified providers are available.
            </li>
            <li>
              Be advised if the physician has a financial interest in the
              surgery center.
            </li>
            <li>
              Receive complete information from his/her physician about his/her
              illness, course of treatment, alternative treatments, outcomes of
              care (including unanticipated outcomes), and prospects for
              recovery in terms he/shecan understand.
            </li>
            <li>
              Receive as much information about any proposed treatment or
              procedure as he/she may need in order to give informed consent or
              to refuse the course of treatment. Except in emergencies, this
              information shall include a description of the procedure or
              treatment, the medically significant risks involved in the
              treatment, alternate courses of treatment or non-treatment and the
              risks involved in each and the name of the person who will carry
              out the procedure or treatment.
            </li>
            <li>
              Participate in the development and implementation of his/her plan
              of care and actively participate in decisions regarding his/her
              medical care. To the extent permitted by law, this includes the
              right to request and/or refuse treatment.
            </li>
            <li>
              Be informed of the facility&apos;s policy and state regulations
              regarding advance directives and be provided advance directive
              forms if requested.
            </li>
            <li>
              Full consideration of privacy concerning his/her medical care.
              Case discussion, consultation, examination and treatment are
              confidential and should be conducted discreetly. The patient has
              the right to be advised as to the reason for the presence of any
              individual involved in his/her health care.
            </li>
            <li>
              Confidential treatment of all communications and records
              pertaining to his/her care and his/her stay at the facility.
              His/her written permission will be obtained before medical records
              can be made available to anyone not directly concerned with their
              care.
            </li>
            <li>
              Receive information in a manner he/she understands. Communications
              with the patient wil be effective and provided in a manner that
              facilitates understanding by the patient. Written information
              provided will be appropriate to the age, understanding and, as
              appropriate, the language of the patient. As appropriate,
              communications specific to the vision, speech, hearing, cognitive
              and language-impaired patient will be appropriate to the
              impairment.
            </li>
            <li>
              Access information contained in his/her medical record within a
              reasonable time frame.
            </li>
            <li>
              Be advised of the facility&apos;s grievance process, should he/she
              wish to communicate a concern regarding the quality of the care
              they received. Notification of the grievance process includes:
              whom to contact to file a grievance, and that he/she will be
              provided with a written notice of the grievance determination that
              contains the name of the facility&apos;s contact person, the steps
              taken on his/her behalf to investigate the grievance, the results
              of the grievance and the grievance completion date.
            </li>
            <li>
              Be advised of contact information for the state agency to which
              complaints can be reported, as well as contact information for the
              Office of the Medicare Beneficiary Ombudsman.
            </li>
            <li>
              Be advised if facility/personal physician proposes to engage in or
              perform human experimentation affecting their care or treatment.
              The patient has the right to refuse to participate in such
              research projects. Refusal to participate or discontinuation of
              participation will not compromise the patient&apos;s right to
              access care, treatment or services.
            </li>
            <li>
              Full support and respect of all patient rights should be patient
              choose to participate in research, investigation and/or clinical
              trials. This includes the patient&apos;s right to a full informed
              consent process as it relates to the research, investigation
              and/or clinical trial. All information provided to subjects will
              be contained in the medical record or research file, along with
              the consent form(s).
            </li>
            <li>
              Be informed by his/her physician or a delegate thereof of the
              continuing healthcare requirement following his/her discharge from
              the facility.
            </li>
            <li>
              Examine and receive an explanation of his/her bill regardless of
              source of payment.
            </li>
            <li>
              Know which facility rules and policies apply to his/her conduct
              while apatient. Have all patient rights apply to the person who
              may have legal responsibility to make decisions regarding medical
              care on behalf of the patient.
            </li>
          </ul>
        </div>

        <div>
          <h2>PATIENTS' RESPONSIBILITIES:</h2>
          <ul>
            <li>
              The patient has the responsibility to provide accurate and
              complete information concerning his/her present complaints, past
              illnesses, hospitalizations, medications (including over the
              counter products and dietary supplements), allergies and
              sensitivities and other matters relating to his/her health. The
              patient and family are responsible for asking questions when they
              do not understand what they have been told about the
              patient&apos;s care or what they are expected to do.
            </li>
            <li>
              The patient is responsible for following the treatment plan
              established by his/her physician, including the instructions of
              nurses and other health professionals as they carry out the
              physician&apos;s orders.
            </li>
            <li>
              The patient is responsible for keeping appointments and for
              notifying the facility or physician when he/she is unable to do
              so.
            </li>
            <li>
              Provide a responsible adult to transport him/her home from the
              facility and remain with him/her for 24 hours unless exempted from
              that requirement by the attending physician.
            </li>
            <li>
              The patient is responsible for his/her own actions should
              treatment be refused or if physician&apos;s orders are not
              followed.
            </li>
            <li>
              The patient is responsible for assuring that the financial
              obligations of his/her care are fulfilled as promptly as possible.
              The patient is responsible for following facility policies and
              procedures.
            </li>
            <li>
              The patient is responsible to inform the facility about the
              patient&apos;s advance directive decision.
            </li>
            <li>
              The patient is responsible for being considerate of the rights of
              other patients and facility personnel.
            </li>
            <li>
              The patient is responsible for being respectful of his/her
              personal property and that of other persons in the facility.
            </li>
          </ul>
        </div>

        <div>
          <h2>ADVANCE DIRECTIVE NOTIFICATION:</h2>
          <ul>
            <li>
              All patients have the right to participate in their own healthcare
              decisions and to make Advance Directives or to execute Power of
              Attorney that authorizes others to make healthcare decisions on
              their behalf based on the patient&apos;s expressed wishes when the
              patient is unable to make decisions or unable to communicate
              decisions. Surgery Center respects and upholds those rights.
            </li>
            <li>
              However, unlike an acute care hospital setting, the Surgery Center
              does not routinely perform &quot;high risk&quot; procedures. Most
              procedures performed in this facility are considered to be of
              minimal risk. No surgery is without risk, however. You will
              discuss the specifics of your procedure with your physician who
              can answer your questions as to its risks, your expected recovery
              and care after surgery.
            </li>
            <li>
              It is our policy, regardless of contents of any Advance Directive
              or instructions from a health care surrogate or attorney-in-fact,
              that if an adverse event occurs during your treatment at this
              facility, we will initiate resuscitative or other stabilizing
              measures and transfer you to an acute care hospital for further
              evaluation. At the acute care hospital, further treatments or
              withdrawal of treatment measures already begun will be ordered in
              accordance with your wishes, Advance Directive, or healthcare
              Power of Attorney. Your agreement with this facility&apos;s policy
              will not revoke or invalidate any current healthcare directive or
              healthcare power of attorney.
            </li>
            <li>
              If you wish to complete an Advance Directive, copies of the
              official state forms are available at our facility.
            </li>
            <li>
              If you do not agree with this facility&apos;s policy, we wil be
              pleased to assist you in rescheduling your procedure.
            </li>
          </ul>
        </div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div>
            <h3>PLEASE CHECK ONE OF THE FOLLOWING:</h3>

            <div>
              <input
                type="radio"
                id="yes"
                name="advanced_directive"
                value="yes"
                checked={advancedDirective === true ? true : false}
                required
                onChange={() => {
                  setAdvancedDirective(true);
                }}
              />
              <label htmlFor="yes">
                Yes, I have an Advance Directive, Living Will or Healthcare
                Power of Attorney
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="no"
                name="advanced_directive"
                value="no"
                checked={advancedDirective === false ? true : false}
                onChange={() => {
                  setAdvancedDirective(false);
                }}
              />
              <label htmlFor="no">
                No, I do not have an Advance Directive, Living Will or
                Healthcare Power of Attorney.
              </label>
            </div>
          </div>

          <div>
            <h3>PLEASE CHECK ONE OF THE FOLLOWING:</h3>

            <div>
              <input
                type="radio"
                id="yes"
                name="policy_acceptance"
                value="yes"
                checked={policyAcceptance === true ? true : false}
                required
                onChange={() => {
                  setPolicyAcceptance(true);
                }}
              />
              <label htmlFor="yes">I do accept this Center's policy.</label>
            </div>

            <div>
              <input
                type="radio"
                id="no"
                name="policy_acceptance"
                value="no"
                checked={policyAcceptance === false ? true : false}
                onChange={() => {
                  setPolicyAcceptance(false);
                }}
              />
              <label htmlFor="no">
                I DO NOT accept this Center's policy and cancel my scheduled
                procedure.
              </label>
            </div>

            <p>
              If you checked YES to the question above, please provide a copy of
              that document so that it may be made part of your medical record
              at this facility.
            </p>
          </div>

          <div className="grid-2">
            <div className="signature-container mt-50">
              <SignatureButton
                individualSignature={individualSignature}
                setIndividualSignature={setIndividualSignature}
              />
              <hr className="signature-line"></hr>
              <p>Patient Signature</p>
            </div>

            <div className="mt-50">
              <label htmlFor="name" className="hidden">
                Patient Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="name-input"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />

              <p>Patient Name (Please Type)</p>
            </div>
          </div>

          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DisclosureofOwnership;
